import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, IconButton, TableCell } from "@mui/material";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { actions, useVizDispatch } from "@/state/visualization";
import { Column, RowCell } from "@/types";
import { pluralize } from "@/utils";

import { DeviceLink } from "../DeviceLink";
import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";
import { RobotoTooltip } from "../RobotoTooltip";
import { TagList } from "../TagList";
import { UserLink } from "../UserLink";

const cellSpanStyle: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  userSelect: "none",
  fontWeight: 400, // You can use a number here
};

const Cell: React.FC<{ column: Column; cell: RowCell }> = ({
  column,
  cell,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const vizDispatch = useVizDispatch();

  switch (cell.type) {
    case "chips":
      if (!Array.isArray(cell.data)) {
        throw new Error("Expect 'chips' cells to have a string[] data type.");
      }
      return (
        <TagList
          maxDisplayCount={3}
          tags={cell.data.sort()}
          onClick={(tag: string) => {
            const queryParams = new URLSearchParams();
            queryParams.set("query", tag);
            navigate(`${pathname}?${queryParams.toString()}`);
          }}
        />
      );
    case "datetime":
      if (typeof cell.data !== "string") {
        throw new Error("Expect 'datetime' cells to have a string data type.");
      }
      return (
        <span style={cellSpanStyle}>
          {new Date(cell.data).toLocaleString()}
        </span>
      );
    case "id": {
      if (typeof cell.data !== "string") {
        throw new Error("Expect 'id' cells to have a string data type.");
      }

      // Construct location for URL
      // Note: topics currently link to their corresponding file (association)
      let cellLink = null;

      if (
        column.title.includes("dataset_id") ||
        column.title.includes("association_id")
      ) {
        cellLink = "/datasets/" + cell.data;
      } else if (column.title.includes("file_id")) {
        cellLink = "/files/" + cell.data;
      } else if (cell.association) {
        cellLink = `/${pluralize(cell.association.association_type, 2)}/${cell.association.association_id}`;
      }

      const handleClick = (
        e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>,
      ) => {
        e.stopPropagation();
        if (column.title.includes("file_id")) {
          if (typeof cell.data === "string") {
            vizDispatch(actions.putFiles([{ fileId: cell.data }]));
          }
        } else if (column.title.includes("topic_id") || cell.association) {
          vizDispatch(
            actions.putFiles([
              { fileId: cell.association?.association_id || "" },
            ]),
          );
        }
      };

      return (
        <span style={cellSpanStyle}>
          {cellLink ? (
            <RobotoLinkHoverUnderline onClick={handleClick} to={cellLink}>
              {cell.data}
            </RobotoLinkHoverUnderline>
          ) : (
            <span onClick={handleClick} style={{ cursor: "pointer" }}>
              {cell.data}
            </span>
          )}
        </span>
      );
    }
    case "email":
      if (typeof cell.data !== "string") {
        throw new Error("Expect 'email' cells to have a string data type.");
      }
      return (
        <span style={cellSpanStyle}>
          <UserLink userId={cell.data} />
        </span>
      );
    case "device":
      if (typeof cell.data !== "string") {
        throw new Error("Expect 'device' cells to have a string data type.");
      }
      return (
        <span style={cellSpanStyle}>
          <DeviceLink deviceId={cell.data} />
        </span>
      );
    case "invocation":
      if (typeof cell.data !== "string") {
        throw new Error(
          "Expect 'invocation' cells to have a string data type.",
        );
      }
      return (
        <RobotoLinkHoverUnderline
          variant="body2"
          to={`/invocations/${cell.data}`}
        >
          {cell.data}
        </RobotoLinkHoverUnderline>
      );
    default:
      return <span style={cellSpanStyle}>{cell.data}</span>;
  }
};

const CopyButton: React.FC<{ cell: RowCell; hovering: boolean }> = ({
  cell,
  hovering,
}) => {
  const [labelText, setLabelText] = React.useState<string>("Copy");

  const cellIsEmpty =
    (typeof cell.data === "string" || Array.isArray(cell.data)) &&
    cell.data.length === 0;
  if (cellIsEmpty) {
    return null;
  }

  return (
    <Box
      sx={{
        opacity: hovering ? 1 : 0,
        transition: "opacity 0.2s",
        display: "inline",
      }}
    >
      <RobotoTooltip title={labelText}>
        <IconButton
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            navigator.clipboard
              .writeText(cell.data.toString())
              .then(() => {
                setLabelText("Copied!");
              })
              .catch(() => {
                setLabelText("Failed to copy.");
              });
          }}
          onBlur={() => {
            setLabelText("Copy");
          }}
          sx={{
            ml: "2px",
            color: "#7d8fb361",
            ":hover": {
              color: "#7D8FB3",
            },
          }}
        >
          <ContentCopyIcon
            sx={{
              fontSize: "12px",
            }}
          />
        </IconButton>
      </RobotoTooltip>
    </Box>
  );
};

interface DataGridCellProps {
  column: Column;
  constantHeight: number;
  cell: RowCell;
}

export const DataGridCell: React.FC<DataGridCellProps> = ({
  column,
  constantHeight,
  cell,
}) => {
  const [hovering, setHovering] = React.useState<boolean>(false);

  const padding = 8;
  return (
    <TableCell
      component="td"
      scope="row"
      sx={{
        border: 0,
        minWidth: column.width + "px",
        maxWidth: column.width + "px",
        width: column.width + "px",
        height: constantHeight + "px",
        padding: "0px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          height: constantHeight + "px",
          lineHeight: constantHeight - padding * 2 + "px",
          padding: padding + `px 8px`,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "left",
          color: "rgb(107, 114, 128)",
          fontSize: ".85rem",
          fontStyle: "normal",
          display: "flex",
          alignItems: "center",
        }}
        onMouseOver={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <Cell column={column} cell={cell} />
        <CopyButton cell={cell} hovering={hovering} />
      </Box>
    </TableCell>
  );
};
