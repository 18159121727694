import { Alert, Box, useTheme } from "@mui/material";
import * as React from "react";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";

import { MainLayoutWithSidebar } from "@/components/MainLayoutWithSidebar";
import {
  ModeToggleSearchBarMode,
  UISimpleCondition,
} from "@/components/SearchBar/types";
import {
  QueryRecord,
  QueryTarget,
  SubmitRoboqlQueryRequest,
  SubmitStructuredQueryRequest,
} from "@/domain/query";
import { SubmitTermQueryRequest } from "@/domain/query/QueryRecord.ts";
import {
  SearchResultInteraction,
  SearchResults,
} from "@/features/search/components/SearchResults";
import { SearchResultsSidebarContent } from "@/features/search/components/SearchResultsSidebarContent.tsx";
import { RobotoDomainException } from "@/http";
import { useAuth, useCurrentOrgId } from "@/providers/auth/hooks";
import { DomainServicesContext } from "@/providers/DomainServices";
import { LoggerService } from "@/service";
import { Condition, ConditionsOperator } from "@/types";

import * as conditions from "../components/conditions";
import { SearchBarController } from "../components/SearchBarController";

export const SearchWithResults = () => {
  const theme = useTheme();

  const { currentOrganization } = useAuth();
  const resolvedOrgId = useCurrentOrgId(currentOrganization?.org_id);

  const [searchParams, setSearchParams] = useSearchParams();

  // If queryId is provided, it overrides all other behavior.
  const sp_queryId = searchParams.get("queryId") || undefined;

  // If no queryId is provided, we'll run a default query on first page load.
  // The target will specify what we're querying for. Falls back to QueryTarget.Datasets.
  const sp_target =
    (searchParams.get("target") as QueryTarget) || QueryTarget.Datasets;

  // If no queryId is provided, a pre-canned query can be specified to constrain the first
  // page load query described above. If multiple are provided, roboql take precedence over query.
  const sp_roboql = searchParams.get("roboql") || undefined;
  const sp_query = searchParams.get("query") || undefined;

  const { queryService } = useContext(DomainServicesContext);

  const [activeQueryId, setActiveQueryId] = React.useState<string | undefined>(
    sp_queryId,
  );

  const [lastError, setLastError] = React.useState<
    RobotoDomainException | Error | undefined
  >(undefined);

  const [target, setTarget] = React.useState<QueryTarget>(sp_target);

  const [mode, setMode] = React.useState<ModeToggleSearchBarMode>(
    sp_roboql ? "roboql" : "basic",
  );

  const autoLaunchQuery = !sp_queryId;

  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [sidebarSelection, setSidebarSelection] = React.useState<
    { result: unknown; resultType: QueryTarget | undefined } | undefined
  >({
    result: undefined,
    resultType: undefined,
  });

  // This is where we can delegate to sidebar popouts and the like
  const onResultInteraction = React.useCallback(
    (
      result: unknown,
      resultType: QueryTarget,
      interaction: SearchResultInteraction,
    ) => {
      if (interaction === SearchResultInteraction.SingleClick) {
        setSidebarSelection({
          result: result,
          resultType: resultType,
        });

        if (result === sidebarSelection?.result) {
          setSidebarOpen(!sidebarOpen);
        } else if (!sidebarOpen) {
          setSidebarOpen(true);
        }
      }
    },
    [sidebarOpen, sidebarSelection?.result],
  );

  const onSubmit = () => {
    setLastError(undefined);
    setActiveQueryId(undefined);
  };

  const onSubmitSuccess = React.useCallback(
    (queryRecord: QueryRecord) => {
      searchParams.set("queryId", queryRecord.query_id);
      searchParams.delete("query");
      searchParams.delete("target");
      searchParams.delete("roboql");
      setActiveQueryId(queryRecord.query_id);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const launchTerm = React.useCallback(
    (target: QueryTarget, term: string, onlySetQueryId?: boolean) => {
      const launchAndAwaitQuery = async () => {
        try {
          const request: SubmitTermQueryRequest = {
            target: target,
            term: term,
          };

          if (!onlySetQueryId) {
            onSubmit();
          }

          const submittedQuery = await queryService.submitTerm(request, {
            resourceOwnerId: resolvedOrgId,
          });

          if (!onlySetQueryId) {
            onSubmitSuccess(submittedQuery);
          } else {
            setActiveQueryId(submittedQuery.query_id);
          }
        } catch (e) {
          if (e instanceof RobotoDomainException) {
            setLastError && setLastError(e);
          } else {
            const err = new Error(
              "We're having trouble loading search results, please try again soon.",
              { cause: e },
            );
            setLastError && setLastError(err);
          }
          LoggerService.error("Search failure", e);
        }
      };

      void launchAndAwaitQuery();
    },
    [onSubmitSuccess, queryService, resolvedOrgId],
  );

  // This powers basic structured queries that may be single term or include filters (i.e. not RoboQL)
  const launchCondition = React.useCallback(
    (target: QueryTarget, condition?: Condition, onlySetQueryId?: boolean) => {
      const launchAndAwaitQuery = async () => {
        try {
          const request: SubmitStructuredQueryRequest = {
            target: target,
            query: {
              condition: condition,
              sort_by: conditions.sortFieldForTarget(target),
              sort_direction: "DESC",
            },
          };

          if (!onlySetQueryId) {
            onSubmit();
          }

          const submittedQuery = await queryService.submitStructured(request, {
            resourceOwnerId: resolvedOrgId,
          });

          if (!onlySetQueryId) {
            onSubmitSuccess(submittedQuery);
          } else {
            setActiveQueryId(submittedQuery.query_id);
          }
        } catch (e) {
          if (e instanceof RobotoDomainException) {
            setLastError && setLastError(e);
          } else {
            const err = new Error(
              "We're having trouble loading search results, please try again soon.",
              { cause: e },
            );
            setLastError && setLastError(err);
          }
          LoggerService.error("Search failure", e);
        }
      };

      void launchAndAwaitQuery();
    },
    [onSubmitSuccess, queryService, resolvedOrgId],
  );

  // This fires when a user enters a single search term in basic mode
  const onBasicSearchSubmit = React.useCallback(
    (target: QueryTarget, searchTerm: string, onlySetQueryId?: boolean) => {
      // Empty structured gives us select *, while term search "" gives us none, so we'll use
      // a condition search ONLY for "".
      if (searchTerm === "") {
        return launchCondition(target, undefined, onlySetQueryId);
      }

      launchTerm(target, searchTerm, onlySetQueryId);
    },
    [launchCondition, launchTerm],
  );

  // This fires when a user adds filters in basic mode
  const onConditionSearchSubmit = React.useCallback(
    (conditions: UISimpleCondition[], operator: ConditionsOperator) => {
      if (conditions.length === 0) {
        return launchCondition(target, undefined);
      }

      launchCondition(target, {
        conditions: conditions,
        operator: operator,
      });
    },
    [launchCondition, target],
  );

  // This fires when a user submits a query in RoboQL mode
  const onRoboQlSearchSubmit = React.useCallback(
    (target: QueryTarget, roboql: string, onlySetQueryId?: boolean) => {
      const launchAndAwaitQuery = async () => {
        try {
          const request: SubmitRoboqlQueryRequest = {
            target: target,
            query: roboql,
          };

          if (!onlySetQueryId) {
            onSubmit();
          }

          const submittedQuery = await queryService.submitRoboql(request, {
            resourceOwnerId: resolvedOrgId,
          });

          if (!onlySetQueryId) {
            onSubmitSuccess(submittedQuery);
          } else {
            setActiveQueryId(submittedQuery.query_id);
          }
        } catch (e) {
          if (e instanceof RobotoDomainException) {
            setLastError && setLastError(e);
          } else {
            const err = new Error(
              "We're having trouble loading search results, please try again soon.",
              { cause: e },
            );
            setLastError && setLastError(err);
          }
          LoggerService.error("Search failure", e);
        }
      };

      void launchAndAwaitQuery();
    },
    [onSubmitSuccess, queryService, resolvedOrgId],
  );

  // On a render where no queryId is set, run an initial query
  // to populate the results table. Either use basic query param,
  // or roboql query param, or a default roboql select * style query.
  React.useEffect(() => {
    if (autoLaunchQuery && resolvedOrgId) {
      const defaultedTarget = sp_target || QueryTarget.Datasets;

      const sortField =
        defaultedTarget === QueryTarget.Files ? "modified" : "created";

      if (sp_query) {
        setMode("basic");
        onBasicSearchSubmit(defaultedTarget, sp_query, true);
      } else {
        onRoboQlSearchSubmit(
          defaultedTarget,
          sp_roboql || `* LIMIT 1000 SORT BY ${sortField} DESC`,
          true,
        );
      }
    }
  }, [
    autoLaunchQuery,
    resolvedOrgId,
    queryService,
    sp_query,
    sp_roboql,
    sp_target,
    onBasicSearchSubmit,
    onRoboQlSearchSubmit,
  ]);

  const [selectedDatasets, setSelectedDatasets] = React.useState<Set<string>>(
    new Set<string>(),
  );

  const clearSelectedDatasets = React.useCallback(() => {
    setSelectedDatasets(new Set<string>());
  }, []);

  return (
    // Vertical allocation
    <Box
      sx={{
        height: `calc(100vh - ${theme.navTopBarHeight})`,
      }}
    >
      <MainLayoutWithSidebar
        sidebarContent={
          <SearchResultsSidebarContent
            selection={sidebarSelection}
            onTagUpdateError={(error) => {
              setLastError(error);
            }}
          />
        }
        sidebarControlOverrides={{
          isOpen: sidebarOpen,
          setIsOpen: setSidebarOpen,
        }}
      >
        <Box
          sx={{
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
          }}
        >
          <SearchBarController
            resolvedOrgId={resolvedOrgId}
            activeQueryId={activeQueryId}
            autoLaunchQuery={autoLaunchQuery}
            target={target}
            setTarget={setTarget}
            mode={mode}
            setMode={setMode}
            initialSearchTerm={sp_query}
            initialRoboql={sp_roboql}
            onBasicSearchSubmit={onBasicSearchSubmit}
            onConditionSearchSubmit={onConditionSearchSubmit}
            onRoboqlSearchSubmit={onRoboQlSearchSubmit}
            selectedDatasets={selectedDatasets}
            clearSelectedDatasets={clearSelectedDatasets}
          />

          {lastError && (
            <Box mt={theme.spacing(2)}>
              <Alert severity="error">{lastError.message}</Alert>
            </Box>
          )}

          <SearchResults
            queryId={activeQueryId}
            lastError={lastError}
            onError={setLastError}
            onResultInteraction={onResultInteraction}
            selectedDatasets={selectedDatasets}
            setSelectedDatasets={setSelectedDatasets}
            extraHeight={mode === "roboql" ? parseInt(theme.spacing(4)) : 0}
          />
        </Box>
      </MainLayoutWithSidebar>
    </Box>
  );
};
