import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { type TriggerEvaluationSummary } from "@/domain/actions";
import { useDomainServices } from "@/providers/DomainServices";

const HIGH_FREQUENCY_POLLING = 1_000; // 1s
const LOW_FREQUENCY_POLLING = 5_000; // 5s

/**
 * Fetches the trigger evaluations summary for a dataset.
 * While uploading, and for 5 seconds directly after an upload finishes,
 * polls every 1s.
 * Else, polls every 5s.
 */
export function useTriggerEvaluationsSummary(
  datasetId: string,
  isUploading: boolean,
) {
  const { triggerService } = useDomainServices();
  const refetchIntervalRef = React.useRef<number>(LOW_FREQUENCY_POLLING);

  const result = useQuery<TriggerEvaluationSummary, Error>({
    queryKey: ["triggerEvaluationsSummary", datasetId],
    queryFn({ signal }) {
      return triggerService.getTriggerEvaluationsSummaryForDataset(datasetId, {
        abortSignal: signal,
      });
    },
    refetchInterval: refetchIntervalRef.current,
  });

  React.useEffect(
    function setTriggerEvaluationPollingFrequency() {
      if (isUploading) {
        refetchIntervalRef.current = HIGH_FREQUENCY_POLLING;
        return;
      }

      // An upload just finished if the `isUploading` state transitions from `true` to `false`
      // and the prior refetchInterval was set to HIGH_FREQUENCY_POLLING
      const uploadJustFinished =
        isUploading === false &&
        refetchIntervalRef.current === HIGH_FREQUENCY_POLLING;
      if (uploadJustFinished) {
        // If an upload just finished, wait for a bit to transition back to lower frequency polling.
        // This makes sure that we capture trigger evaluation status changes that happen directly
        // after an upload completes without waiting a full LOW_FREQUENCY_POLLING interval.
        const timeoutId = setTimeout(() => {
          refetchIntervalRef.current = LOW_FREQUENCY_POLLING;
        }, 5_000);

        return function cleanUp() {
          clearTimeout(timeoutId);
        };
      }
    },
    [isUploading],
  );

  return result;
}
