import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Skeleton } from "@mui/material";

import { RobotoTypography } from "@/components";
import { useMessagePathById } from "@/domain/topics/hooks/useMessagePath";
import { useTopicById } from "@/domain/topics/hooks/useTopic";

interface AssociatedMessagePathProps {
  messagePathId: string;
}

export function AssociatedMessagePath({
  messagePathId,
}: AssociatedMessagePathProps) {
  const messagePathQuery = useMessagePathById(messagePathId);
  const topicQuery = useTopicById(messagePathQuery.data?.topic_id);

  if (messagePathQuery.isPending || topicQuery.isPending) {
    return <Skeleton variant="text" width="150px" />;
  }

  if (messagePathQuery.isError || topicQuery.isError) {
    return (
      <span style={{ display: "inline-flex", gap: "0.5rem" }}>
        <ErrorOutlineIcon color="error" fontSize="small" />
        <RobotoTypography component={"span"} fontSize="14px">
          Failed to load message path
        </RobotoTypography>
      </span>
    );
  }

  return (
    <span style={{ display: "inline-flex", gap: "0.5rem" }}>
      <TimelineIcon fontSize="small" />
      <RobotoTypography component={"span"} fontSize="14px">
        {topicQuery.data.topic_name}.{messagePathQuery.data.message_path}
      </RobotoTypography>
    </span>
  );
}
