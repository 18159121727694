import {
  Alert,
  Box,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import { useLazyAPICall } from "@/service/apiHooks";
import {
  APIServiceError,
  GetDatasetResponse,
  RobotoAPICall,
  individualDatasetEndpoint,
} from "@/types";

interface EditableDatasetDescriptionProps {
  datasetId: string;
  description: string | undefined;
}

export const EditableDatasetDescription: React.FC<
  EditableDatasetDescriptionProps
> = ({ datasetId, description }) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(
    description,
  );
  const [editable, setEditable] = React.useState(false);
  const [error, setError] = React.useState<APIServiceError | null>(null);

  const { initiateRequest } = useLazyAPICall<GetDatasetResponse>();

  React.useEffect(() => {
    setCurrentValue(description);
  }, [description, datasetId]);

  const updateDataset = async () => {
    const updateDatasetCall: RobotoAPICall = {
      endpoint: individualDatasetEndpoint,
      orgId: currentOrganization?.org_id,
      pathParams: { datasetId },
      method: "PUT",
      requestBody: JSON.stringify({
        description: currentValue,
      }),
    };

    const { error, data } = await initiateRequest(updateDatasetCall);

    if (error) {
      setCurrentValue(description);
      setError(error);
    }

    if (data?.data.description !== undefined) {
      setCurrentValue(data?.data.description);
    }

    setEditable(false);
  };

  return (
    <Box sx={{ mt: theme.spacing(1) }}>
      {error && (
        <Alert
          onClose={() => setError(null)}
          severity="error"
          sx={{ mb: theme.spacing(1), mt: theme.spacing(1) }}
        >
          {error.message}
        </Alert>
      )}
      {!editable ? (
        <Typography
          sx={{
            color: theme.palette.text.secondary,
          }}
          onClick={() => {
            if (editable) {
              void updateDataset();
            }
            setEditable(!editable);
          }}
          variant="body2"
        >
          {currentValue === undefined && <Skeleton />}
          {currentValue !== undefined && (
            <i>
              {currentValue || "No description added yet, click to add one."}
            </i>
          )}
        </Typography>
      ) : (
        <TextField
          id="outlined-action-desc"
          placeholder="Enter a description"
          size="small"
          multiline
          minRows={2}
          maxRows={5}
          fullWidth
          autoFocus
          value={currentValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentValue(event.target.value);
          }}
          inputProps={{
            style: { fontSize: "0.9rem" },
          }}
          onBlur={() => {
            void updateDataset();
            setEditable(!editable);
          }}
        />
      )}
    </Box>
  );
};
