import { Box, CircularProgress, Typography } from "@mui/material";

import { InvocationStatus } from "@/domain/actions";

import InvocationStatusChip from "../InvocationStatusChip";

interface InvocationLogsHeaderProps {
  loading: boolean;
  status?: InvocationStatus;
}

const containerStyle = {
  position: "relative",
  transition: "height 0.5s",
};

const loadingIndicatorStyle = {
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, 50%)",
  display: "flex",
  top: "2px",
  gap: "1em",
  alignItems: "center",
  justifyContent: "center",
};

const statusChipStyle = {
  position: "absolute",
  left: "0em",
  top: "50%",
  transform: "translateY(-50%)",
};

export default function InvocationLogsHeader({
  loading,
  status,
}: InvocationLogsHeaderProps) {
  const headerContent: JSX.Element[] = [];
  if (loading) {
    headerContent.push(
      <Box sx={loadingIndicatorStyle} key="waiting">
        <CircularProgress size="1.5em" />
        <Typography marginLeft="1em" variant="body2">
          Waiting for logs...
        </Typography>
      </Box>,
    );
  }

  if (status !== undefined) {
    headerContent.push(
      <InvocationStatusChip
        key={status.toString()}
        pollInterval={null}
        status={status}
        sx={statusChipStyle}
      />,
    );
  }

  return (
    <Box height={headerContent.length ? "48px" : "0px"} sx={containerStyle}>
      {headerContent}
    </Box>
  );
}
