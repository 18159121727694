import {
  useDatasetMetadataKeys,
  useDatasetTags,
} from "@/domain/datasets/hooks/";
import useFileMetadataKeys from "@/domain/files/hooks/useFileMetadataKeys";
import useOrgFileTags from "@/domain/files/hooks/useOrgFileTags";
import { QueryTarget } from "@/domain/query";
import useAllTopicKeys from "@/domain/topics/hooks/useAllTopicKeys.ts";

import { RoboqlExternalCtx } from "../../RoboqlEditor/monaco";

export const useRoboqlExternalCtx = (
  target: QueryTarget,
  orgId: string,
): RoboqlExternalCtx => {
  const fileTagsQuery = useOrgFileTags(orgId);
  const fileMetadataKeysQuery = useFileMetadataKeys(orgId);
  const datasetTagsQuery = useDatasetTags(orgId);
  const datasetMetadataKeysQuery = useDatasetMetadataKeys(orgId);
  const allTopicKeysQuery = useAllTopicKeys(orgId);

  const ret = {
    target: target,
    datasets: {
      tags: datasetTagsQuery,
      metadataKeys: datasetMetadataKeysQuery,
    },
    files: {
      tags: fileTagsQuery,
      metadataKeys: fileMetadataKeysQuery,
    },
    topics: allTopicKeysQuery,
  };

  return ret;
};
