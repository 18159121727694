import {
  CircularProgress,
  Popover,
  TextField,
  Box,
  Divider,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoErrorAlertDialog } from "@/components/RobotoErrorAlert";
import { useAuth } from "@/providers";
import { useLazyAPICall } from "@/service/apiHooks";
import { APIServiceError, AnalyticsEvent, feedbackEndpoint } from "@/types";

import { RobotoButton } from "../RobotoButton";
import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";

interface SupportPopoverProps {
  anchorEl: HTMLButtonElement | null;
  handleClose?: () => void;
}

export const SupportPopover: React.FC<SupportPopoverProps> = (props) => {
  const theme = useTheme();
  const [supportAlertOpen, setSupportAlertOpen] =
    React.useState<boolean>(false);
  const [lastSupportError, setLastSupportError] = React.useState<
    APIServiceError | undefined
  >(undefined);

  const { initiateRequest: sendMessageRequest } = useLazyAPICall();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const { currentOrganization } = useAuth();

  const sendMessage = async () => {
    setLoading(true);
    const { error } = await sendMessageRequest({
      method: "POST",
      endpoint: feedbackEndpoint,
      requestBody: JSON.stringify({
        message: message,
      }),
      orgId: currentOrganization?.org_id,
    });

    if (error) {
      setLastSupportError(error);
      setSupportAlertOpen(true);
    } else {
      setMessage("");
    }

    props.handleClose && props.handleClose();
    setLoading(false);
  };

  return (
    <>
      <RobotoErrorAlertDialog
        open={supportAlertOpen}
        setOpen={setSupportAlertOpen}
        error={lastSupportError}
      />

      <Popover
        id={"popover-support"}
        open={Boolean(props.anchorEl)}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: "center",
        }}
        marginThreshold={5}
      >
        <Box
          component={Paper}
          elevation={24}
          sx={{
            width: "30rem",
            padding: theme.spacing(1.5),
          }}
        >
          <Typography variant="subtitle1">Support</Typography>
          <Divider sx={{ mt: theme.spacing(1), mb: theme.spacing(1) }} />
          <Box sx={{ padding: theme.spacing(1) }}>
            <Box sx={{ mb: theme.spacing(1) }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle2">Contact</Typography>
              </Box>
              <Typography variant="caption">
                Join our community{" "}
                <RobotoLinkHoverUnderline
                  to="https://discord.gg/r8RXceqnqH"
                  target="_blank"
                >
                  Discord server
                </RobotoLinkHoverUnderline>{" "}
                to chat, or send us a message below.
              </Typography>
            </Box>
            <TextField
              id="outlined-feedback-desc"
              placeholder="Type your message here"
              size="small"
              multiline
              minRows={2}
              maxRows={5}
              fullWidth
              value={message}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMessage(event.target.value);
              }}
              inputProps={{
                style: { fontSize: "0.9rem" },
              }}
            />
          </Box>

          <Box
            sx={{
              m: theme.spacing(1),
              display: "flex",
              alignItems: "center",
            }}
          >
            <RobotoButton
              eventName={AnalyticsEvent.SentFeedbackFromPopover}
              variant={"contained"}
              color="secondary"
              onClick={() => {
                void sendMessage();
              }}
            >
              Send Message
            </RobotoButton>
            {loading && (
              <CircularProgress sx={{ ml: theme.spacing(1.5) }} size="1rem" />
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
