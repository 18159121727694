import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from "react";

import { Expandable } from "../Expandable";
import { Label } from "../Label";

import { MessagePath } from "./MessagePath";
import styles from "./Topic.module.css";
import { type TopicNode } from "./tree";

interface TopicProps {
  node: TopicNode;
}

/**
 * Render a Topic and its associated MessagePaths.
 */
export function Topic(props: TopicProps) {
  const { node } = props;
  const [children, setChildren] = React.useState<JSX.Element | null>(null);

  const toggleExpanded = () => {
    if (node.children.length === 0) {
      return;
    }

    if (children !== null) {
      setChildren(null);
      return;
    }
    return setChildren(
      <div className={styles.messagePathContainer}>
        {node.children.map((child) => {
          return <MessagePath key={child.data.message_path_id} node={child} />;
        })}
      </div>,
    );
  };

  const baseLabel = <Label title={node.label}>{node.label}</Label>;

  const label =
    node.children.length === 0 ? (
      baseLabel
    ) : (
      <Expandable<TopicNode>
        data={node}
        EndIcon={children ? ExpandLessIcon : ExpandMoreIcon}
        onClick={toggleExpanded}
      >
        {baseLabel}
      </Expandable>
    );

  return (
    <>
      {label}
      {children}
    </>
  );
}
