import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Skeleton, useTheme } from "@mui/material";
import React from "react";

import { EventRecord } from "@/domain/events";

import { RobotoTypography } from "../../RobotoTypography";
import { useWorkspaceEvent } from "../WorkspaceCtx";

import styles from "./EventName.module.css";
import { EventNameForm } from "./EventNameForm";

interface EventNameProps {
  eventId: EventRecord["event_id"];
}

export function EventName({ eventId }: EventNameProps) {
  const eventQuery = useWorkspaceEvent(eventId);
  const theme = useTheme();

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  if (eventQuery.isLoading) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Name
        </RobotoTypography>
        <Skeleton height="24px" variant="rounded" />
      </section>
    );
  }

  if (eventQuery.isError) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Name
        </RobotoTypography>
        <div className={styles.errorContainer}>
          <ErrorOutlineIcon color="error" />
          <RobotoTypography>Failed to load event name</RobotoTypography>
        </div>
      </section>
    );
  }

  const name = eventQuery.data.name;

  if (isEditing) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Name
        </RobotoTypography>
        <div>
          <EventNameForm
            id={eventId}
            name={name}
            onSubmit={() => setIsEditing(false)}
          />
        </div>
      </section>
    );
  }

  return (
    <section>
      <RobotoTypography component={"h3"} className={styles.title}>
        Name
      </RobotoTypography>
      <Box
        onClick={() => setIsEditing(true)}
        sx={{
          ":hover": {
            backgroundColor: theme.palette.hoverBackground.main,
            borderRadius: "0.25rem",
            cursor: "pointer",
          },
        }}
      >
        <RobotoTypography component="p">{name}</RobotoTypography>
      </Box>
    </section>
  );
}
