import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Box,
  Breadcrumbs,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

import {
  RobotoButton,
  RobotoLinkHoverUnderline,
  MetadataForm,
  MetadataView,
} from "@/components";
import { AutocompleteType } from "@/components/TagAndMetadataAutocomplete";
import { TagInput, TagsGroup, Tags } from "@/components/tags";
import { ActionParameter, ActionRecord } from "@/domain/actions";
import { OrganizationTier } from "@/domain/orgs";
import { useAuth, useNavigation } from "@/providers";
import { useLazyAPICall } from "@/service/apiHooks";
import { AnalyticsEvent, actionsEndpoint } from "@/types";

import { ActionComputeReqs, ActionContainerParams } from "../components/action";
import { ActionParamsTable } from "../components/action/ActionParamsTable";
import { ActionTimeoutField } from "../components/action/ActionTimeoutField";
import {
  actionImageError,
  actionNameError,
  createInitialTimeoutState,
  isActionImageValid,
  isActionNameValid,
  isActionTimeoutValid,
  parseComputeRequirements,
  parseContainerParameters,
} from "../components/action/actionUtils";

const formErrors = (
  hasComputeFormErrors: boolean,
  name?: string,
  image?: string,
  actionTimeout?: number | string,
) => {
  return (
    actionNameError(name) ||
    actionImageError(image) ||
    hasComputeFormErrors ||
    !isActionTimeoutValid(actionTimeout)
  );
};

export const CreateActionPage: React.FC = () => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>("");
  const [name, setName] = React.useState<string | undefined>(undefined);
  const [description, setDescription] = React.useState<string | undefined>(
    undefined,
  );
  const [image, setImage] = React.useState<string | undefined>(undefined);
  const [parameters, setParameters] = React.useState<
    ActionParameter[] | undefined
  >(undefined);
  const [cpu, setCpu] = React.useState<string | undefined>(undefined);
  const [memory, setMemory] = React.useState<string | undefined>(undefined);
  const [gpu, setGpu] = React.useState<string | undefined>(undefined);
  const [storage, setStorage] = React.useState<number | undefined>(undefined);
  const [workdir, setWorkdir] = React.useState<string | undefined>(undefined);
  const [command, setCommand] = React.useState<string | undefined>(undefined);
  const [entrypoint, setEntrypoint] = React.useState<string | undefined>(
    undefined,
  );
  const [envVars, setEnvVars] = React.useState<Record<string, string>>({});
  const [tags, setTags] = React.useState<string[]>([]);
  const [metadata, setMetadata] = React.useState<Record<string, unknown>>({});

  const [openParams, setOpenParams] = React.useState<boolean>(false);
  const [hasComputeFormErrors, setHasComputeFormErrors] = React.useState(false);

  const isGated =
    currentOrganization?.tier === OrganizationTier.Free ? true : false;

  const [actionTimeout, setActionTimeout] = React.useState<
    number | string | undefined
  >(createInitialTimeoutState(isGated));
  const [openContainerParams, setOpenContainerParams] =
    React.useState<boolean>(false);

  const [openResourceParams, setOpenResourceParams] =
    React.useState<boolean>(false);

  const [openTags, setOpenTags] = React.useState<boolean>(false);
  const [openMetadata, setOpenMetadata] = React.useState<boolean>(false);

  const { initiateRequest: createActionReq } = useLazyAPICall<{
    data: ActionRecord;
  }>();

  const { goto } = useNavigation();

  const createAction = async () => {
    setLoading(true);

    const computeRequirements = parseComputeRequirements(
      cpu,
      memory,
      gpu,
      storage,
    );
    const containerParameters = parseContainerParameters(
      command,
      entrypoint,
      workdir,
      envVars,
    );

    // Create the Action
    const { error, data } = await createActionReq({
      method: "POST",
      endpoint: actionsEndpoint,
      requestBody: JSON.stringify({
        name: name,
        description: description,
        metadata: metadata,
        tags: tags,
        uri: image,
        compute_requirements: computeRequirements,
        container_parameters: containerParameters,
        parameters: parameters,
        timeout: actionTimeout,
      }),
      orgId: currentOrganization?.org_id,
    });

    if (error) {
      setErrorText(error.message);
    }

    setLoading(false);

    if (!error && data?.data) {
      const ref = {
        name: data.data.name,
      };
      goto.action({
        action: ref,
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          fontSize: "1.125rem",
          fontWeight: "500",
          borderBottom: "unset",
          "& a": {
            color: theme.palette.text.secondary,
            textDecoration: "none",
          },
          "& a:hover": {
            textDecoration: "underline",
          },
        }}
      >
        <Link to="/actions?tab=0">Actions</Link>,
        <Typography
          sx={{ fontSize: "1.125rem", fontWeight: "500" }}
          color="text.primary"
        >
          Create Action
        </Typography>
      </Breadcrumbs>

      <Box>
        <Box
          component={Paper}
          variant="outlined"
          sx={{ p: theme.spacing(2), mt: theme.spacing(3), background: "none" }}
        >
          <Box
            sx={{
              mb: theme.spacing(2),
            }}
          >
            {errorText ? (
              <Alert severity="error">{errorText}</Alert>
            ) : (
              <Alert severity="info">
                Complete the form below to create a new action. Check out the{" "}
                <RobotoLinkHoverUnderline
                  to="https://docs.roboto.ai/user-guides/index.html"
                  target="_blank"
                >
                  user guide
                </RobotoLinkHoverUnderline>{" "}
                if it&apos;s your first time.
              </Alert>
            )}
          </Box>
          <Stack
            component="form"
            sx={{
              maxWidth: "65ch",
            }}
            spacing={3}
            noValidate
            autoComplete="off"
          >
            <Box>
              <Box sx={{ mb: theme.spacing(1) }}>
                <Typography variant="subtitle2">Action Name</Typography>
                <Typography variant="caption">
                  Give your action a name. Note, this can&apos;t be modified
                  after creation.
                </Typography>
              </Box>
              <TextField
                id="outlined-action-name"
                placeholder="Enter a name"
                size="small"
                fullWidth
                value={name}
                error={actionNameError(name)}
                helperText={
                  name === undefined || (name && isActionNameValid(name))
                    ? ""
                    : "Names cannot contain spaces or special characters"
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value);
                }}
                inputProps={{ maxLength: 75 }}
                sx={{ width: "100%", maxWidth: 400 }}
              />
            </Box>
            <Box>
              <Box sx={{ mb: theme.spacing(1) }}>
                <Typography variant="subtitle2">
                  Description (optional)
                </Typography>
                <Typography variant="caption">
                  Give your action a description. Markdown is supported.
                </Typography>
              </Box>
              <TextField
                id="outlined-action-desc"
                placeholder="Enter a description"
                size="small"
                multiline
                minRows={2}
                maxRows={5}
                fullWidth
                value={description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDescription(event.target.value);
                }}
              />
            </Box>

            <Box>
              <Box sx={{ mb: theme.spacing(1) }}>
                <Typography variant="subtitle2">Image (optional)</Typography>
                <Typography variant="caption">
                  Specify the Docker image for your action. For example:{" "}
                  <i>busybox:latest</i> or{" "}
                  <i>private-registry/repository:tag</i>.
                </Typography>
              </Box>
              <TextField
                id="outlined-action-name"
                placeholder="Enter an image name:tag"
                size="small"
                fullWidth
                error={actionImageError(image)}
                helperText={
                  image === undefined || (image && isActionImageValid(image))
                    ? ""
                    : "Images must contain a repository and tag separated by a colon"
                }
                value={image}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setImage(event.target.value);
                }}
              />
            </Box>

            <Box>
              <Box sx={{ mb: theme.spacing(2) }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle2">
                    Parameters (optional)
                  </Typography>
                  <IconButton
                    aria-label="parameters"
                    size="small"
                    sx={{
                      ml: theme.spacing(0.25),
                      transform: openParams ? "rotate(180deg)" : "none",
                    }}
                    onClick={() => setOpenParams(!openParams)}
                  >
                    <ExpandCircleDownOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </IconButton>
                </Box>
                <Typography variant="caption">
                  Create parameters for your action.
                </Typography>
              </Box>
              <Collapse in={openParams} timeout="auto" unmountOnExit>
                <ActionParamsTable
                  editable={true}
                  mode="parameters"
                  params={parameters || []}
                  setParams={setParameters}
                />
              </Collapse>
            </Box>
            <ActionTimeoutField
              isGated={isGated}
              showAlert={false}
              actionTimeout={actionTimeout}
              setActionTimeout={setActionTimeout}
            />
            <Box>
              <Box sx={{ mb: theme.spacing(2) }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle2">
                    Container Parameters (optional)
                  </Typography>
                  <IconButton
                    aria-label="container-parameters"
                    size="small"
                    sx={{
                      ml: theme.spacing(0.25),
                      transform: openContainerParams
                        ? "rotate(180deg)"
                        : "none",
                    }}
                    onClick={() => setOpenContainerParams(!openContainerParams)}
                  >
                    <ExpandCircleDownOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </IconButton>
                </Box>
                <Typography variant="caption">
                  Specify parameters to pass to your action&apos;s container at
                  runtime.
                </Typography>
              </Box>
              <Collapse in={openContainerParams} timeout="auto" unmountOnExit>
                <ActionContainerParams
                  workdir={workdir}
                  setWorkdir={setWorkdir}
                  command={command}
                  setCommand={setCommand}
                  entrypoint={entrypoint}
                  setEntrypoint={setEntrypoint}
                  envVars={envVars}
                  setEnvVars={setEnvVars}
                />
              </Collapse>
            </Box>
            <Box>
              <Box sx={{ mb: theme.spacing(2) }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle2">
                    Compute Requirements (optional)
                  </Typography>
                  <IconButton
                    aria-label="compute-requirements"
                    size="small"
                    sx={{
                      ml: theme.spacing(0.25),
                      transform: openResourceParams ? "rotate(180deg)" : "none",
                    }}
                    onClick={() => setOpenResourceParams(!openResourceParams)}
                  >
                    <ExpandCircleDownOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </IconButton>
                </Box>
                <Typography variant="caption">
                  Specify compute resources required to run your action.
                </Typography>
              </Box>
              <Collapse in={openResourceParams} timeout="auto" unmountOnExit>
                <ActionComputeReqs
                  cpu={cpu}
                  setCpu={setCpu}
                  memory={memory}
                  setMemory={setMemory}
                  gpu={gpu}
                  setGpu={setGpu}
                  storage={storage}
                  setStorage={setStorage}
                  setHasComputeFormErrors={setHasComputeFormErrors}
                />
              </Collapse>
            </Box>
            <Box>
              <Box sx={{ mb: theme.spacing(1) }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle2">Tags (optional)</Typography>
                  <IconButton
                    aria-label="tags"
                    size="small"
                    sx={{
                      ml: theme.spacing(0.25),
                      transform: openTags ? "rotate(180deg)" : "none",
                    }}
                    onClick={() => setOpenTags(!openTags)}
                  >
                    <ExpandCircleDownOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </IconButton>
                </Box>
                <Typography variant="caption">
                  Label your action with relevant tags to improve discovery,
                  e.g. ROS
                </Typography>
              </Box>
              <Collapse in={openTags} timeout="auto" unmountOnExit>
                <TagsGroup>
                  <Tags
                    tags={tags}
                    onDeleteTag={(tag) => {
                      setTags((prev) => prev.filter((t) => t !== tag));
                    }}
                    truncateText={false}
                  />
                  <TagInput
                    autocompleteType={AutocompleteType.ActionTags}
                    tags={tags}
                    onAddTag={(tag) => {
                      setTags((prev) => [...prev, tag]);
                    }}
                  />
                </TagsGroup>
              </Collapse>
            </Box>
            <Box>
              <Box sx={{ mb: theme.spacing(1) }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle2">
                    Metadata (optional)
                  </Typography>
                  <IconButton
                    aria-label="metadata"
                    size="small"
                    sx={{
                      ml: theme.spacing(0.25),
                      transform: openMetadata ? "rotate(180deg)" : "none",
                    }}
                    onClick={() => setOpenMetadata(!openMetadata)}
                  >
                    <ExpandCircleDownOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </IconButton>
                </Box>
                <Typography variant="caption">
                  Label your action with relevant metadata to improve discovery.
                </Typography>
              </Box>
              <Collapse in={openMetadata} timeout="auto" unmountOnExit>
                <MetadataView metadata={metadata} onChange={setMetadata} />
                <MetadataForm
                  metadata={metadata}
                  autocompleteType={AutocompleteType.ActionMetadataKeys}
                  onAddMetadata={(key: string, value: string) => {
                    setMetadata({
                      ...metadata,
                      [key]: value,
                    });
                  }}
                />
              </Collapse>
            </Box>
          </Stack>
          <Box sx={{ mt: theme.spacing(3) }}>
            <Box
              sx={{
                mt: theme.spacing(1),
                display: "flex",
                alignItems: "center",
              }}
            >
              <RobotoButton
                eventName={AnalyticsEvent.CreateActionClicked}
                variant={"contained"}
                color="secondary"
                disabled={
                  name === undefined ||
                  name === "" ||
                  formErrors(hasComputeFormErrors, name, image, actionTimeout)
                }
                onClick={() => {
                  void createAction();
                }}
              >
                Create Action
              </RobotoButton>
              {loading && (
                <CircularProgress sx={{ ml: theme.spacing(1.5) }} size="1rem" />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
