import CancelIcon from "@mui/icons-material/Cancel";
import { SxProps } from "@mui/material";
import * as React from "react";

import { AlertDialog, RobotoIconButton, RobotoTooltip } from "@/components";
import { InvocationRecord, InvocationStatus } from "@/domain/actions";
import { OrgRecord } from "@/domain/orgs";
import { AnalyticsEvent } from "@/types";

import useCancellationCallback from "./useCancellationCallback";

interface CancelInvocationIconButtonProps {
  invocation?: InvocationRecord;
  onCancel?: () => void;
  org: OrgRecord | null;
  sx?: SxProps;
}

export default function CancelInvocationIconButton({
  invocation,
  onCancel,
  org,
  sx,
}: CancelInvocationIconButtonProps) {
  const cancelInvocation = useCancellationCallback(invocation, org, onCancel);
  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);

  const latestStatusRecord = invocation?.status[invocation?.status.length - 1];
  const isTerminal =
    latestStatusRecord?.status !== undefined &&
    [
      InvocationStatus.Completed,
      InvocationStatus.Cancelled,
      InvocationStatus.Failed,
      InvocationStatus.Deadly,
    ].includes(latestStatusRecord.status);

  if (isTerminal) {
    return null;
  }

  return (
    <>
      <RobotoTooltip title="Cancel">
        <span>
          <RobotoIconButton
            eventName={AnalyticsEvent.InvocationCancelled}
            disabled={isTerminal}
            onClick={() => {
              setAlertDialogOpen(true);
            }}
            sx={sx}
            color="warning"
          >
            <CancelIcon sx={{ fontSize: "1.1rem" }} />
          </RobotoIconButton>
        </span>
      </RobotoTooltip>
      <AlertDialog
        dialogOpen={alertDialogOpen}
        handleClose={() => {
          setAlertDialogOpen(false);
        }}
        dialogTitle="Cancel"
        dialogText={`Are you sure you want to cancel this invocation of ${invocation?.provenance.action.name}?`}
        dialogAction={cancelInvocation}
      />
    </>
  );
}
