import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import * as React from "react";

import { AnalyticsEvent, Comment } from "@/types";

import { RobotoIconButton } from "../RobotoIconButton";
import { RobotoLink } from "../RobotoLink";
import { RobotoMarkdown } from "../RobotoMarkdown";
import { RobotoTooltip } from "../RobotoTooltip";

// Display a special avatar for any Roboto platform users
const ROBOTO_PLATFORM_USER_IDS = ["support@roboto.ai", "system@roboto.ai"];

function trimComment(text: string) {
  if (text.length > 300) {
    return text.slice(0, 300) + "...";
  }

  return text;
}

interface DisplayCommentProps {
  comment: Comment;
  currentUserId: string;
  avatarUrl: string | null;
  onDeleteCommentPressed: (comment: Comment) => void;
  onEditCommentPressed: (comment: Comment) => void;
  summaryView?: boolean;
}

export const DisplayComment: React.FC<DisplayCommentProps> = ({
  comment,
  currentUserId,
  avatarUrl,
  onDeleteCommentPressed,
  onEditCommentPressed,
  summaryView,
}) => {
  const theme = useTheme();

  const time = new Date(comment.modified);

  const humanTime = time
    ? dayjs.duration(dayjs(time).diff(dayjs())).humanize(true)
    : "Never";

  const displayText = summaryView
    ? trimComment(comment.comment_text)
    : comment.comment_text;

  const [isHover, setIsHover] = React.useState<boolean>(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(0.5),
        gap: theme.spacing(0.5),
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {ROBOTO_PLATFORM_USER_IDS.includes(comment.created_by) ? (
          <Avatar
            imgProps={{ referrerPolicy: "no-referrer" }}
            sx={{ width: 38, height: 38 }}
          >
            <img
              src="/iconWhite.svg"
              style={{ width: "40px", height: "40px" }}
              alt="Roboto Logo"
            />
          </Avatar>
        ) : (
          <Avatar
            src={avatarUrl ?? ""}
            imgProps={{ referrerPolicy: "no-referrer" }}
            sx={{ width: 38, height: 38 }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: theme.spacing(2),
          flex: 1,
          a: {
            textDecoration: "none",
            ":hover": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Typography variant={"caption"} component="span">
          <span style={{ fontWeight: 500 }}>
            {ROBOTO_PLATFORM_USER_IDS.includes(comment.created_by) ? (
              <>{comment.created_by}</>
            ) : (
              <RobotoLink
                to={`/users/${comment.created_by}`}
                sx={{
                  color: theme.palette.text.primary,
                }}
              >
                {comment.created_by}
              </RobotoLink>
            )}
          </span>
          &nbsp;{" "}
          <span
            style={{ color: "#888" }}
            title={time.toLocaleString() + " (" + humanTime + ")"}
          >
            {time.toLocaleDateString(undefined, {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}{" "}
            at{" "}
            {time.toLocaleTimeString(undefined, {
              hour: "numeric",
              minute: "numeric",
            })}{" "}
            {summaryView && (
              <>
                {" on "}
                <RobotoLink
                  to={`/${comment.entity_type}s/${comment.entity_id}`}
                  sx={{ color: "#888" }}
                >
                  {comment.entity_id}
                </RobotoLink>{" "}
              </>
            )}
            {comment.created !== comment.modified ? "(edited)" : ""}
          </span>
        </Typography>
        <Box
          sx={{
            fontSize: "0.9rem",
            a: {
              color: theme.palette.primary.main,
            },
          }}
        >
          <RobotoMarkdown
            content={displayText
              // fix mentions
              // first capture group is user name, second is user id (email)
              .replace(/@\[([^\]]+)\]\(([^)]+)\)/gi, "**[@$1](/users/$2)**")
              // fix new lines
              .replace(/(?:\n\n)(?![*-])\n/gi, "&nbsp;\n ")}
          />
        </Box>
      </Box>

      {comment.created_by === currentUserId && !summaryView && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            opacity: isHover ? 1 : 0,
          }}
        >
          <RobotoIconButton
            eventName={AnalyticsEvent.EditCommentButtonClicked}
            sx={{ marginRight: theme.spacing(1) }}
            onClick={() => onEditCommentPressed(comment)}
          >
            <RobotoTooltip title="Edit">
              <EditIcon fontSize="small" color="primary" />
            </RobotoTooltip>
          </RobotoIconButton>

          <RobotoIconButton
            eventName={AnalyticsEvent.DeleteCommentButtonClicked}
            onClick={() => onDeleteCommentPressed(comment)}
          >
            <RobotoTooltip title="Delete">
              <DeleteIcon fontSize="small" color="primary" />
            </RobotoTooltip>
          </RobotoIconButton>
        </Box>
      )}
    </Box>
  );
};
