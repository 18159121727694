import { CSSProperties } from "react";

import { formatDuration } from "./formatDuration";

interface EventDurationProps {
  durationInSeconds: number;
  style?: CSSProperties;
  show?: boolean;
}

function formatDurationSafely(durationInSeconds: number) {
  try {
    return formatDuration(durationInSeconds);
  } catch {
    return "Invalid duration";
  }
}

export function EventDuration({
  durationInSeconds,
  style,
  show = true,
}: EventDurationProps) {
  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        height: "42px",
        borderLeftWidth: "2px",
        borderLeftStyle: "solid",
        borderRightWidth: "2px",
        borderRightStyle: "solid",
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      {formatDurationSafely(durationInSeconds)}
    </div>
  );
}
