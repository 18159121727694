import { Button } from "@mui/material";
import * as React from "react";

import styles from "./Expandable.module.css";

interface ExpandableProps<T> {
  data: T;
  EndIcon?: JSX.ElementType;
  onClick?: (arg0: T) => void;
}

const noop = () => {};

/**
 * A subtle, textual button (optionally with an end icon) that can be expanded to reveal more content.
 */
export function Expandable<T>(
  props: React.PropsWithChildren<ExpandableProps<T>>,
) {
  const { children, data, EndIcon, onClick = noop } = props;
  return (
    <Button
      className={styles.button}
      endIcon={EndIcon !== undefined ? <EndIcon /> : undefined}
      onClick={() => onClick(data)}
      variant="text"
    >
      {children}
    </Button>
  );
}
