// This file was autogenerated from `packages/roboql/roboql_fields_gen.py`, and should not be
// modified by hand.

export interface RoboqlFieldDescription {
  aliases: string[];
  comparators: string[] | null;
  detail: string | null;
  documentation: string | null;
  is_sortable: boolean;
  label: string;
  namespace: string;
}

export const roboqlFields: Record<string, RoboqlFieldDescription[]> = {
  datasets: [
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "ISO8601 timestamp when the dataset was created.",
      documentation: null,
      is_sortable: true,
      label: "created",
      namespace: "datasets",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "User who created the dataset.",
      documentation: null,
      is_sortable: true,
      label: "created_by",
      namespace: "datasets",
    },
    {
      aliases: ["id"],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "A 'ds_' prefixed unique ID for the dataset.",
      documentation: null,
      is_sortable: false,
      label: "dataset_id",
      namespace: "datasets",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Freetext human-readable description of the dataset.",
      documentation: null,
      is_sortable: false,
      label: "description",
      namespace: "datasets",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail:
        "The unique ID of the device which created the dataset, or null if created by a human user.",
      documentation: null,
      is_sortable: false,
      label: "device_id",
      namespace: "datasets",
    },
    {
      aliases: [],
      comparators: null,
      detail:
        "Custom key-value pairs added by users to categorize the dataset.",
      documentation: null,
      is_sortable: false,
      label: "metadata",
      namespace: "datasets",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "ISO8601 timestamp when the dataset was last modified.",
      documentation:
        "A dataset is considered modified when fields such as description, metadata, or tags are updated. Changes due to file uploads are not reflected in this field.",
      is_sortable: true,
      label: "modified",
      namespace: "datasets",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "User who last modified the dataset.",
      documentation: null,
      is_sortable: false,
      label: "modified_by",
      namespace: "datasets",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Org which owns the dataset.",
      documentation: null,
      is_sortable: false,
      label: "org_id",
      namespace: "datasets",
    },
    {
      aliases: [],
      comparators: ["CONTAINS", "NOT_CONTAINS"],
      detail: "Custom string tags added by users to categorize the dataset.",
      documentation: null,
      is_sortable: false,
      label: "tags",
      namespace: "datasets",
    },
  ],
  files: [
    {
      aliases: ["dataset_id", "collection_id"],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Unique ID of the resource which the file belongs to.",
      documentation:
        "Currently, association ID may be a `ds_` prefixed dataset ID or `cl_` prefixed collection ID.",
      is_sortable: true,
      label: "association_id",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "ISO8601 timestamp when the file was uploaded.",
      documentation: null,
      is_sortable: true,
      label: "created",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "User who uploaded the file.",
      documentation: null,
      is_sortable: true,
      label: "created_by",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail:
        "Freetext human-readable description of the file and its contents.",
      documentation: null,
      is_sortable: false,
      label: "description",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail:
        "The unique ID of the device which uploaded the file, or null if uploaded by a human user.",
      documentation: null,
      is_sortable: false,
      label: "device_id",
      namespace: "files",
    },
    {
      aliases: ["id"],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Unique ID for the file.",
      documentation: null,
      is_sortable: true,
      label: "file_id",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: null,
      detail: "Custom key-value pairs added by users to categorize the file.",
      documentation: null,
      is_sortable: false,
      label: "metadata",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "ISO8601 timestamp when the file was last modified.",
      documentation: null,
      is_sortable: true,
      label: "modified",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "User who last modified the file.",
      documentation: null,
      is_sortable: true,
      label: "modified_by",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Org which owns the file.",
      documentation: null,
      is_sortable: false,
      label: "org_id",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail:
        "String which describes the client and user which uploaded the file.",
      documentation:
        "This is used to signal that files were written by a specific invocation, by the python SDK, or by something else entirely. Origination is conceptually similar to HTTP User Agents.",
      is_sortable: false,
      label: "origination",
      namespace: "files",
    },
    {
      aliases: ["name", "path"],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail:
        "Full path to the file in the context of its associated resource.",
      documentation:
        "A dataset might have files with relative paths like `top_level_report.pdf` and `raw_images/img123.jpg`. The relative paths can be used to rebuild a flat list of files into a more conventional folder-based file browser view.",
      is_sortable: true,
      label: "relative_path",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "File size in bytes",
      documentation: null,
      is_sortable: true,
      label: "size",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: ["EQUALS", "NOT_EQUALS"],
      detail: "Lifecycle status of the file.",
      documentation: null,
      is_sortable: false,
      label: "status",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: ["CONTAINS", "NOT_CONTAINS"],
      detail: "Custom string tags added by users to categorize the file.",
      documentation: null,
      is_sortable: false,
      label: "tags",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Unique ID of the upload transaction responsible for this file.",
      documentation: null,
      is_sortable: false,
      label: "upload_id",
      namespace: "files",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "The URI of the file.",
      documentation: null,
      is_sortable: false,
      label: "uri",
      namespace: "files",
    },
  ],
  topics: [
    {
      aliases: ["file_id"],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Unique ID of the resource which the topic belongs to.",
      documentation: "Currently, association ID will always be a file ID.",
      is_sortable: true,
      label: "association_id",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Type of the resource which the topic belongs to.",
      documentation: "Currently, association type will always be 'file'.",
      is_sortable: true,
      label: "association_type",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "ISO8601 timestamp when the topic was created.",
      documentation: null,
      is_sortable: true,
      label: "created",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "User who created the topic.",
      documentation: null,
      is_sortable: true,
      label: "created_by",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "Epoch nanosecond timestamp of the last message in the topic.",
      documentation: null,
      is_sortable: true,
      label: "end_time",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "The number of messages in the topic.",
      documentation: null,
      is_sortable: true,
      label: "message_count",
      namespace: "topics",
    },
    {
      aliases: ["fields"],
      comparators: null,
      detail:
        "Reference a single property within a complex topic, e.g. `sensor.imu.x`.",
      documentation: null,
      is_sortable: true,
      label: "msgpaths",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: null,
      detail: "Custom key-value pairs added by users to categorize the topic.",
      documentation: null,
      is_sortable: false,
      label: "metadata",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "ISO8601 timestamp when the topic was last modified.",
      documentation: null,
      is_sortable: true,
      label: "modified",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "User who last modified the topic.",
      documentation: null,
      is_sortable: true,
      label: "modified_by",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Org which owns the topic.",
      documentation: null,
      is_sortable: false,
      label: "org_id",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Deterministic checksum version of a schema.",
      documentation: null,
      is_sortable: false,
      label: "schema_checksum",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Name of the schema used to serialize messages in the topic.",
      documentation: null,
      is_sortable: false,
      label: "schema_name",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "Monotonically increasing version of a schema.",
      documentation:
        "This is useful for seeing how a schema with a fixed name has evolved over time.",
      is_sortable: false,
      label: "schema_version",
      namespace: "topics",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "Epoch nanosecond timestamp of the first message in the topic.",
      documentation: null,
      is_sortable: true,
      label: "start_time",
      namespace: "topics",
    },
    {
      aliases: ["id"],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Unique numeric ID for the topic.",
      documentation: null,
      is_sortable: true,
      label: "topic_id",
      namespace: "topics",
    },
    {
      aliases: ["name"],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Name for the topic.",
      documentation: null,
      is_sortable: true,
      label: "topic_name",
      namespace: "topics",
    },
  ],
  topic_message_paths: [
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail:
        "Well-known (and simplified) data types, normalized across frameworks/technologies.",
      documentation: null,
      is_sortable: false,
      label: "canonical_data_type",
      namespace: "topic_message_paths",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "ISO8601 timestamp when the message path was created.",
      documentation: null,
      is_sortable: true,
      label: "created",
      namespace: "topic_message_paths",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "User who created the message path.",
      documentation: null,
      is_sortable: true,
      label: "created_by",
      namespace: "topic_message_paths",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail:
        "'Native'/framework-specific data type of the attribute at this path. E.g. 'float32', 'uint8[]', 'geometry_msgs/Pose', 'string'.",
      documentation: null,
      is_sortable: false,
      label: "data_type",
      namespace: "topic_message_paths",
    },
    {
      aliases: ["name", "path", "field"],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "Dot-delimited path to an attribute within a topic datum record.",
      documentation: null,
      is_sortable: true,
      label: "message_path",
      namespace: "topic_message_paths",
    },
    {
      aliases: [],
      comparators: null,
      detail:
        "Custom key-value pairs added by users to categorize the topic.msgpath.",
      documentation: null,
      is_sortable: false,
      label: "metadata",
      namespace: "topic_message_paths",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "ISO8601 timestamp when the message path was last modified.",
      documentation: null,
      is_sortable: true,
      label: "modified",
      namespace: "topic_message_paths",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "CONTAINS",
        "NOT_CONTAINS",
        "LIKE",
        "NOT_LIKE",
      ],
      detail: "User who last modified the message path.",
      documentation: null,
      is_sortable: true,
      label: "modified_by",
      namespace: "topic_message_paths",
    },
    {
      aliases: [],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail:
        "Unique numeric ID for the topic to which this message path is associated.",
      documentation: null,
      is_sortable: true,
      label: "topic_id",
      namespace: "topic_message_paths",
    },
    {
      aliases: ["id"],
      comparators: [
        "EQUALS",
        "NOT_EQUALS",
        "LESS_THAN",
        "LESS_THAN_OR_EQUAL",
        "GREATER_THAN",
        "GREATER_THAN_OR_EQUAL",
      ],
      detail: "Unique numeric ID for the message path.",
      documentation: null,
      is_sortable: true,
      label: "message_path_id",
      namespace: "topic_message_paths",
    },
  ],
};
