import DataObjectIcon from "@mui/icons-material/DataObject";
import DeleteIcon from "@mui/icons-material/Delete";
import DeselectIcon from "@mui/icons-material/Deselect";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { MenuItem, PopoverPosition } from "@mui/material";
import * as React from "react";

import { RobotoStyledPopoverMenu } from "@/components";
import { useDeleteDirectoryMutation } from "@/domain/files/hooks/useDeleteDirectoryMutation";
import { useDeleteFileMutation } from "@/domain/files/hooks/useDeleteFileMutation";
import { useAnalytics } from "@/providers";
import { LoggerService } from "@/service";
import {
  AnalyticsEvent,
  FileNode,
  FileSystemNode,
  isDirectory,
  isFile,
} from "@/types";
import { downloadFile } from "@/utils";

interface ActionMenuProps {
  row: FileSystemNode;
  onDeleteSuccess: (row: FileSystemNode) => void;
  anchorPos: PopoverPosition | undefined;
  setAnchorPos: (arg: PopoverPosition | undefined) => void;
  handleActionConfirmation: (
    title: string,
    text: string,
    action: () => Promise<void>,
  ) => void;
  openContent: () => void;
  fetchSignedUrl: (arg: FileNode) => Promise<string | undefined>;
  checked: boolean;
  metadataExpandable: boolean;
  openMetadata: () => void;
  handleSelectRows: (rows: FileSystemNode[], isSelected: boolean) => void;
  handleRenameNode: () => void;
  rowIsFileType: boolean;
}

export const ActionMenu: React.FC<ActionMenuProps> = ({
  row,
  onDeleteSuccess,
  anchorPos,
  setAnchorPos,
  handleActionConfirmation,
  openContent,
  fetchSignedUrl,
  checked,
  metadataExpandable,
  openMetadata,
  handleSelectRows,
  handleRenameNode,
  rowIsFileType,
}) => {
  const deleteFileMutation = useDeleteFileMutation();
  const deleteDirectoryMutation = useDeleteDirectoryMutation();
  const { trackEvent } = useAnalytics();

  const handleClose = () => {
    setAnchorPos(undefined);
  };

  const onClickDownload = async () => {
    if (!isFile(row)) {
      return;
    }

    const signedUrl = await fetchSignedUrl(row);

    if (signedUrl) {
      downloadFile(row.name, signedUrl);
    }
    handleClose();
  };

  const metadata = metadataExpandable ? (
    <MenuItem
      key="metadata"
      disableRipple
      onClick={() => {
        handleClose();
        openMetadata();
      }}
    >
      <DataObjectIcon />
      Metadata
    </MenuItem>
  ) : null;

  const fileMenuOptions = [
    <MenuItem
      key="view"
      disableRipple
      onClick={() => {
        handleClose();
        openContent();
      }}
    >
      <VisibilityRoundedIcon />
      View
    </MenuItem>,
    metadata,
    <MenuItem
      key="download"
      disableRipple
      onClick={() => {
        void onClickDownload();
      }}
    >
      <DownloadIcon />
      Download
    </MenuItem>,
  ];

  return (
    <>
      <RobotoStyledPopoverMenu
        id="selection-menu"
        MenuListProps={{
          "aria-labelledby": "selection-button",
        }}
        anchorReference="anchorPosition"
        anchorPosition={anchorPos}
        open={Boolean(anchorPos)}
        onClose={handleClose}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            trackEvent(AnalyticsEvent.RenameFileNodeClicked);
            handleRenameNode();
            handleClose();
          }}
        >
          <DriveFileRenameOutlineIcon />
          Rename
        </MenuItem>
        {checked && (
          <MenuItem
            disableRipple
            onClick={() => {
              handleClose();
              handleSelectRows([row], false);
            }}
          >
            <DeselectIcon />
            Deselect
          </MenuItem>
        )}
        {!checked && (
          <MenuItem
            disableRipple
            onClick={() => {
              handleClose();
              handleSelectRows([row], true);
            }}
          >
            <SelectAllIcon />
            Select
          </MenuItem>
        )}

        {rowIsFileType && fileMenuOptions}

        <MenuItem
          disableRipple
          onClick={() => {
            handleClose();
            handleActionConfirmation(
              `Delete ${isFile(row) ? "File" : "Directory"}`,
              `Are you sure you want to delete ${row.name}?`,
              async function () {
                try {
                  if (isDirectory(row)) {
                    await deleteDirectoryMutation.mutateAsync({
                      datasetId: row.directory.association_id,
                      directoryPaths: [row.directory.relative_path],
                    });
                  } else if (isFile(row)) {
                    await deleteFileMutation.mutateAsync({
                      file: row.file,
                    });
                  }

                  onDeleteSuccess(row);
                } catch (e) {
                  LoggerService.error("Failed to delete file", e);
                }
              },
            );
          }}
        >
          <DeleteIcon />
          Delete
        </MenuItem>
      </RobotoStyledPopoverMenu>
    </>
  );
};
