import * as React from "react";

import { ErrorMonitoringService } from "@/service";

export class UploadError extends Error {
  public readonly cause?: Error;
  public readonly detail?: string;

  constructor(
    message: string,
    { cause, detail }: { cause?: Error; detail?: string } = {},
  ) {
    super(message);
    this.name = "UploadError";
    this.cause = cause;
    this.detail = detail;
  }
}

export enum UploadStage {
  // Very beginning of process, before files are processed from the event
  Preprocessing = "Preprocessing",

  // Get credentials, begin transaction
  Initializing = "Initializing",

  // Upload files
  Uploading = "Uploading",

  // Wait for acknowledgement of all uploaded files, end transaction, call UploadComplete
  Completing = "Completing",
}

export interface UploadState {
  currentCount: number;
  currentFile?: string;
  error?: UploadError;
  isUploading: boolean;
  stage: UploadStage;
  totalBytes: number;
  totalCount: number;
  uploadedBytes: number;
}

const INITIAL_STATE: UploadState = {
  currentCount: 0,
  isUploading: false,
  stage: UploadStage.Initializing,
  totalBytes: 0,
  totalCount: 0,
  uploadedBytes: 0,
};

enum ActionType {
  AwaitCompletion = "AwaitCompletion",
  BeginFilePreprocessing = "BeginFilePreprocessing",
  BeginUpload = "BeginUpload",
  CompleteUpload = "CompleteUpload",
  Fail = "Fail",
  Reset = "Reset",
  StartFileUpload = "StartFileUpload",
  TrackUploadProgress = "TrackUploadProgress",
}

interface Action<T = unknown> {
  type: ActionType;
  payload?: T;
}

function awaitCompletion() {
  return {
    type: ActionType.AwaitCompletion,
  };
}

function beginFilePreprocessing() {
  return {
    type: ActionType.BeginFilePreprocessing,
  };
}

function beginUpload(fileCount: number, totalBytes: number) {
  return {
    type: ActionType.BeginUpload,
    payload: {
      fileCount,
      totalBytes,
    },
  };
}

function completeUpload() {
  return {
    type: ActionType.CompleteUpload,
  };
}

function fail(error: Error) {
  return {
    type: ActionType.Fail,
    payload: error,
  };
}

function reset() {
  return {
    type: ActionType.Reset,
  };
}

function startFileUpload(path: string) {
  return {
    type: ActionType.StartFileUpload,
    payload: path,
  };
}

function trackUploadProgress(chunkSize: number) {
  return {
    type: ActionType.TrackUploadProgress,
    payload: chunkSize,
  };
}

export interface UploadActions {
  awaitCompletion: () => void;
  beginFilePreprocessing: () => void;
  beginUpload: (fileCount: number, totalBytes: number) => void;
  completeUpload: () => void;
  fail: (error: Error) => void;
  reset: () => void;
  startFileUpload: (path: string) => void;
  trackUploadProgress: (chunkSize: number) => void;
}

function reducer(state: UploadState, action: Action): UploadState {
  switch (action.type) {
    case ActionType.AwaitCompletion: {
      return {
        ...state,
        stage: UploadStage.Completing,
      };
    }
    case ActionType.BeginUpload: {
      const payload = action.payload as {
        fileCount: number;
        totalBytes: number;
      };
      return {
        ...state,
        isUploading: true,
        currentCount: 0,
        uploadedBytes: 0,
        stage: UploadStage.Uploading,
        totalCount: payload.fileCount,
        totalBytes: payload.totalBytes,
      };
    }
    case ActionType.CompleteUpload: {
      return {
        ...state,
        isUploading: false,
      };
    }
    case ActionType.Fail: {
      return {
        ...state,
        isUploading: false,
        error: action.payload as UploadError,
      };
    }
    case ActionType.BeginFilePreprocessing: {
      return {
        ...state,
        isUploading: true,
        stage: UploadStage.Preprocessing,
      };
    }
    case ActionType.Reset: {
      return INITIAL_STATE;
    }
    case ActionType.StartFileUpload: {
      const currentFile = action.payload as string;
      return {
        ...state,
        currentCount: state.currentCount + 1,
        currentFile,
      };
    }
    case ActionType.TrackUploadProgress: {
      const chunkSize = action.payload as number;
      return {
        ...state,
        uploadedBytes: state.uploadedBytes + chunkSize,
      };
    }
    default:
      return state;
  }
}

export function useUploadState(): [UploadState, UploadActions] {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const actions: UploadActions = React.useMemo(
    () => ({
      awaitCompletion: () => dispatch(awaitCompletion()),
      beginFilePreprocessing: () => dispatch(beginFilePreprocessing()),
      beginUpload: (fileCount: number, totalBytes: number) =>
        dispatch(beginUpload(fileCount, totalBytes)),
      completeUpload: () => dispatch(completeUpload()),
      fail: (error: Error) => {
        ErrorMonitoringService.captureError(error);
        dispatch(fail(error));
      },
      reset: () => dispatch(reset()),
      startFileUpload: (path: string) => dispatch(startFileUpload(path)),
      trackUploadProgress: (chunkSize: number) =>
        dispatch(trackUploadProgress(chunkSize)),
    }),
    [dispatch],
  );

  return [state, actions];
}
