import { Box, Grid, useTheme } from "@mui/material";
import * as React from "react";

import {
  Annotation,
  AnnotationViewFilters,
  Annotations,
} from "@/components/Annotations";
import { FileSystemNode, isDirectory, isFile } from "@/types";
import { basename } from "@/utils";

import { DatasetGridItem } from "./DatasetGridItem";
import { NoFilesFoundAlert } from "./NoFilesFoundAlert";

interface DatasetFileGridProps {
  datasetId?: string;
  currentPage: FileSystemNode[] | null;
  isUploading?: boolean;
  handleRowClick?: (row: FileSystemNode) => void;
  gridItems: number;
  annotationViewFilters: AnnotationViewFilters;
  annotationData?: object;
  selectedItems?: FileSystemNode[];
  handleSelectRows: (rows: FileSystemNode[], isSelected: boolean) => void;
}

export const DatasetFileGrid: React.FC<DatasetFileGridProps> = ({
  datasetId,
  currentPage,
  isUploading,
  handleRowClick,
  gridItems,
  annotationViewFilters,
  annotationData,
  selectedItems,
  handleSelectRows,
}) => {
  const theme = useTheme();

  let datasetGridItemContent = null;

  if (currentPage && currentPage.length > 0) {
    datasetGridItemContent = (
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <>
          {currentPage.map((row) => {
            let annotationsForRow = [] as Annotation[];
            if (annotationData) {
              const annotations = annotationData as Annotations;
              if (annotations && annotations.images) {
                annotationsForRow = annotations?.images[basename(row.name)];
              }
            }

            let key = "";
            let isChecked = false;

            if (isFile(row)) {
              key = row.file.file_id;
              isChecked =
                selectedItems?.some((item) => {
                  if (isFile(item)) {
                    return item.file.file_id === row.file.file_id;
                  } else {
                    return false;
                  }
                }) || false;
            } else if (isDirectory(row)) {
              key = row.directory.name;
              isChecked =
                selectedItems?.some((item) => {
                  if (isDirectory(item)) {
                    return item.directory.name === row.directory.name;
                  } else {
                    return false;
                  }
                }) || false;
            }

            return (
              <Grid
                item
                xs={2}
                sm={3}
                md={gridItems}
                key={key}
                sx={{
                  userSelect: row.type !== "file" ? "none" : "auto",
                }}
              >
                <DatasetGridItem
                  item={row}
                  datasetId={datasetId}
                  onClick={() => {
                    if (row.type !== "file") {
                      handleRowClick && handleRowClick(row);
                    }
                  }}
                  annotations={annotationsForRow}
                  annotationViewFilters={annotationViewFilters}
                  isChecked={isChecked}
                  handleSelectRows={handleSelectRows}
                />
              </Grid>
            );
          })}
        </>
      </Grid>
    );
  } else if (currentPage && currentPage.length === 0) {
    datasetGridItemContent = <NoFilesFoundAlert />;
  }

  return (
    <Box
      sx={{
        mt: theme.spacing(1),
        mb: theme.spacing(1),
        opacity: isUploading ? 0 : 1,
        transition: "opacity 0.2s",
        minHeight: isUploading ? "120px" : "50px",
      }}
    >
      {datasetGridItemContent}
    </Box>
  );
};
