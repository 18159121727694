import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import { Skeleton } from "@mui/material";

import { RobotoTypography } from "@/components";
import { useDataset } from "@/domain/datasets/hooks/";

interface AssociatedDatasetProps {
  datasetId: string;
}

export function AssociatedDataset({ datasetId }: AssociatedDatasetProps) {
  const datasetQuery = useDataset(datasetId);
  if (datasetQuery.isPending) {
    return <Skeleton variant="text" width="150px" />;
  }
  if (datasetQuery.isError) {
    return (
      <span style={{ display: "inline-flex", gap: "0.5rem" }}>
        <ErrorOutlineIcon color="error" fontSize="small" />
        <RobotoTypography component={"span"} fontSize="14px">
          Failed to load dataset
        </RobotoTypography>
      </span>
    );
  }
  return (
    <span style={{ display: "inline-flex", gap: "0.5rem" }}>
      <TopicOutlinedIcon fontSize="small" />
      <RobotoTypography component={"span"} fontSize="14px">
        {datasetQuery.data.dataset_id}
      </RobotoTypography>
    </span>
  );
}
