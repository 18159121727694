import {
  AuthError,
  signUp as amplifySignUp,
  confirmSignUp,
  signIn as amplifySignIn,
  signOut as amplifySignOut,
  resetPassword,
  confirmResetPassword,
  fetchAuthSession,
  resendSignUpCode,
} from "aws-amplify/auth";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";

import { OrgRecord } from "@/domain/orgs";
import { UserRecord } from "@/domain/users";
import { APIService, LoggerService } from "@/service";
import {
  GetOrganizationRolesResponse,
  GetOrganizationsResponse,
  OrganizationRole,
} from "@/service/ApiService";
import { ErrorMonitoringService } from "@/service/ErrorMonitoringService";
import {
  RobotoAPICall,
  UserIdToken,
  UserResponse,
  usersEndpoint,
  usersOrgsEndpoint,
  orgsRolesEndpoint,
} from "@/types";

import { AuthContext } from "./AuthContext";

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthProviderState {
  isAuthenticated: boolean;
  isUserLoading: boolean;
  currentUser: UserRecord | null;
  currentOrganization: OrgRecord | null;
  availableOrganizations: OrgRecord[] | null;
  orgRoles: OrganizationRole[] | null;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthProviderState>({
    isAuthenticated: false,
    currentUser: null,
    isUserLoading: true,
    currentOrganization: null,
    availableOrganizations: [],
    orgRoles: [],
  });

  React.useEffect(
    // Note(Pratik) We may want to opt for a PubSub pattern here so
    // that AuthProvider doesn't take a dependency on ErrorMonitoringService and
    // other dependencies that live outside react.
    function onUserChange() {
      if (authState.currentUser === null) {
        return;
      }

      ErrorMonitoringService.setUser(authState.currentUser);
    },
    [authState.currentUser],
  );

  React.useEffect(
    function onOrgChange() {
      if (authState.currentOrganization === null) {
        return;
      }

      ErrorMonitoringService.setOrg(authState.currentOrganization);
    },
    [authState.currentOrganization],
  );

  const getCurrentOrganization = useCallback(() => {
    let storedOrg: OrgRecord | null = null;

    if (authState.currentOrganization) {
      return authState.currentOrganization;
    }

    const serializedOrg = localStorage.getItem("currentOrg");
    if (serializedOrg && serializedOrg !== "undefined") {
      storedOrg = JSON.parse(serializedOrg) as OrgRecord | null;
    }

    return storedOrg;
  }, [authState.currentOrganization]);

  const deleteCurrentOrganizationFromLocalStorage = useCallback(() => {
    localStorage.removeItem("currentOrg");
  }, []);

  const deleteCurrentOrganization = useCallback(() => {
    deleteCurrentOrganizationFromLocalStorage();

    setAuthState((prevState) => ({
      ...prevState,
      currentOrganization: null,
    }));
  }, [deleteCurrentOrganizationFromLocalStorage]);

  const setCurrentOrganization = (org: OrgRecord) => {
    localStorage.setItem("currentOrg", JSON.stringify(org));

    setAuthState((prevState) => ({
      ...prevState,
      currentOrganization: org,
    }));
  };

  const retrieveOrgRoles = useCallback(
    async (orgId: string): Promise<OrganizationRole[] | null> => {
      const apiCall: RobotoAPICall = {
        endpoint: orgsRolesEndpoint,
        method: "GET",
        orgId: orgId,
      };

      const { response, error } =
        await APIService.authorizedRequest<GetOrganizationRolesResponse>(
          apiCall,
        );

      if (error) {
        LoggerService.warn(
          "Unable to get organizations in AuthProvider: ",
          error,
        );
        return null;
      }

      if (!response?.data) {
        return null;
      }

      return response.data;
    },
    [],
  );

  const retrieveOrgList = useCallback(async (): Promise<OrgRecord[] | null> => {
    const apiCall: RobotoAPICall = {
      endpoint: usersOrgsEndpoint,
      method: "GET",
    };

    const { response, error } =
      await APIService.authorizedRequest<GetOrganizationsResponse>(apiCall);

    if (error) {
      LoggerService.warn(
        "Unable to get organizations in AuthProvider: ",
        error,
      );
      return null;
    }

    if (!response?.data) {
      return null;
    }

    return response.data;
  }, []);

  const retrieveRobotoUser =
    useCallback(async (): Promise<UserRecord | null> => {
      const apiCall: RobotoAPICall = {
        endpoint: usersEndpoint,
        method: "GET",
      };

      const { response, error } =
        await APIService.authorizedRequest<UserResponse>(apiCall);

      if (error) {
        LoggerService.warn("Unable to get user in AuthProvider: ", error);
        return null;
      }

      if (!response?.data) {
        return null;
      }

      return response.data;
    }, []);

  // Check auth status when app boots
  useEffect(() => {
    const checkAuth = async () => {
      //

      const promises = [retrieveRobotoUser(), retrieveOrgList()];

      const results = await Promise.allSettled(promises);

      const user = (
        results[0].status === "fulfilled" ? results[0].value : null
      ) as UserRecord | null;
      const orgList = (
        results[1].status === "fulfilled" ? results[1].value : null
      ) as OrgRecord[] | null;

      let storedOrg: OrgRecord | null = null;

      const serializedOrg = localStorage.getItem("currentOrg");
      if (serializedOrg && serializedOrg !== "undefined") {
        storedOrg = JSON.parse(serializedOrg) as OrgRecord | null;
      }

      let orgRoles = null;

      if (storedOrg) {
        orgRoles = await retrieveOrgRoles(storedOrg.org_id);
      }

      setAuthState({
        isAuthenticated: user !== null,
        currentUser: user,
        isUserLoading: false,
        currentOrganization: storedOrg,
        availableOrganizations: orgList,
        orgRoles: orgRoles,
      });
    };

    void checkAuth();

    // retrieveOrgList, retrieveRobotoUser do not have any dependencies, therefore they can be safely omitted from the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUp = async (
    emailAddress: string,
    password: string,
  ): Promise<string | null> => {
    if (authState.currentUser) {
      return "You are already signed in. Please sign out before creating another account.";
    }

    try {
      await amplifySignUp({
        username: emailAddress,
        password: password,
        options: {
          userAttributes: {
            email: emailAddress,
          },
          autoSignIn: true,
        },
      });

      return null;
    } catch (error: unknown) {
      LoggerService.log("error signing up:", error);

      if (error instanceof AuthError) {
        return error.message;
      }

      return "There was an error signing up. Please try again.";
    }
  };

  const submitVerificationCode = async (
    username: string,
    code: string,
  ): Promise<string | null> => {
    try {
      await confirmSignUp({ username, confirmationCode: code });

      return null;
    } catch (error: unknown) {
      if (error instanceof AuthError) {
        if (error.message) {
          return error.message;
        }
      }
      return "There was an error confirming your account. Please try again.";
    }
  };

  const signIn = async (
    emailAddress: string,
    password: string,
  ): Promise<string | null> => {
    try {
      // isUserLoading is true until the event listener picks up the sign in event
      setAuthState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      await amplifySignIn({ username: emailAddress, password });

      const user = await retrieveRobotoUser();

      const orgList = await retrieveOrgList();

      const currentOrg = getCurrentOrganization();

      let orgRoles = null;
      if (currentOrg) {
        orgRoles = await retrieveOrgRoles(currentOrg.org_id);
      }

      setAuthState({
        isAuthenticated: true,
        currentUser: user,
        isUserLoading: false,
        currentOrganization: currentOrg,
        availableOrganizations: orgList,
        orgRoles: orgRoles,
      });

      return null;
    } catch (error: unknown) {
      LoggerService.log("error signing in:", error);

      setAuthState({
        isAuthenticated: false,
        currentUser: null,
        isUserLoading: false,
        currentOrganization: getCurrentOrganization(),
        availableOrganizations: null,
        orgRoles: null,
      });

      if (error instanceof AuthError) {
        if (error.message) {
          return error.message;
        }
      }

      return "There was an error signing in. Please try again.";
    }
  };

  const signOut = async (): Promise<string | null> => {
    try {
      setAuthState((prevState) => ({
        ...prevState,
        isUserLoading: true,
      }));

      await amplifySignOut();

      deleteCurrentOrganizationFromLocalStorage();

      setAuthState({
        isAuthenticated: false,
        currentUser: null,
        isUserLoading: false,
        currentOrganization: null,
        availableOrganizations: null,
        orgRoles: null,
      });

      return null;
    } catch (error: unknown) {
      LoggerService.log("error signing out:", error);

      setAuthState({
        isAuthenticated: false,
        currentUser: null,
        currentOrganization: null,
        availableOrganizations: null,
        isUserLoading: false,
        orgRoles: null,
      });

      if (error instanceof AuthError) {
        if (error.message) {
          return error.message;
        }
      }

      return "There was an error signing out. Please try again.";
    }
  };

  const getCurrentUser = async (): Promise<UserRecord | null> => {
    if (authState.currentUser) {
      return authState.currentUser;
    }

    const userAPICall: RobotoAPICall = {
      endpoint: usersEndpoint,
      method: "GET",
    };

    const { response, error } =
      await APIService.authorizedRequest<UserResponse>(userAPICall);

    if (error) {
      LoggerService.warn("Error getting current user: ", error);
      return null;
    }

    if (!response?.data) {
      return null;
    }

    return response.data;
  };

  const forgotPasswordInitiate = async (
    emailAddress: string,
  ): Promise<string | null> => {
    try {
      await resetPassword({ username: emailAddress });

      return null;
    } catch (error: unknown) {
      if (error instanceof AuthError) {
        if (error.message) {
          return error.message;
        }
      }

      return "There was an error initiating the password reset process. Please try again.";
    }
  };

  const forgotPasswordComplete = async (
    emailAddress: string,
    code: string,
    newPassword: string,
  ): Promise<string | null> => {
    try {
      await confirmResetPassword({
        username: emailAddress,
        newPassword,
        confirmationCode: code,
      });

      return null;
    } catch (error: unknown) {
      if (error instanceof AuthError) {
        if (error.message) {
          return error.message;
        }
      }

      return "There was an error completing the password reset process. Please try again.";
    }
  };

  const parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload) as UserIdToken;
  };

  const getUserIdToken = async (): Promise<UserIdToken | null> => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString() ?? "";
      const payload = parseJwt(idToken);

      return payload;
    } catch (e) {
      LoggerService.log("No current user session found: ", e);
      return null;
    }
  };

  const refreshOrgList = async () => {
    const orgs = await retrieveOrgList();

    setAuthState((prevState) => ({
      ...prevState,
      availableOrganizations: orgs,
    }));
  };

  const resendVerificationCode = async (emailAddress: string) => {
    try {
      await resendSignUpCode({ username: emailAddress });
      return null;
    } catch (e) {
      ErrorMonitoringService.captureError(e);
      return "Error sending code. Please try again.";
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authState.isAuthenticated,
        isLoading: authState.isUserLoading,
        currentUser: authState.currentUser,
        currentOrganization: authState.currentOrganization,
        availableOrganizations: authState.availableOrganizations,
        orgRoles: authState.orgRoles,
        signUp,
        submitVerificationCode,
        signIn,
        signOut,
        forgotPasswordInitiate,
        forgotPasswordComplete,
        getCurrentUser,
        getCurrentOrganization,
        setCurrentOrganization,
        deleteCurrentOrganization,
        getUserIdToken,
        refreshOrgList,
        resendVerificationCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
