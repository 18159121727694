import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import {
  Box,
  Breadcrumbs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { RobotoLinkHoverUnderline } from "@/components";
import { RobotoSlider } from "@/components/RobotoSlider";
import { PageRoute } from "@/types";

import styles from "./DatasetBreadcrumbs.module.css";
import { TriggerEvaluationStatus } from "./TriggerEvaluationStatus";

interface DatasetBreadcrumbsProps {
  datasetId: string;
  datasetPath: string[];
  displayMode: string;
  gridItems: number;
  handleDisplayToggle: (mode: string) => void;
  handleGridSliderChange: (_event: Event, newValue: number | number[]) => void;
  isUploading: boolean;
  sliderValueFormat: (value: number) => string;
}

export const DatasetBreadcrumbs: React.FC<DatasetBreadcrumbsProps> = ({
  datasetId,
  datasetPath,
  displayMode,
  gridItems,
  handleDisplayToggle,
  handleGridSliderChange,
  isUploading,
  sliderValueFormat,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(0.5),
          alignItems: "center",
        }}
      >
        <Breadcrumbs
          maxItems={4}
          itemsAfterCollapse={2}
          itemsBeforeCollapse={1}
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            key={"datasets-link"}
            to={PageRoute.Datasets}
            style={{
              textDecoration: "none",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.primary.main,
                ":hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Datasets
            </Typography>
          </Link>
          <RobotoLinkHoverUnderline
            key={"datasets-link"}
            variant="body2"
            to={`/datasets/${datasetId}?display=${displayMode}`}
          >
            {datasetId}
          </RobotoLinkHoverUnderline>
          {datasetPath
            .filter((_, index) => index !== 0)
            .map((item, index) => {
              return (
                <RobotoLinkHoverUnderline
                  key={item}
                  variant="body2"
                  to={`/datasets/${datasetPath.slice(0, index + 2).join("/")}`}
                >
                  {item}
                </RobotoLinkHoverUnderline>
              );
            })}
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          gap: theme.spacing(1),
          cursor: "pointer",
          display: {
            xs: "none", // applies to xtra small (mobile) screens
            sm: "flex", // applies to small screens
          },
          alignItems: "center",
        }}
      >
        <TriggerEvaluationStatus
          className={styles.triggerEvaluationStatus}
          datasetId={datasetId}
          isUploading={isUploading}
        />
        <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
          {displayMode === "grid" && (
            <RobotoSlider
              value={gridItems}
              min={1}
              step={1}
              max={12}
              onChange={handleGridSliderChange}
              valueLabelDisplay="auto"
              valueLabelFormat={sliderValueFormat}
              aria-labelledby="non-linear-slider"
              sx={{
                width: "100px",
                height: "5px",
                top: "3px",
                mr: theme.spacing(1),
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                },
              }}
            />
          )}

          <ToggleButtonGroup
            color="primary"
            exclusive
            onChange={(_, newView: string) => {
              handleDisplayToggle(newView);
            }}
            size="small"
            value={displayMode}
          >
            <ToggleButton value={"table"} aria-label="table-view">
              <TableRowsRoundedIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value={"grid"} aria-label="grid-view">
              <GridViewRoundedIcon fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </Box>
  );
};
