import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Skeleton } from "@mui/material";

import { RobotoTypography } from "@/components";
import { useFile } from "@/domain/files/hooks/useFile";

interface AssociatedFileProps {
  fileId: string;
}

export function AssociatedFile({ fileId }: AssociatedFileProps) {
  const fileQuery = useFile(fileId);
  if (fileQuery.isPending) {
    return <Skeleton variant="text" width="150px" />;
  }
  if (fileQuery.isError) {
    return (
      <span style={{ display: "inline-flex", gap: "0.5rem" }}>
        <ErrorOutlineIcon color="error" fontSize="small" />
        <RobotoTypography component={"span"} fontSize="14px">
          Failed to load file
        </RobotoTypography>
      </span>
    );
  }
  return (
    <span style={{ display: "inline-flex", gap: "0.5rem" }}>
      <InsertDriveFileOutlinedIcon fontSize="small" />
      <RobotoTypography component={"span"} fontSize="14px">
        {fileQuery.data?.relative_path}
      </RobotoTypography>
    </span>
  );
}
