import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { Credentials } from "@aws-sdk/types";

import { APIService, LoggerService } from "@/service";
import {
  APIResponse,
  DatasetsCredentialsResponse,
  datasetsCredentialsEndpoint,
  datasetsManifestTransactionBeginEndpoint,
  datasetsManifestTransactionCompleteEndpoint,
} from "@/types";

import { ErrorMonitoringService } from "../ErrorMonitoringService";

import { UploadableFile } from "./filesystem";

class RobotoBucketCredentials implements Credentials {
  public accessKeyId: string;
  public secretAccessKey: string;
  public sessionToken: string;

  private datasetId: string;
  private orgId: string;
  private transactionId: string;

  constructor(
    initialAccessKeyId: string,
    initialSecretAccessKey: string,
    initialSessionToken: string,
    datasetId: string,
    orgId: string,
    transactionId: string,
  ) {
    this.accessKeyId = initialAccessKeyId;
    this.secretAccessKey = initialSecretAccessKey;
    this.sessionToken = initialSessionToken;
    this.datasetId = datasetId;
    this.orgId = orgId;
    this.transactionId = transactionId;
  }

  async refresh(): Promise<void> {
    const newCredentials = await this.fetchNewCredentials();

    this.accessKeyId = newCredentials.accessKeyId;
    this.secretAccessKey = newCredentials.secretAccessKey;
    this.sessionToken = newCredentials.sessionToken as string;
  }

  private async fetchNewCredentials(): Promise<{
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken?: string;
  }> {
    const { response, error } = await getFileUploadCredentials(
      this.datasetId,
      this.orgId,
      this.transactionId,
    );

    if (error || !response?.data) {
      LoggerService.error(
        "Error getting credentials for dataset upload",
        error,
      );
      throw error;
    }

    const { access_key_id, secret_access_key, session_token } = response.data;

    return {
      accessKeyId: access_key_id,
      secretAccessKey: secret_access_key,
      sessionToken: session_token,
    };
  }
}

export const uploadManifestFile = async (
  file: UploadableFile,
  destUri: string,
  datasetId: string,
  orgId: string,
  trackUploadProgress: (arg0: number) => void,
  credentials: DatasetsCredentialsResponse,
  transactionId: string,
): Promise<void> => {
  const { access_key_id, secret_access_key, session_token } = credentials.data;

  const robotoBucketCredentials = new RobotoBucketCredentials(
    access_key_id,
    secret_access_key,
    session_token,
    datasetId,
    orgId,
    transactionId,
  );

  const s3Client = new S3Client({
    region: credentials.data.region,
    credentials: robotoBucketCredentials,
  });

  let keyUri = destUri;
  if (destUri.startsWith("s3://")) {
    keyUri = destUri.slice("s3://".length);
  }

  const keyParts = keyUri.split("/");
  const bucket = keyParts.shift();
  const key = keyParts.join("/");

  // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-lib-storage/Class/Upload/
  const upload = new Upload({
    client: s3Client,
    params: {
      Bucket: bucket,
      Key: key,
      Body: file.file,
    },
  });

  let prevLoaded = 0;
  upload.on("httpUploadProgress", (progress) => {
    const loaded = progress.loaded ?? 0;
    const chunkSize = loaded - prevLoaded;
    prevLoaded = loaded;
    trackUploadProgress(chunkSize);
  });

  await upload.done();
};

// File path -> file size in bytes
type ResourceManifest = { [key: string]: number };

export const getFileUploadCredentials = async (
  datasetId: string,
  orgId: string,
  transactionId: string,
) => {
  const queryParamsObject: { [key: string]: string } = {
    mode: "ReadWrite",
    transaction_id: transactionId,
  };

  const { response, error } =
    await APIService.authorizedRequest<DatasetsCredentialsResponse>({
      method: "GET",
      endpoint: datasetsCredentialsEndpoint,
      pathParams: {
        datasetId,
      },
      queryParams: new URLSearchParams(queryParamsObject),
      orgId,
    });

  if (error) {
    ErrorMonitoringService.captureError(error);
    return { error };
  }

  if (!response?.data) {
    const error = new Error("No data returned from credentials endpoint");
    ErrorMonitoringService.captureError(error);
    return { error };
  }

  return { response };
};

export const beginManifestTransaction = async (
  datasetId: string,
  resourceManifest: ResourceManifest,
  orgId: string,
): Promise<{
  transaction_id: string | null;
  uploadMappings: { [key: string]: string } | null;
  error: Error | null;
}> => {
  const { response, error } = await APIService.authorizedRequest<
    APIResponse<{
      transaction_id: string;
      upload_mappings: { [key: string]: string };
    }>
  >({
    method: "POST",
    endpoint: datasetsManifestTransactionBeginEndpoint,
    apiVersion: "v2",
    requestBody: JSON.stringify({
      resource_manifest: resourceManifest,
      origination: "Roboto Web App",
    }),
    pathParams: {
      datasetId,
    },
    orgId,
  });

  if (error) {
    LoggerService.error("Error beginning transaction", error);
    return {
      transaction_id: null,
      uploadMappings: null,
      error,
    };
  }

  if (response?.data === undefined) {
    const error = new Error("No data returned from transaction endpoint");
    LoggerService.error("No data returned from transaction endpoint", error);
    return {
      transaction_id: null,
      uploadMappings: null,
      error,
    };
  }

  return {
    transaction_id: response.data.transaction_id,
    uploadMappings: response.data.upload_mappings,
    error,
  };
};

export const completeManifestFileUpload = async (
  datasetId: string,
  orgId: string,
  uploadId: string,
) => {
  const { error: errResp } = await APIService.authorizedRequest({
    method: "PUT",
    endpoint: datasetsManifestTransactionCompleteEndpoint,
    apiVersion: "v2",
    orgId: orgId,
    pathParams: {
      datasetId,
      uploadId,
    },
  });

  if (errResp) {
    throw errResp;
  }
};
