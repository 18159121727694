import { HttpClient, type PaginatedResponse, robotoHeaders } from "@/http";

import {
  QueryRecord,
  SubmitRoboqlQueryRequest,
  SubmitStructuredQueryRequest,
  SubmitTermQueryRequest,
} from "./QueryRecord.ts";

export class QueryService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  async submitStructured(
    request: SubmitStructuredQueryRequest,
    options?: Partial<{
      abortSignal: AbortSignal;
      resourceOwnerId: string;
    }>,
  ): Promise<QueryRecord> {
    const url = this.#httpClient.constructUrl("v1/query/submit/structured");

    const response = await this.#httpClient.post(url, {
      body: JSON.stringify(request),
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return await response.json<QueryRecord>();
  }

  async submitTerm(
    request: SubmitTermQueryRequest,
    options?: Partial<{
      abortSignal: AbortSignal;
      resourceOwnerId: string;
    }>,
  ): Promise<QueryRecord> {
    const url = this.#httpClient.constructUrl("v1/query/submit/term");

    const response = await this.#httpClient.post(url, {
      body: JSON.stringify(request),
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return await response.json<QueryRecord>();
  }

  async submitRoboql(
    request: SubmitRoboqlQueryRequest,
    options?: Partial<{
      abortSignal: AbortSignal;
      resourceOwnerId: string;
    }>,
  ): Promise<QueryRecord> {
    const url = this.#httpClient.constructUrl("v1/query/submit/roboql");

    const response = await this.#httpClient.post(url, {
      body: JSON.stringify(request),
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });

    return await response.json<QueryRecord>();
  }

  async getQueryRecord(
    queryId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
    }>,
  ): Promise<QueryRecord> {
    const url = this.#httpClient.constructUrl(`v1/query/id/${queryId}`);

    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
    });

    return await response.json<QueryRecord>();
  }

  async getQueryResults<T>(
    queryId: string,
    options?: Partial<{
      abortSignal: AbortSignal;
      pageToken: string;
      limit: number;
    }>,
  ): Promise<PaginatedResponse<T>> {
    const searchParams = new URLSearchParams();

    if (options?.pageToken !== undefined) {
      searchParams.append("page_token", options.pageToken);
    }

    if (options?.limit !== undefined) {
      searchParams.append("limit", options.limit.toString());
    }

    const url = this.#httpClient.constructUrl(
      `v1/query/id/${queryId}/results`,
      searchParams,
    );

    const response = await this.#httpClient.get(url, {
      signal: options?.abortSignal,
    });
    return await response.json<PaginatedResponse<T>>();
  }
}
