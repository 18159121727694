// Firefox and Safari don't support the `reviver` arg to `JSON.parse`
// It's a Stage 3 ES proposal as of Jul 2024, so polyfill it
import "core-js/actual/json/parse";

/**
 * Callback used as a `JSON.parse` reviver to ensure that values persisted as `bigint`
 * (TopicRecord::start_time and TopicRecord::end_time) are parsed as such.
 * See in-depth discussion and example here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse#the_reviver_parameter
 * See also:
 * https://github.com/tc39/proposal-json-parse-with-source
 */
export function parseTimestampsAsBigInt(
  key: string,
  value: unknown,
  context?: { source: string },
) {
  if ((key === "start_time" || key === "end_time") && context !== undefined) {
    // Ignore the value because it has already lost precision
    return BigInt(context.source);
  }
  return value;
}
