import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { uploadHandler } from "./uploadHandler";
import { UploadProgress } from "./UploadProgress";
import { type UploadActions, type UploadState } from "./useUploadState";

interface DragAndDropFilesProps {
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  datasetId: string;
  displayOverlayOnFileHover?: boolean;
  filePrefix?: string;
  onDropStart?: () => void;
  onDropComplete: (error?: Error) => Promise<void>;
  onDragOver?: () => void;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  orgId: string;
  uploadActions: UploadActions;
  uploadState: UploadState;
}

export const DragAndDropFiles: React.FC<DragAndDropFilesProps> = ({
  children,
  containerStyle,
  datasetId,
  displayOverlayOnFileHover,
  filePrefix,
  onDropStart,
  onDropComplete,
  onDragOver,
  onDragEnter,
  onDragLeave,
  orgId,
  uploadActions,
  uploadState,
}) => {
  const [fileHovering, setFileHovering] = React.useState(false);

  const theme = useTheme();

  const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    onDropStart?.();
    uploadActions.reset();
    try {
      await uploadHandler(event, orgId, datasetId, uploadActions, filePrefix);
      void onDropComplete();
    } catch (e) {
      const error = e instanceof Error ? e : new Error(String(e));
      uploadActions.fail(error);
      void onDropComplete(error);
    } finally {
      setFileHovering(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: fileHovering
          ? theme.palette.primary.light
          : theme.palette.background.default,
        position: "relative",
        ...containerStyle,
      }}
      onDrop={(e) => {
        void onDrop(e);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        onDragOver?.();
        setFileHovering(true);
      }}
      onDragEnter={(event) => {
        event.preventDefault();
        onDragEnter?.();
        setFileHovering(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();

        // Check if leaving to a child
        if (event.currentTarget.contains(event.relatedTarget as Node)) {
          return;
        }

        onDragLeave?.();
        setFileHovering(false);
      }}
    >
      {children}

      <UploadProgress uploadState={uploadState} />

      {displayOverlayOnFileHover && fileHovering ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.palette.primary.light,
            opacity: 0.5,
          }}
        />
      ) : null}
    </Box>
  );
};
