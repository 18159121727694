function getenv<T>(name: string, defaultValue?: T): T {
  const value = import.meta.env[name] as T;
  if (value === undefined) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    throw new Error(`Required environment variable ${name} is not defined`);
  }
  return value;
}

export class Env {
  public static readonly appEnv = getenv<string>("VITE_REACT_APP_ENV");
  public static readonly mixpanelToken = getenv<string>(
    "VITE_REACT_APP_MIXPANEL_TOKEN",
  );
  public static readonly robotoServiceBaseUrl = getenv<string>("VITE_API_URL");
  public static readonly robotoMapboxToken =
    getenv<string>("VITE_MAPBOX_TOKEN");

  public static readonly sentryDsn = getenv<string>("VITE_SENTRY_DSN");
  public static readonly PostHogPublicToken = getenv<string>(
    "VITE_POSTHOG_API_KEY",
  );
}
