import { SvgIcon, useTheme } from "@mui/material";
import { signInWithRedirect, AuthError } from "aws-amplify/auth";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { RobotoButton } from "@/components";
import { useAuth } from "@/providers";
import { AnalyticsEvent } from "@/types";

import { AmplifyAuthError } from "../types";

export function GoogleIcon() {
  return (
    <SvgIcon>
      {/* https://developers.google.com/identity/branding-guidelines */}
      <svg version="1.1" viewBox="0 0 18 18" style={{ padding: "2px" }}>
        <g id="Google-Button">
          <g id="btn_google_light_normal">
            <g id="logo_googleg_48dp">
              <path
                id="Shape"
                fill="#4285F4"
                d="M17.641,9.205c0-0.638-0.059-1.252-0.164-1.841H9v3.481h4.844
				c-0.209,1.125-0.843,2.079-1.796,2.717v2.258h2.909C16.658,14.253,17.641,11.945,17.641,9.205L17.641,9.205z"
              />
              <path
                id="Shape_1_"
                fill="#34A853"
                d="M9,18c2.43,0,4.467-0.806,5.957-2.181l-2.909-2.258
				C11.242,14.102,10.211,14.42,9,14.42c-2.344,0-4.328-1.582-5.036-3.71H0.957v2.332C2.438,15.983,5.482,18,9,18L9,18z"
              />
              <path
                id="Shape_2_"
                fill="#FBBC05"
                d="M3.964,10.71C3.784,10.17,3.682,9.594,3.682,9
				c0-0.593,0.103-1.17,0.282-1.71V4.958H0.957C0.348,6.173,0,7.548,0,9s0.348,2.826,0.957,4.042L3.964,10.71L3.964,10.71z"
              />
              <path
                id="Shape_3_"
                fill="#EA4335"
                d="M9,3.58c1.321,0,2.508,0.454,3.44,1.346l2.581-2.581
				C13.463,0.892,11.426,0,9,0C5.482,0,2.438,2.017,0.957,4.958L3.964,7.29C4.672,5.163,6.656,3.58,9,3.58L9,3.58z"
              />
              <path id="Shape_4_" fill="none" d="M0,0h18v18H0V0z" />
            </g>
            <g id="handles_square"></g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

interface GoogleButtonProps {
  signUp: boolean;
  buttonWidth: number;
  marginTop: string;
  marginBottom: string;
  onClick: () => void;
}

const GoogleButtonComponent: React.FC<GoogleButtonProps> = ({
  signUp,
  buttonWidth,
  marginTop,
  marginBottom,
  onClick,
}) => {
  const [searchParams] = useSearchParams();
  const inviteId = searchParams.get("inviteId");
  const theme = useTheme();
  const { signOut } = useAuth();

  return (
    <RobotoButton
      data-cy={"sign-in-with-google"}
      size="large"
      sx={{
        width: buttonWidth,
        marginBottom,
        marginTop,
        justifyContent: "space-evenly",
        border: theme.border.thin,
        background: "#FFF",
        ":hover": {
          background: "#fafcfd",
          border: theme.border.thin,
          color: "#333333",
        },
        color: "rgba(0, 0, 0, 0.6)",
      }}
      eventName={
        signUp
          ? AnalyticsEvent.SignUpWithGoogleClicked
          : AnalyticsEvent.SignInWithGoogleClicked
      }
      variant={"outlined"}
      startIcon={<GoogleIcon />}
      onClick={() => {
        onClick();
        void googleSignIn(inviteId, signOut);
      }}
    >
      {signUp ? "Sign up" : "Sign in"} with Google
    </RobotoButton>
  );
};

const googleSignIn = async (
  inviteId: string | null,
  signOut: () => Promise<string | null>,
) => {
  try {
    if (inviteId) {
      await signInWithRedirect({
        provider: "Google",
        customState: JSON.stringify({ inviteId: inviteId }),
      });
    } else {
      await signInWithRedirect({
        provider: "Google",
      });
    }
  } catch (e) {
    if (e instanceof AuthError) {
      if (e.name === `${AmplifyAuthError.UserAlreadyAuthenticatedException}`) {
        await signOut();
        void googleSignIn(inviteId, signOut);
      }
    }
  }
};

export default GoogleButtonComponent;
