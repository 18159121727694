import { IconButton, IconButtonProps } from "@mui/material";
import * as React from "react";

import { useAnalytics } from "@/providers";
import { AnalyticsEvent, MixpanelEventProps } from "@/types";

interface RobotoIconButtonProps extends IconButtonProps {
  eventName?: AnalyticsEvent;
  eventProperties?: MixpanelEventProps[AnalyticsEvent];
}

export const RobotoIconButton: React.FC<RobotoIconButtonProps> = ({
  onClick,
  eventName,
  eventProperties,
  ...props
}) => {
  const { trackEvent } = useAnalytics();

  return (
    <IconButton
      onClick={(event) => {
        if (eventName) {
          trackEvent(eventName, eventProperties);
        }
        if (onClick) {
          onClick(event);
        }
      }}
      {...props}
    >
      {props.children}
    </IconButton>
  );
};
