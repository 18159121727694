import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import { RobotoButton, RobotoTypography, RobotoLogo } from "@/components";
import { useAuth, useNavigation } from "@/providers";
import { AnalyticsEvent } from "@/types";

import { AuthCard } from "./AuthCard";
import { AuthCardTitle } from "./AuthCardTitle";
import { LegalFooter } from "./LegalFooter";

const buttonWidth = 215;

interface ResetPasswordFormProps {
  isVisible: boolean;
  emailAddress: string;
  goBackClicked: () => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  isVisible,
  emailAddress,
  goBackClicked,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <AuthCardTitle title={"Reset Password"} />
        <ResetInput emailAddress={emailAddress} goBackClicked={goBackClicked} />
      </AuthCard>
      <LegalFooter />
    </>
  );
};

interface ResetInputProps {
  emailAddress: string;
  goBackClicked: () => void;
}

const ResetInput: React.FC<ResetInputProps> = ({
  emailAddress,
  goBackClicked,
}) => {
  const theme = useTheme();

  const [code, setCode] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [resendLoading, setResendLoading] = useState<boolean>(false);

  const { forgotPasswordComplete, signIn, forgotPasswordInitiate } = useAuth();

  const { goto } = useNavigation();

  const buttonDisabled = code.length !== 6 || newPassword.length < 8;

  const resendCode = async () => {
    setResendLoading(true);

    await forgotPasswordInitiate(emailAddress);

    setResendLoading(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const err = await forgotPasswordComplete(emailAddress, code, newPassword);

    if (err) {
      setError(err);
      setLoading(false);
      return;
    }

    const signInErr = await signIn(emailAddress, newPassword);

    if (signInErr) {
      // password has been reset, but sign in failed. best to redirect to login at this point and let the user try again
      goto.signIn();
      return;
    } else {
      goto.home();
    }
  };

  return (
    <Box
      component={"form"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: buttonWidth,
      }}
      onSubmit={(e) => {
        void onSubmit(e);
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: theme.spacing(1),
        }}
      >
        <TextField
          label="Verification code"
          type="tel"
          sx={{ marginBottom: theme.spacing(2) }}
          value={code}
          size="small"
          onChange={(e) => {
            setError(undefined);
            setCode(e.target.value);
          }}
        />

        <TextField
          label="New password"
          type="password"
          size="small"
          sx={{ marginBottom: theme.spacing(2) }}
          value={newPassword}
          onChange={(e) => {
            setError(undefined);
            setNewPassword(e.target.value);
          }}
        />
      </Box>

      {loading ? (
        <Box
          sx={{
            width: buttonWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: theme.spacing(2),
          }}
        >
          <CircularProgress size="1.75rem" />
        </Box>
      ) : (
        <RobotoButton
          eventName={AnalyticsEvent.ForgotPasswordContinueClicked}
          variant={"contained"}
          sx={{ width: buttonWidth, marginBottom: theme.spacing(2) }}
          disabled={buttonDisabled}
          type="submit"
        >
          Continue
        </RobotoButton>
      )}

      {resendLoading ? (
        <Box
          sx={{
            width: buttonWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: theme.spacing(2),
          }}
        >
          <CircularProgress size="1.75rem" />
        </Box>
      ) : (
        <RobotoButton
          eventName={AnalyticsEvent.ForgotPasswordResendLinkClicked}
          sx={{ marginBottom: theme.spacing(1), width: buttonWidth }}
          variant={"outlined"}
          onClick={() => {
            void resendCode();
          }}
        >
          Resend code
        </RobotoButton>
      )}

      {error && (
        <RobotoTypography
          variant={"caption"}
          textAlign={"center"}
          sx={{
            color: theme.palette.error.main,
            marginBottom: theme.spacing(2),
          }}
        >
          {error}
        </RobotoTypography>
      )}

      <RobotoButton
        eventName={AnalyticsEvent.ForgotPasswordBackClicked}
        variant={"text"}
        sx={{ width: buttonWidth }}
        onClick={goBackClicked}
      >
        Go back
      </RobotoButton>
    </Box>
  );
};
