import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Dialog, useTheme } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

import {
  RobotoIconButton,
  RobotoTableCell,
  RobotoTableRow,
  RobotoTooltip,
} from "@/components";
import { TriggerEvaluationsForTrigger } from "@/components/TriggerEvaluations";
import { UserLink } from "@/components/UserLink";
import { TriggerRecord } from "@/domain/actions/TriggerRecord";
import { OrgRecord } from "@/domain/orgs";
import { useLazyAPICall } from "@/service/apiHooks";
import { AnalyticsEvent, triggerEndpoint } from "@/types";

import { ActionLink } from "../action/ActionLink";

import { TriggerSwitch } from "./TriggerSwitch";

interface TriggerRowProps {
  trigger: TriggerRecord;
  currentOrg: OrgRecord | null;
  reloadToggle: boolean;
  setReloadToggle: (arg: boolean) => void;
  setAlertDialogText: (arg: string) => void;
  setAlertDialogAction: (
    value: React.SetStateAction<(() => Promise<void>) | undefined>,
  ) => void;
  setAlertDialogOpen: (arg: boolean) => void;
  setErrorText: (arg: string) => void;
}

export const TriggerRow: React.FC<TriggerRowProps> = ({
  trigger,
  currentOrg,
  reloadToggle,
  setReloadToggle,
  setAlertDialogText,
  setAlertDialogAction,
  setAlertDialogOpen,
  setErrorText,
}) => {
  const theme = useTheme();
  const [openEvaluationHistory, setOpenEvaluationHistory] =
    React.useState(false);

  const { initiateRequest: deleteTriggerRequest } = useLazyAPICall();

  function wrapActionWithDialog(text: string, action: () => Promise<void>) {
    setAlertDialogText(text);
    setAlertDialogAction(() => action);
    setAlertDialogOpen(true);
  }
  return (
    <React.Fragment>
      <RobotoTableRow>
        <RobotoTableCell
          sx={{
            minWidth: "200px",
            "& a": {
              color: theme.palette.primary.main,
              textDecoration: "none",
            },
            "& a:hover": {
              textDecoration: "underline",
            },
          }}
        >
          <Link to={`/triggers/${trigger.name}`}>{trigger.name}</Link>
        </RobotoTableCell>
        <RobotoTableCell
          sx={{
            minWidth: "200px",
          }}
        >
          <ActionLink action={trigger.action} />
        </RobotoTableCell>
        <RobotoTableCell>
          <>
            <RobotoIconButton
              onClick={() => setOpenEvaluationHistory(true)}
              size="small"
              title="View trigger evaluations history"
            >
              <OpenInNewIcon fontSize="small" color="primary" />
            </RobotoIconButton>
            <Dialog
              maxWidth={false}
              open={openEvaluationHistory}
              onClose={() => setOpenEvaluationHistory(false)}
            >
              <TriggerEvaluationsForTrigger triggerName={trigger.name} />
            </Dialog>
          </>
        </RobotoTableCell>
        <RobotoTableCell title={new Date(trigger.modified).toLocaleString()}>
          {new Date(trigger.modified).toLocaleString()}
        </RobotoTableCell>
        <RobotoTableCell title={trigger.modified_by || undefined}>
          {trigger.modified_by.includes("@") ? (
            <UserLink userId={trigger.modified_by} />
          ) : (
            trigger.modified_by
          )}
        </RobotoTableCell>
        <RobotoTableCell
          sx={{
            minWidth: "200px",
          }}
        >
          <TriggerSwitch
            active={trigger.enabled}
            triggerName={trigger.name}
            setErrorText={setErrorText}
          />

          <RobotoTooltip title="Delete Trigger">
            <span>
              <RobotoIconButton
                eventName={AnalyticsEvent.TriggerDeleted}
                onClick={() =>
                  wrapActionWithDialog(
                    `Are you sure you want to delete ${trigger.name}?`,
                    async function () {
                      const { error } = await deleteTriggerRequest({
                        endpoint: triggerEndpoint,
                        method: "DELETE",
                        orgId: currentOrg?.org_id,
                        pathParams: { name: trigger.name },
                      });

                      if (!error) {
                        setReloadToggle(!reloadToggle);
                      }
                    },
                  )
                }
              >
                <DeleteIcon color="secondary" />
              </RobotoIconButton>
            </span>
          </RobotoTooltip>
        </RobotoTableCell>
      </RobotoTableRow>
    </React.Fragment>
  );
};
