import { DotLottie, DotLottieReact } from "@lottiefiles/dotlottie-react";
import { Card, CardActionArea, CardContent, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoTypography } from "@/components/RobotoTypography";
import { actions, PanelType, useVizDispatch } from "@/state/visualization";

import { panelTypeToDetails } from "../panelTypes";

interface PanelOptionProps {
  type: PanelType;
}

export function PanelOption({ type }: PanelOptionProps) {
  const dispatch = useVizDispatch();
  const { title, description, icon } = panelTypeToDetails[type];
  const theme = useTheme();
  const [dotLottiePlayer, setDotLottiePlayer] =
    React.useState<DotLottie | null>(null);

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: theme.palette.paper.main,
      }}
    >
      <CardActionArea
        onClick={function createEmptyPanel() {
          dispatch(actions.createPanel(null, type));
        }}
        onMouseEnter={function playAnimation() {
          dotLottiePlayer?.play();
        }}
      >
        <DotLottieReact
          loop
          {...(panelTypeToDetails[type].options ?? {})}
          src={icon}
          dotLottieRefCallback={(dotLottiePlayer) =>
            setDotLottiePlayer(dotLottiePlayer)
          }
          style={{
            padding: panelTypeToDetails[type].padding,
            height: "200px",
            marginTop: "0.5rem",
          }}
        />
        <CardContent>
          <RobotoTypography gutterBottom variant="h6" component="h5">
            {title}
          </RobotoTypography>
          <RobotoTypography variant="body2" color="text.secondary">
            {description}
          </RobotoTypography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
