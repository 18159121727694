import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as React from "react";

import { ErrorMonitoringService } from "@/service";

export function QueryProvider({ children }: React.PropsWithChildren) {
  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        onError: (err, variables) => {
          ErrorMonitoringService.captureError(err, { variables });
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}

      {/** DevTools only enabled for process.env.NODE_ENV === 'development' as per docs */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
