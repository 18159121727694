/**
 * To create an event, follow this three step process:
 * 1. Create an enum value for your event in the AnalyticsEvent enum
 * 2. Create an interface for your event properties in the bottom section of this file
 * 3. Bind your event type to its respective properties in the MixpanelEventProps interface
 */

// Create your event types here
export enum AnalyticsEvent {
  AcceptOrgInviteClicked = "AcceptOrgInviteClicked",
  AccountClicked = "AccountClicked",
  ActionDeleted = "ActionDeleted",
  AddColumnToDatagrid = "AddColumnToDatagrid",
  AddPanelClicked = "AddPanelClicked",
  CancelCreateDatasetFilterClicked = "CancelCreateDatasetFilterClicked",
  CancelDatasetCommentClicked = "CancelDatasetCommentClicked",
  CancelUpdateEventNameClicked = "CancelUpdateEventNameClicked",
  ChangeOrgClicked = "ChangeOrgClicked",
  CloseDatasetSidebarButtonClicked = "CloseDatasetSidebarButtonClicked",
  CollectionPermissionsClicked = "CollectionPermissionsClicked",
  ConfigureOrganizationGoBackClicked = "ConfigureOrganizationGoBackClicked",
  ContinueConfigureOrganizationClicked = "ContinueConfigureOrganizationClicked",
  ContinueWithEmailClicked = "ContinueWithEmailClicked",
  CreateActionClicked = "CreateActionClicked",
  CreateCollectionClicked = "CreateCollectionClicked",
  CreateDatasetClicked = "CreateDatasetClicked",
  CreateDatasetFilterClicked = "CreateDatasetFilterClicked",
  CreateDatasetEventClicked = "CreateDatasetEventClicked",
  CreateDatasetEventFormSubmitClicked = "CreateDatasetEventFormSubmitClicked",
  CreateEventFromSelectionClicked = "CreateEventFromSelectionClicked",
  CreateNewOrganizationClicked = "CreateNewOrganizationClicked",
  CreateTokenClicked = "CreateTokenClicked",
  CreateTriggerClicked = "CreateTriggerClicked",
  CreatedDatasetFromPopover = "CreateDatasetFromPopover",
  DarkModeToggleClicked = "DarkModeToggleClicked",
  DatasetAddColumnCancel = "DatasetAddColumnCancel",
  DatasetAddColumnSave = "DatasetAddColumnSave",
  DatasetDeleted = "DatasetDeleted",
  DatasetExplorerSnackbarClose = "DatasetExplorerSnackbarClose",
  DatasetFileExpandMetadata = "DatasetFileExpandMetadata",
  DeclineOrgInviteClicked = "DeclineOrgInviteClicked",
  DeleteCommentButtonClicked = "DeleteCommentButtonClicked",
  DeleteCommentCancelButtonClicked = "DeleteCommentCancelButtonClicked",
  DeleteCommentConfirmButtonClicked = "DeleteCommentConfirmButtonClicked",
  DeleteDatasetFilterClicked = "DeleteDatasetFilterClicked",
  DeleteEventClicked = "DeleteEventClicked",
  DeleteFileClicked = "DeleteFileClicked",
  DeleteTokenClicked = "DeleteTokenClicked",
  DownloadFileClicked = "DownloadFileClicked",
  EditCommentButtonClicked = "EditCommentButtonClicked",
  ExpandRepoImagesClicked = "ExpandRepoImagesClicked",
  FileFocalActionsClicked = "FileFocalActionsClicked",
  FileFocalAnnotationsClicked = "FileFocalAnnotationsClicked",
  FileFocalDetailsClicked = "FileFocalDetailsClicked",
  FileFocalOpenFileClicked = "FileFocalOpenFileClicked",
  FileFocalDownloadFileClicked = "FileFocalDownloadFileClicked",
  ForgotPasswordBackClicked = "ForgotPasswordBackClicked",
  ForgotPasswordClicked = "ForgotPasswordClicked",
  ForgotPasswordContinueClicked = "ForgotPasswordContinueClicked",
  ForgotPasswordResendLinkClicked = "ForgotPasswordResendLinkClicked",
  ForwardDatasetButtonClicked = "ForwardDatasetButtonClicked",
  HowDoYouWorkContinueClicked = "HowDoYouWorkContinueClicked",
  FinishAuthFlowClicked = "FinishAuthFlowClicked",
  HowDoYouWorkGoBackPressed = "HowDoYouWorkGoBackPressed",
  ImageDeleted = "ImageDeleted",
  InviteAndCompleteClicked = "InviteAndCompleteClicked",
  InviteTeamMembersAddEmailClicked = "InviteTeamMembersAddEmailClicked",
  InviteTeamMembersGoBackClicked = "InviteTeamMembersGoBackClicked",
  InviteTeamMembersRemoveEmailClicked = "InviteTeamMembersRemoveEmailClicked",
  InvocationCancelled = "InvocationCancelled",
  InvocationCloned = "InvocationCloned",
  InvokeActionClicked = "InvokeActionClicked",
  LeftOrg = "LeftOrg",
  LogInClicked = "LogInClicked",
  LogOutClicked = "LogOutClicked",
  MakeActionPrivateClicked = "MakeActonPrivateClicked",
  MakeActionPublicClicked = "MakeActionPublicClicked",
  NavigationIconClicked = "NavigationIconClicked",
  NavigationMenuClicked = "NavigationMenuClicked",
  OrgDeleted = "OrgDeleted",
  OpenRoboQLDocsClicked = "OpenRoboQLDocsClicked",
  PageVisit = "PageVisit",
  PageViewed = "$pageview",
  RepositoryDeleted = "RepositoryDeleted",
  ResendVerificationCodeClicked = "ResendVerificationCodeClicked",
  SearchV2Submitted = "SearchV2Submitted",
  SearchBarAddFilterButtonClicked = "SearchBarAddFilterButtonClicked",
  SearchBarSearchButtonClicked = "SearchBarSearchButtonClicked",
  SearchBarExecuteButtonClicked = "SearchBarExecuteButtonClicked",
  SelectOrgClicked = "SelectOrgClicked",
  SentFeedbackFromPopover = "SentFeedbackFromPopover",
  ShareDatasetButtonClicked = "ShareDatasetButtonClicked",
  UploadFilesButtonClicked = "UploadFilesButtonClicked",
  SideBarAddFilesButtonClicked = "SideBarAddFilesButtonClicked",
  SideBarAddTagButtonClicked = "SideBarAddTagButtonClicked",
  SignInHereClicked = "SignInHereClicked",
  SignInWithEmailClicked = "SignInWithEmailClicked",
  SignInWithGoogleClicked = "SignInWithGoogleClicked",
  SignUpHereClicked = "SignUpHereClicked",
  SignUpWithEmailClicked = "SignUpWithEmailClicked",
  SignUpWithGoogleClicked = "SignUpWithGoogleClicked",
  SkipForNowClicked = "SkipForNowClicked",
  SortDatagridColumn = "SortDatagridColumn",
  SubmitDatasetCommentClicked = "SubmitDatasetCommentClicked",
  SubmitVerificationCodeClicked = "SubmitVerificationCodeClicked",
  TriggerAddFilterButtonClicked = "TriggerAddFilterButtonClicked",
  TriggerDeleted = "TriggerDeleted",
  UpdatedEventNameClicked = "UpdatedEventNameClicked",
  ViewFileClicked = "ViewFileClicked",
  ParameterDeleted = "ParameterDeleted",
  OpenCollectionClicked = "OpenCollectionClicked",
  AddToCollectionClicked = "AddToCollectionClicked",
  RemoveFromCollectionClicked = "RemoveFromCollectionClicked",
  DeleteCollectionClicked = "DeleteCollectionClicked",
  AuditCollectionClicked = "AuditCollectionClicked",
  ItemGoToDatasetClicked = "ItemGoToDatasetClicked",
  FileFocalShareClicked = "FileFocalShareClicked",
  CopyWorkspaceLinkClicked = "CopyWorkspaceLinkClicked",
  ErrorPageVisited = "ErrorPageVisited",
  CopyLinkClicked = "CopyLinkClicked",
  UpdateOrgTierButtonClicked = "UpdateOrgTierButtonClicked",
  SaveFileNodeClicked = "SaveFileNodeClicked",
  CancelSaveFileNodeClicked = "CancelSaveFileNodeClicked",
  RenameFileNodeClicked = "RenameFileNodeClicked",
  SelectInviteClicked = "SelectInviteClicked",
  ConfigureRegionContinueClicked = "ConfigureRegionContinueClicked",
  ConfigureRegionGoBackClicked = "ConfgureRegionGoBackClicked",
}

// Bind your event types to their respective properties here, if desired. Event properties are optional
export type MixpanelEventProps = {
  [AnalyticsEvent.PageVisit]: PageVisitProps;
  [AnalyticsEvent.NavigationIconClicked]: NavigationIconClickedProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

/**
 * Define your event properties below this line
 */

export interface PageVisitProps {
  pageName: string;
}

export interface NavigationIconClickedProps {
  navRoute: string;
}
