import { Box, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";
import { Link, useLocation } from "react-router-dom";

import { AnalyticsEvent } from "@/types";

import { RobotoIconButton } from "../RobotoIconButton";
import { RobotoTooltip } from "../RobotoTooltip";

import { LinkButton } from "./LinkButton";
import { Alignment, NavItemData } from "./types";

export const NavItems = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: theme.spacing(1),
  },
}));

// eslint-disable-next-line react/display-name
export const NavItem = forwardRef<HTMLDivElement, NavItemData>(
  ({ title, alignment, link, target, Icon }, ref) => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const active = link === "/" ? pathname === link : pathname.includes(link);

    if (alignment === Alignment.Left) {
      return (
        <Link to={link} target={target} style={{ textDecoration: "none" }}>
          <LinkButton title={title} active={active} link={link} Icon={Icon} />
        </Link>
      );
    }

    return (
      <Link to={link} style={{ textDecoration: "none" }}>
        <RobotoTooltip title={title}>
          <Box ref={ref} sx={{ marginRight: theme.spacing(2) }}>
            <RobotoIconButton
              eventName={AnalyticsEvent.NavigationIconClicked}
              eventProperties={{ navRoute: link }}
            >
              <Icon />
            </RobotoIconButton>
          </Box>
        </RobotoTooltip>
      </Link>
    );
  },
);
