import rawJSON from "core-js/actual/json/raw-json";

import { AssociationRecord, AssociationType } from "@/domain/association.ts";
import {
  CreateEventRequest,
  EventRecord,
  QueryEventsForAssociationsRequest,
  UpdateEventRequest,
} from "@/domain/events/EventRecord.ts";
import { HttpClient, PaginatedResponse, robotoHeaders } from "@/http";
import { parseTimestampsAsBigInt } from "@/utils/jsonUtils.ts";

interface Options {
  abortSignal: AbortSignal;
  resourceOwnerId: string;
  searchParams: URLSearchParams;
}

export class EventService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async createEvent(
    orgId: string,
    event: CreateEventRequest,
    options?: Partial<Options>,
  ): Promise<EventRecord> {
    const requestUrl = this.#httpClient.constructUrl("v1/events/create");
    const response = await this.#httpClient.post(requestUrl, {
      signal: options?.abortSignal,
      body: JSON.stringify(event, (_key, value: unknown) => {
        return typeof value === "bigint" ? rawJSON(value.toString()) : value;
      }),
      headers: robotoHeaders({
        resourceOwnerId: options?.resourceOwnerId,
        orgId,
      }),
    });
    return response.json<EventRecord>(parseTimestampsAsBigInt);
  }

  public async getEventById(
    eventId: string,
    options?: Partial<Options>,
  ): Promise<EventRecord> {
    const requestUrl = this.#httpClient.constructUrl(`v1/events/id/${eventId}`);
    const response = await this.#httpClient.get(requestUrl, {
      signal: options?.abortSignal,
    });
    return response.json<EventRecord>(parseTimestampsAsBigInt);
  }

  public async getEventsForAssociations(
    associations: {
      datasetIds?: string[];
      fileIds?: string[];
      topicIds?: string[];
      messagePathIds?: string[];
    },
    options?: Partial<Options>,
  ): Promise<EventRecord[]> {
    // The backend will throw a 400 if this is empty, we can just return [] instead
    if (
      (associations.datasetIds?.length ?? 0) === 0 &&
      (associations.fileIds?.length ?? 0) === 0 &&
      (associations.topicIds?.length ?? 0) === 0 &&
      (associations.messagePathIds?.length ?? 0) === 0
    ) {
      return [];
    }

    const requestUrl = this.#httpClient.constructUrl(
      "v1/events/query/for_associations",
    );

    const normalizedAssociations: AssociationRecord[] = [];

    if (associations.datasetIds) {
      associations.datasetIds.forEach((datasetId) => {
        normalizedAssociations.push({
          association_id: datasetId,
          association_type: AssociationType.Dataset,
        });
      });
    }

    if (associations.fileIds) {
      associations.fileIds.forEach((fileId) => {
        normalizedAssociations.push({
          association_id: fileId,
          association_type: AssociationType.File,
        });
      });
    }

    if (associations.topicIds) {
      associations.topicIds.forEach((topicId) => {
        normalizedAssociations.push({
          association_id: topicId.toString(),
          association_type: AssociationType.Topic,
        });
      });
    }

    if (associations.messagePathIds) {
      associations.messagePathIds.forEach((messagePathId) => {
        normalizedAssociations.push({
          association_id: messagePathId,
          association_type: AssociationType.MessagePath,
        });
      });
    }

    const body: QueryEventsForAssociationsRequest = {
      associations: normalizedAssociations,
    };

    const response = await this.#httpClient.post(requestUrl, {
      signal: options?.abortSignal,
      body: JSON.stringify(body),
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    return await response
      .json<PaginatedResponse<EventRecord>>(parseTimestampsAsBigInt)
      .then((response) => response.items);
  }

  public async deleteEvent(
    eventId: string,
    options?: Partial<Options>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(`v1/events/id/${eventId}`);
    const response = await this.#httpClient.delete(requestUrl, {
      signal: options?.abortSignal,
      headers: robotoHeaders({ resourceOwnerId: options?.resourceOwnerId }),
    });
    await response.throwIfError();
  }

  public async updateEvent(
    eventId: string,
    updates: UpdateEventRequest,
    options?: Partial<Options>,
  ): Promise<EventRecord> {
    const requestUrl = this.#httpClient.constructUrl(`v1/events/id/${eventId}`);
    const response = await this.#httpClient.put(requestUrl, {
      signal: options?.abortSignal,
      body: JSON.stringify(updates),
    });
    return response.json<EventRecord>(parseTimestampsAsBigInt);
  }
}
