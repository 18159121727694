import * as React from "react";

import { ErrorMonitoringService, LoggerService } from "@/service";
import {
  UploadableFile,
  beginManifestTransaction,
  completeManifestFileUpload,
  fileFromFileSystemFileEntry,
  fileSystemEntryIsDirectory,
  fileSystemEntryIsFile,
  getFileEntries,
  getFileUploadCredentials,
  uploadManifestFile,
} from "@/service/uploader";

import { type UploadActions, UploadError } from "./useUploadState";

const MAX_FILES_PER_MANIFEST = 500;
const ONE_GB = 1000 * 1000 * 1000;
const MAX_UPLOAD_SIZE = 5 * ONE_GB;

function eventIsDragEvent(event: Event): event is DragEvent {
  return "dataTransfer" in event && event.dataTransfer !== null;
}

function eventIsChangeEvent(event: Event): event is InputEvent {
  return !eventIsDragEvent(event);
}

export const uploadHandler = async (
  event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
  orgId: string,
  datasetId: string,
  uploadActions: UploadActions,
  prefix?: string,
) => {
  return await manifestUploadHandler(
    event,
    orgId,
    datasetId,
    uploadActions,
    prefix,
  );
};

const manifestUploadHandler = async (
  event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
  orgId: string,
  datasetId: string,
  uploadActions: UploadActions,
  prefix?: string,
) => {
  uploadActions.beginFilePreprocessing();

  const nativeEvent = event.nativeEvent;
  nativeEvent.preventDefault();

  const files: UploadableFile[] = [];

  // Need to get the files before any async calls, otherwise the browser will clear the files
  if (eventIsDragEvent(nativeEvent) && nativeEvent.dataTransfer !== null) {
    // Ref: File and Directories API
    const entries = Array.from(nativeEvent.dataTransfer.items).map(
      (item): [FileSystemEntry | null, DataTransferItem] => [
        item.webkitGetAsEntry(),
        item,
      ],
    );
    for (const [fileSystemEntry, dataTransferItem] of entries) {
      if (fileSystemEntry === null) {
        LoggerService.error(
          "Failed to get file system entry",
          dataTransferItem,
        );
        continue;
      }
      if (fileSystemEntryIsFile(fileSystemEntry)) {
        files.push(await fileFromFileSystemFileEntry(fileSystemEntry));
      } else if (fileSystemEntryIsDirectory(fileSystemEntry)) {
        for await (const fileEntry of getFileEntries(fileSystemEntry)) {
          files.push(await fileFromFileSystemFileEntry(fileEntry));
        }
      }
    }
  } else if (eventIsChangeEvent(nativeEvent) && nativeEvent.target !== null) {
    // An input event
    const target = nativeEvent.target as HTMLInputElement;
    Array.prototype.push.apply(
      files,
      Array.from(target.files || []).map((file) => {
        let relativePath = file.webkitRelativePath.endsWith(file.name)
          ? file.webkitRelativePath
          : `${file.webkitRelativePath}/${file.name}`;
        relativePath = relativePath.startsWith("/")
          ? relativePath.slice(1)
          : relativePath;
        return new UploadableFile(file, relativePath);
      }),
    );
  }

  const filteredFiles = files.filter((file) => {
    return (
      file.name !== ".DS_Store" &&
      file.name !== "Thumbs.db" &&
      file.name !== "desktop.ini" &&
      file.name !== ".directory"
    );
  });

  if (filteredFiles.length === 0) {
    throw new Error("Could not determine files to upload. Please try again.");
  }

  for (const file of filteredFiles) {
    if (file.size > MAX_UPLOAD_SIZE) {
      const sizeInGB = file.size / ONE_GB;
      const msg = [
        "Files larger than 5 GB must be uploaded via the CLI.",
        `${file.relativePath} is ${sizeInGB.toFixed(2)} GB.`,
      ].join(" ");
      throw new Error(msg);
    }
  }

  const totalBytes = filteredFiles.reduce((acc, file) => acc + file.size, 0);

  uploadActions.beginUpload(filteredFiles.length, totalBytes);

  while (filteredFiles.length > 0) {
    const batch = createFileBatch(filteredFiles);

    const isLastBatch = filteredFiles.length === 0;

    await uploadFileBatch(
      batch,
      datasetId,
      orgId,
      uploadActions,
      isLastBatch,
      prefix,
    );
  }

  uploadActions.completeUpload();
};

const createFileBatch = (files: UploadableFile[]): UploadableFile[] => {
  const batch = [];

  for (let i = 0; i < MAX_FILES_PER_MANIFEST; i++) {
    const file = files.pop();

    if (file) {
      batch.push(file);
    } else {
      break;
    }
  }

  return batch;
};

const uploadFileBatch = async (
  batch: UploadableFile[],
  datasetId: string,
  orgId: string,
  uploadActions: UploadActions,
  isLastBatch: boolean,
  prefix?: string,
) => {
  const resourceManifest = Object.fromEntries(
    batch.map(({ relativePath, size }) => {
      let computedPath = relativePath;

      if (prefix) {
        computedPath = `${prefix}/${relativePath}`;
      }

      return [computedPath, size];
    }),
  );

  const {
    transaction_id,
    uploadMappings,
    error: beginTxnError,
  } = await beginManifestTransaction(datasetId, resourceManifest, orgId);
  if (
    transaction_id === null ||
    uploadMappings === null ||
    beginTxnError !== null
  ) {
    const msg = beginTxnError
      ? beginTxnError.message
      : "System Error. Please refresh the page or try again later.";
    throw new UploadError(msg, {
      cause: beginTxnError ?? undefined,
      detail: "Failed to begin transaction",
    });
  }

  try {
    const { response: credentials, error } = await getFileUploadCredentials(
      datasetId,
      orgId,
      transaction_id,
    );

    if (!credentials || error) {
      const msg = error
        ? error.message
        : "System Error. Please refresh the page or try again later.";
      throw new UploadError(msg, {
        cause: error ?? undefined,
        detail: "Failed to get upload credentials",
      });
    }

    for (const file of batch) {
      let filePath = file.relativePath;

      if (prefix) {
        filePath = `${prefix}/${file.relativePath}`;
      }

      uploadActions.startFileUpload(filePath);

      await uploadManifestFile(
        file,
        uploadMappings[filePath],
        datasetId,
        orgId,
        uploadActions.trackUploadProgress,
        credentials,
        transaction_id,
      );
    }

    if (isLastBatch) {
      uploadActions.awaitCompletion();
    }

    await completeManifestFileUpload(datasetId, orgId, transaction_id);
  } catch (error) {
    ErrorMonitoringService.captureError(error);
    const msg = error instanceof Error ? error.message : "Upload failed";
    uploadActions.fail(new UploadError(msg));
  }
};
