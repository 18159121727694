import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { AssociationType } from "@/domain/association.ts";
import { TopicRecord } from "@/domain/topics";
import { useTopicById } from "@/domain/topics/hooks/useTopic";
import { LoggerService } from "@/service";

import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";

const linkErrorComponent = (
  <Typography variant="body2" color="error">
    Failed to load link
  </Typography>
);

function defaultLinkName(topicRecord: TopicRecord) {
  return topicRecord.topic_name;
}

interface TopicVizLinkProps {
  topicId: string;
  getLinkName?: (topicRecord: TopicRecord) => string;
}

export const TopicVizLink: React.FC<TopicVizLinkProps> = ({
  topicId,
  getLinkName = defaultLinkName,
}) => {
  const topicQuery = useTopicById(topicId);

  if (topicQuery.isLoading) {
    return (
      <Typography variant="body2">
        <Skeleton variant="text" width="200px" />
      </Typography>
    );
  }

  if (topicQuery.isError) {
    LoggerService.error(topicQuery.error?.message, topicQuery.error);
    return linkErrorComponent;
  }

  if (topicQuery.data?.association.association_type !== AssociationType.File) {
    LoggerService.error("Can only link to topics associated with files.");
    return linkErrorComponent;
  }

  const fileId = topicQuery.data?.association.association_id;
  return (
    <RobotoLinkHoverUnderline
      to={`/files/${fileId ?? ""}?filterTerm=${encodeURIComponent(topicQuery.data.topic_name)}`}
    >
      {getLinkName(topicQuery.data)}
    </RobotoLinkHoverUnderline>
  );
};
