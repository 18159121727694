import { EventSource } from "@/components/events/scope";
import { EventRecord } from "@/domain/events";

import { CreateWorkspaceEventFormDefaults } from "../CreateWorkspaceEventForm/makeCreateEventRequest";

export enum SidebarViewType {
  CreateEvent = "CreateEvent",
  EventDetails = "EventDetails",
  PanelSettings = "PanelSettings",
  TabbedSidebar = "TabbedSidebar",
}

export interface SidebarView<D> {
  type: SidebarViewType;
  data: D;
}

export interface PanelSettingsSidebarProps {
  panelId: string;
  onClose: () => void;
}

export interface CreateEventSidebarProps {
  defaultFields?: CreateWorkspaceEventFormDefaults;
  onSuccess: (event: EventRecord) => void;
  onCancel: () => void;
  sourceOptions: Array<EventSource>;
}

export interface EventDetailsSidebarProps {
  eventId: string;
}

export function isEventDetailsView(
  view: SidebarView<unknown>,
): view is SidebarView<EventDetailsSidebarProps> {
  return view.type === SidebarViewType.EventDetails;
}

export function isTabbedSidebarView(
  view: SidebarView<unknown>,
): view is SidebarView<undefined> {
  return view.type === SidebarViewType.TabbedSidebar;
}

export function isPanelSettingsView(
  view: SidebarView<unknown>,
): view is SidebarView<PanelSettingsSidebarProps> {
  return view.type === SidebarViewType.PanelSettings;
}

export function isCreateEventView(
  view: SidebarView<unknown> | null,
): view is SidebarView<CreateEventSidebarProps> {
  return view?.type === SidebarViewType.CreateEvent;
}
