import AddIcon from "@mui/icons-material/Add";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Badge, Box, Hidden, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import * as React from "react";

import {
  NotificationRecord,
  NotificationChannel,
  ReadStatus,
} from "@/domain/notifications";
import { useAuth } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";
import { ColorModeContext } from "@/providers/theme/MUIThemeProvider";
import { ErrorMonitoringService } from "@/service";
import { usePaginatedAPICall } from "@/service/apiHooks";
import { RobotoAPICall, AnalyticsEvent } from "@/types";

import { RobotoIconButton } from "../../RobotoIconButton";
import { RobotoTooltip } from "../../RobotoTooltip";
import { CreateDatasetPopover } from "../CreateDatasetPopover";
import { FeedbackIcon } from "../FeedbackIcon";
import { NavItems } from "../NavItem";
import { NotificationsPopover } from "../NotificationsPopover/NotificationsPopover";
import { OrgListButton } from "../OrgListButton";
import { ProfileMenu } from "../ProfileMenu";
import { StyledTopBar } from "../StyledTopbar";
import { SupportPopover } from "../SupportPopover";
import { notificationsEndpoint } from "../types";

interface RobotoTopBarProps {
  topBarHeight: string;
  handleDrawerToggle: () => void;
}

const pageSize = 100;

export const RobotoTopBar: React.FC<RobotoTopBarProps> = ({
  topBarHeight,
  handleDrawerToggle,
}) => {
  const theme = useTheme();
  const { currentOrganization, currentUser, orgRoles } = useAuth();
  const { notifications } = React.useContext(DomainServicesContext);

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [lastFetchTime, setLastFetchTime] = useState<number>(0);
  const [loadingMarkAllAsRead, setLoadingMarkAllAsRead] =
    useState<boolean>(false);

  const [createDatasetAnchorEl, setCreateDatasetAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [supportAnchorEl, setSupportAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [pictureAnchorEl, setPictureAnchorEl] = useState<HTMLDivElement | null>(
    null,
  );

  const {
    getFirstPage,
    fetchNextPage,
    loading,
    cache,
    isNextPageAvailable,
    updateItemsInCache,
  } = usePaginatedAPICall<NotificationRecord>();

  let badgeCount = 0;
  let allNotificationsRead = true;

  cache.forEach((notification) => {
    const isAcknowledged =
      notification.lifecycle_status[NotificationChannel.WebUi] ===
      "Acknowledged";

    if (!isAcknowledged) {
      // Only increment count for notifications that have not been acknowledged
      badgeCount++;
    }

    if (notification.read_status === ReadStatus.Unread) {
      allNotificationsRead = false;
    }
  });

  let badgeContent = badgeCount.toString();
  if (badgeCount >= 9) {
    badgeContent = "9+";
  }

  useEffect(() => {
    const fetchData = async () => {
      const apiCall: RobotoAPICall = {
        endpoint: notificationsEndpoint,
        method: "GET",
        orgId: currentOrganization?.org_id ?? "",
      };

      await getFirstPage(apiCall, pageSize);

      setLastFetchTime(Date.now());
    };

    void fetchData();
  }, [currentOrganization?.org_id, getFirstPage]);

  const handleProfileMenuClose = () => {
    setPictureAnchorEl(null);
  };

  const handleCreateDatasetClose = () => {
    setCreateDatasetAnchorEl(null);
  };

  const handleSupportClose = () => {
    setSupportAnchorEl(null);
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };

  const handleMarkAllNotificationsAsRead = () => {
    setLoadingMarkAllAsRead(true);
    notifications
      .markNotificationsAsRead(cache, currentOrganization?.org_id || "")
      .then((updatedNotifications) => {
        const dict: { [key: string]: NotificationRecord } = {};

        updatedNotifications.forEach((notification) => {
          dict[notification.notification_id] = notification;
        });

        updateItemsInCache(dict, "notification_id");
      })
      .catch((error) => {
        ErrorMonitoringService.captureError(error);
      })
      .finally(() => {
        setLoadingMarkAllAsRead(false);
      });
  };

  const handleNotificationUpdate = (notification: NotificationRecord) => {
    const dict: { [key: string]: NotificationRecord } = {};

    dict[notification.notification_id] = notification;

    updateItemsInCache(dict, "notification_id");

    handleNotificationsClose();
  };

  const handleLoadMore = () => {
    void fetchNextPage(pageNumber + 1, pageSize, "url");
    setPageNumber(pageNumber + 1);
  };

  const colorMode = React.useContext(ColorModeContext);

  const [avatarMap, setAvatarMap] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (orgRoles === null) {
      return;
    }

    const avatarMap: { [key: string]: string } = {};

    for (const role of orgRoles) {
      if (role.user.picture_url) {
        avatarMap[role.user.user_id] = role.user.picture_url;
      }
    }

    setAvatarMap(avatarMap);
  }, [orgRoles]);

  return (
    <StyledTopBar topBarHeight={topBarHeight}>
      <OrgListButton userEmail={currentUser?.user_id ?? null} />

      <Hidden smUp implementation="css">
        <RobotoIconButton
          onClick={handleDrawerToggle}
          eventName={AnalyticsEvent.NavigationMenuClicked}
        >
          <MenuIcon />
        </RobotoIconButton>
      </Hidden>
      <Hidden smDown implementation="css">
        <NavItems sx={{ alignItems: "center" }}>
          <RobotoTooltip title={"New Dataset"}>
            <Box sx={{ marginRight: theme.spacing(2) }}>
              <RobotoIconButton
                onClick={(e) => setCreateDatasetAnchorEl(e.currentTarget)}
                eventName={AnalyticsEvent.CreateDatasetClicked}
              >
                <AddIcon />
              </RobotoIconButton>
            </Box>
          </RobotoTooltip>

          <RobotoTooltip title={"Support"}>
            <Box sx={{ marginRight: theme.spacing(2) }}>
              <RobotoIconButton
                onClick={(e) => {
                  setSupportAnchorEl(e.currentTarget);
                }}
              >
                <FeedbackIcon />
              </RobotoIconButton>
            </Box>
          </RobotoTooltip>

          <RobotoTooltip title={"Notifications"}>
            <Box sx={{ marginRight: theme.spacing(2) }}>
              <RobotoIconButton
                data-cy={"notifications-icon"}
                onClick={(e) => {
                  if (cache.length > 0) {
                    notifications
                      .markNotificationsAcknowledged(
                        cache,
                        currentOrganization?.org_id || "",
                      )
                      .then((updatedNotifications) => {
                        const dict: { [key: string]: NotificationRecord } = {};

                        updatedNotifications.forEach((notification) => {
                          dict[notification.notification_id] = notification;
                        });

                        updateItemsInCache(dict, "notification_id");
                      })
                      .catch((error) => {
                        ErrorMonitoringService.captureError(error);
                      });
                  }

                  setNotificationsAnchorEl(e.currentTarget);
                }}
              >
                {badgeCount === 0 ? (
                  <NotificationsOutlinedIcon />
                ) : (
                  <Badge
                    data-cy={"notifications-badge"}
                    badgeContent={badgeContent}
                    color="error"
                  >
                    <NotificationsOutlinedIcon />
                  </Badge>
                )}
              </RobotoIconButton>
            </Box>
          </RobotoTooltip>

          <RobotoTooltip
            title={theme.palette.mode === "dark" ? "Light Mode" : "Dark Mode"}
          >
            <Box sx={{ marginRight: theme.spacing(2) }}>
              <RobotoIconButton
                onClick={colorMode.toggleColorMode}
                eventName={AnalyticsEvent.DarkModeToggleClicked}
              >
                {theme.palette.mode === "dark" ? (
                  <LightModeIcon />
                ) : (
                  <DarkModeOutlinedIcon />
                )}
              </RobotoIconButton>
            </Box>
          </RobotoTooltip>

          <Box
            data-cy={"avatar"}
            sx={{
              marginRight: theme.spacing(2),
              marginLeft: theme.spacing(1),
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              setPictureAnchorEl(e.currentTarget)
            }
          >
            <Avatar
              sx={{ width: 30, height: 30 }}
              src={currentUser?.picture_url}
              imgProps={{ referrerPolicy: "no-referrer" }}
            />
            <i
              style={{
                marginLeft: ".25rem",
                lineHeight: "1em",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="rgb(96, 98, 102)"
                aria-hidden="true"
                style={{
                  height: "14px",
                  width: "14px",
                }}
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </i>
          </Box>
        </NavItems>
      </Hidden>
      <ProfileMenu
        anchorEl={pictureAnchorEl}
        handleClose={handleProfileMenuClose}
        userEmail={currentUser?.user_id ?? null}
        userPicture={currentUser?.picture_url}
        userName={currentUser?.name ?? undefined}
      />
      <CreateDatasetPopover
        anchorEl={createDatasetAnchorEl}
        handleClose={handleCreateDatasetClose}
      />
      <SupportPopover
        anchorEl={supportAnchorEl}
        handleClose={handleSupportClose}
      />
      <NotificationsPopover
        anchorEl={notificationsAnchorEl}
        handleClose={handleNotificationsClose}
        notifications={cache}
        isNextPageAvailable={isNextPageAvailable}
        handleNotificationUpdate={handleNotificationUpdate}
        handleLoadMore={handleLoadMore}
        pageLoading={loading}
        allNotificationsRead={allNotificationsRead}
        handleMarkAllAsRead={handleMarkAllNotificationsAsRead}
        loadingMarkAllAsRead={loadingMarkAllAsRead}
        lastUpdateTimestamp={lastFetchTime}
        avatarMap={avatarMap}
      />
    </StyledTopBar>
  );
};
