import { Popover, Box, Paper, Typography, useTheme } from "@mui/material";
import * as React from "react";

import { NotificationRecord, ReadStatus } from "@/domain/notifications";
import { AnalyticsEvent, Comment } from "@/types";

import { RobotoButton } from "../../RobotoButton";

import { NotificationCell } from "./NotificationCell";
import { NotificationsHeader } from "./NotificationsHeader";

interface NotificationsPopoverProps {
  anchorEl: HTMLButtonElement | null;
  handleClose?: () => void;
  notifications: NotificationRecord[];
  isNextPageAvailable: boolean;
  handleNotificationUpdate: (notification: NotificationRecord) => void;
  handleLoadMore: () => void;
  pageLoading: boolean;
  allNotificationsRead: boolean;
  handleMarkAllAsRead: () => void;
  loadingMarkAllAsRead: boolean;
  lastUpdateTimestamp: number;
  avatarMap: { [key: string]: string };
}

export const NotificationsPopover: React.FC<NotificationsPopoverProps> = ({
  anchorEl,
  handleClose,
  notifications,
  isNextPageAvailable,
  handleNotificationUpdate,
  handleLoadMore,
  pageLoading,
  allNotificationsRead,
  handleMarkAllAsRead,
  loadingMarkAllAsRead,
  lastUpdateTimestamp,
  avatarMap,
}) => {
  const theme = useTheme();

  const commentRef = React.useRef<{ [key: string]: Comment }>({});

  const [onlyShowUnread, setOnlyShowUnread] = React.useState<boolean>(false);

  const handleOnlyShowUnread = () => {
    setOnlyShowUnread(!onlyShowUnread);
  };

  const computedNotifications = notifications.filter((notification) => {
    if (onlyShowUnread) {
      return notification.read_status === ReadStatus.Unread;
    }

    return true;
  });

  let notificationContent = null;

  if (computedNotifications.length > 0) {
    notificationContent = computedNotifications.map((notification) => (
      <NotificationCell
        key={notification.notification_id}
        createdTime={notification.created}
        notifierId={notification.notifier_id}
        notificationType={notification.notification_type}
        notificationId={notification.notification_id}
        readStatus={notification.read_status}
        handleNotificationUpdate={handleNotificationUpdate}
        commentRef={commentRef}
        avatarMap={avatarMap}
      />
    ));
  }

  return (
    <Popover
      id={"popover-notifications"}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: -15,
        horizontal: "center",
      }}
      marginThreshold={5}
    >
      <Box
        component={Paper}
        elevation={24}
        sx={{
          width: "30rem",
          padding: theme.spacing(1.5),
        }}
      >
        <NotificationsHeader
          allNotificationsRead={allNotificationsRead}
          mostRecentNotification={computedNotifications[0] || null}
          handleMarkAllAsRead={handleMarkAllAsRead}
          loadingMarkAllAsRead={loadingMarkAllAsRead}
          handleOnlyShowUnread={handleOnlyShowUnread}
          lastUpdateTimestamp={lastUpdateTimestamp}
        />

        {!pageLoading && computedNotifications.length === 0 && (
          <Box sx={{ mt: theme.spacing(1) }}>
            <Typography variant="caption">
              There are no new notifications.
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: theme.spacing(1),
          }}
        >
          {notificationContent}

          {isNextPageAvailable && (
            <RobotoButton
              eventName={AnalyticsEvent.SentFeedbackFromPopover}
              variant={"text"}
              color="primary"
              onClick={handleLoadMore}
              loading={pageLoading}
              sx={{
                mt: theme.spacing(1),
              }}
            >
              Load more
            </RobotoButton>
          )}
        </Box>
      </Box>
    </Popover>
  );
};
