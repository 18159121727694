import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { FileRecord } from "@/domain/files";
import { useFile } from "@/domain/files/hooks/useFile.ts";
import { LoggerService } from "@/service";

import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";

function defaultLinkName(fileRecord: FileRecord) {
  return fileRecord.relative_path;
}

interface FileVizLinkProps {
  fileId: string;
  getLinkName?: (fileRecord: FileRecord) => string;
}

export const FileVizLink: React.FC<FileVizLinkProps> = ({
  fileId,
  getLinkName = defaultLinkName,
}) => {
  const fileQuery = useFile(fileId);

  if (fileQuery.isLoading) {
    return (
      <Typography variant="body2">
        <Skeleton variant="text" width="200px" />
      </Typography>
    );
  }

  if (fileQuery.isError || fileQuery.data === undefined) {
    LoggerService.error(fileQuery.error?.message, fileQuery.error);
    return (
      <Typography variant="body2" color="error">
        Failed to load event scope
      </Typography>
    );
  }

  return (
    <RobotoLinkHoverUnderline to={`/files/${fileId}`}>
      {getLinkName(fileQuery.data)}
    </RobotoLinkHoverUnderline>
  );
};
