import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoIconButton, RobotoTableCell, RobotoTooltip } from "@/components";
import { AnalyticsEvent } from "@/types";

interface ActionCellProps {
  handleMoreClicked: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

export const ActionCell: React.FC<ActionCellProps> = ({
  handleMoreClicked,
}) => {
  const theme = useTheme();

  return (
    <RobotoTableCell>
      <RobotoTooltip title={"View Operations"}>
        <Box sx={{ width: "fit-content", marginRight: theme.spacing(3) }}>
          <RobotoIconButton
            eventName={AnalyticsEvent.ViewFileClicked}
            sx={{
              padding: 0,
              "&:hover": { bgcolor: "transparent" },
            }}
            onClick={handleMoreClicked}
          >
            <MoreHorizIcon fontSize="small" />
          </RobotoIconButton>
        </Box>
      </RobotoTooltip>
    </RobotoTableCell>
  );
};
