import { Alert, AlertTitle, Box, Paper, useTheme } from "@mui/material";
import * as React from "react";

import { FileRecord } from "@/domain/files";
import { useNavigation } from "@/providers";
import { actions, useVizDispatch } from "@/state/visualization";
import { SearchQueryBody } from "@/types";

import { FileMetadataAndTopics } from "../FileMetadataAndTopics";
import { RobotoDataGrid } from "../RobotoDataGrid";

import { ColumnsModal } from "./ColumnsModal";
import {
  TablePageAction,
  TablePageState,
  tablePageReducer,
} from "./stateReducer";
import { createRows, retrieveMetadataColumns } from "./tableLogic";

const initialFilesQuery: SearchQueryBody = {
  sort_by: "created",
  sort_direction: "DESC",
};

const possibleStandardFilesColumns = [
  "association_id",
  "file_id",
  "modified",
  "relative_path",
  "size",
  "org_id",
  "status",
  "upload_id",
  "origination",
  "created_by",
  "tags",
  "device_id",
  "source",
];

const sortableColumns = new Set<string>();

interface FilesTableProps {
  files: FileRecord[];
  loading: boolean;
  selectedRows?: Set<string>;
  setSelectedRows?: (arg: Set<string>) => void;
  onRowSingleClick?: (dataset: FileRecord) => void;
  variant?: "elevation" | "outlined";
  containerStyle?: React.CSSProperties;
  disableSelection?: boolean;
}

export const FilesTable: React.FC<FilesTableProps> = ({
  files,
  loading,
  selectedRows,
  setSelectedRows = () => {},
  onRowSingleClick = () => {},
  variant,
  containerStyle,
  disableSelection,
}) => {
  const theme = useTheme();

  const filesById = new Map<string, FileRecord>(
    files.map((file) => {
      return [file.file_id, file];
    }),
  );

  const { goto } = useNavigation();
  const vizDispatch = useVizDispatch();

  const [selectedRowsToggle, setSelectedRowsToggle] =
    React.useState<boolean>(false);

  const [state, dispatch] = React.useReducer<
    React.Reducer<TablePageState, TablePageAction>
  >(tablePageReducer, {
    modalOpen: false,
    rightClickedRow: null,
    sidebar: { isOpen: false, dataset: null },
    columns: ["relative_path", "created", "source", "association_id", "tags"],
    tableConfig: { page: 0, rowsPerPage: 25 },
    snackbar: { isOpen: false, message: "" },
    sortColumnIndex: 1,
    sortOrder: "descending",
    lastSearchQuery: initialFilesQuery,
    selectedRows: new Set<string>(),
  });

  const pageData = React.useMemo(() => {
    return files.slice(
      state.tableConfig.page * state.tableConfig.rowsPerPage,
      state.tableConfig.page * state.tableConfig.rowsPerPage +
        state.tableConfig.rowsPerPage,
    );
  }, [files, state.tableConfig]);

  const { rows, allPossibleColumns } = React.useMemo(() => {
    const possibleMetadataColumns: string[] =
      retrieveMetadataColumns(pageData) ?? [];

    const rows = createRows(pageData, state.columns, possibleMetadataColumns);

    const allPossibleColumns = possibleStandardFilesColumns
      .concat(possibleMetadataColumns)
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

    return { rows, allPossibleColumns };
  }, [pageData, state.columns]);

  const noSearchResults =
    Object.keys(state.lastSearchQuery).length > 0 && files.length === 0;

  React.useEffect(() => {
    if (selectedRows && selectedRows.size === 0) {
      setSelectedRowsToggle((prevToggle) => !prevToggle);
      dispatch({
        type: "SET_SELECTED_ROWS",
        payload: { selectedRows: new Set<string>() },
      });
    }
  }, [selectedRows]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: "100%",
        }}
      >
        <Paper
          sx={{
            mt: theme.spacing(2),
          }}
          variant={variant}
        >
          {noSearchResults && (
            <Alert severity="info">
              <AlertTitle>Files</AlertTitle>
              No files match your search criteria. Try changing your search
              terms and try again.
            </Alert>
          )}

          {!noSearchResults && (
            <>
              <RobotoDataGrid
                sortableColumns={sortableColumns}
                sortingOrder={state.sortOrder}
                sortingColumnIndex={state.sortColumnIndex}
                page={state.tableConfig.page}
                rowsPerPage={state.tableConfig.rowsPerPage}
                nextPageButtonDisabled={
                  (state.tableConfig.page + 1) *
                    state.tableConfig.rowsPerPage >=
                  files.length
                }
                currentRowLength={files.length}
                containerStyle={containerStyle}
                loading={loading}
                columnNames={state.columns}
                rows={rows}
                onPageChange={(newPage, rowsPerPage) => {
                  dispatch({
                    type: "SET_TABLE_CONFIG",
                    payload: { page: newPage, rowsPerPage: rowsPerPage },
                  });
                }}
                onRowsPerPageChange={(_page, newRowsPerPage) => {
                  dispatch({
                    type: "SET_TABLE_CONFIG",
                    payload: {
                      page: 0,
                      rowsPerPage: newRowsPerPage as 10 | 25 | 50,
                    },
                  });
                }}
                onColumnSortClicked={() => {
                  // do nothing
                }}
                onAddColumnClick={() => {
                  dispatch({ type: "SET_MODAL_OPEN", payload: true });
                }}
                onRowDoubleClick={(rowId) => {
                  goto.file({
                    fileId: rowId,
                    beforeNavigation: () =>
                      vizDispatch(actions.putFiles([{ fileId: rowId }])),
                  });
                }}
                onRowSingleClick={(rowId: string) => {
                  const fileRecord = filesById.get(rowId);
                  if (onRowSingleClick && fileRecord) {
                    onRowSingleClick(fileRecord);
                  }
                }}
                onSelectedRowsChange={(selectedRows) => {
                  setSelectedRows(selectedRows);
                  dispatch({
                    type: "SET_SELECTED_ROWS",
                    payload: { selectedRows: selectedRows },
                  });
                }}
                isRowSelectable={!disableSelection}
                isRowExpandable={true}
                clearSelectedRowsToggle={selectedRowsToggle}
                expandableContent={(rowId: string) => {
                  const fileRecord = filesById.get(rowId);
                  if (fileRecord) {
                    return (
                      <FileMetadataAndTopics
                        fileId={fileRecord.file_id}
                        fileVersion={fileRecord.version}
                      />
                    );
                  }
                }}
              />
              <ColumnsModal
                open={state.modalOpen}
                handleClose={() => {
                  dispatch({ type: "SET_MODAL_OPEN", payload: false });
                }}
                columns={allPossibleColumns}
                currentColumns={state.columns}
                onSave={(updatedColumns) => {
                  dispatch({ type: "SAVE_COLUMNS", payload: updatedColumns });
                }}
              />
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};
