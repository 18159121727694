import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Skeleton } from "@mui/material";

import { RobotoTypography } from "@/components";
import { useTopicById } from "@/domain/topics/hooks/useTopic";

interface AssociatedTopicProps {
  topicId: string;
}

export function AssociatedTopic({ topicId }: AssociatedTopicProps) {
  const topicQuery = useTopicById(topicId);

  if (topicQuery.isPending) {
    return <Skeleton variant="text" width="150px" />;
  }

  if (topicQuery.isError) {
    return (
      <span style={{ display: "inline-flex", gap: "0.5rem" }}>
        <ErrorOutlineIcon color="error" fontSize="small" />
        <RobotoTypography component={"span"} fontSize="14px">
          Failed to load topic
        </RobotoTypography>
      </span>
    );
  }

  return (
    <span style={{ display: "inline-flex", gap: "0.5rem" }}>
      <AccountTreeOutlinedIcon fontSize="small" />
      <RobotoTypography component={"span"} fontSize="14px">
        {topicQuery.data.topic_name}
      </RobotoTypography>
    </span>
  );
}
