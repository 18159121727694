import mixpanel from "mixpanel-browser";

import { Env } from "@/config/Env";
import { OrgRecord } from "@/domain/orgs";
import { UserRecord } from "@/domain/users";
import { AnalyticsEvent, MixpanelEventProps } from "@/types";

const DEV_ENV_NAME = "dev";
const TEST_ENV_NAME = "test";

/**
 * Use the Singleton pattern to provide an instance of the Mixpanel service
 */

export const MixpanelService = (function () {
  // eslint-disable-next-line prefer-const
  let instance;

  if (instance) {
    return instance;
  }

  mixpanel.init(Env.mixpanelToken, {
    debug: Env.appEnv === DEV_ENV_NAME,
  });

  instance = {
    track<T extends AnalyticsEvent>(
      eventName: T,
      eventProps: MixpanelEventProps[T],
    ) {
      if (Env.appEnv === TEST_ENV_NAME) {
        return;
      }

      mixpanel.track(eventName, eventProps);
    },
    identifyUser(userRecord: UserRecord) {
      if (Env.appEnv === TEST_ENV_NAME) {
        return;
      }

      // Associates a user to every event they send
      mixpanel.identify(userRecord.user_id);
      mixpanel.people.set({
        $email: userRecord.user_id,
      });
    },
    setOrg(orgRecord: OrgRecord) {
      if (Env.appEnv === TEST_ENV_NAME) {
        return;
      }

      // Enables org id to be attached to all tracked events
      mixpanel.register({
        org_id: orgRecord.org_id,
        org_name: orgRecord.name,
      });
    },
  };

  return instance;
})();
