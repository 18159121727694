export { type BaseSchema } from "./base";

import { VERSION } from "./v1";
export {
  type LogPanelState,
  type File,
  type Layout,
  type LayoutItem,
  type ImagePanelConfig,
  type ImagePanelState,
  type MessagePathAttr,
  type MessagePathPart,
  type MessagePathSlice,
  type PanelState,
  type PanelStates,
  type MapPanelState,
  type PlotPanelState,
  type PlotSeries,
  type RawMessagePanelState,
  type State,
  type TopicData,
  isLogPanelState,
  isMapPanelState,
  isImagePanelState,
  isLayoutItem,
  isPlotPanelState,
  isRawMessagePanelState,
  LayoutType,
  messagePathPartIsMessagePathAttr,
  MessagePathPartType,
  PanelType,
  schema as vizSchema,
} from "./v1";

export const CURRENT_VIZ_SCHEMA_VERSION = VERSION;
