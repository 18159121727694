import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import * as React from "react";

import { RobotoButton } from "@/components/RobotoButton";
import { AnalyticsEvent } from "@/types";

import { ModeToggleSearchBarMode } from "../types";

interface SearchModeToggleProps {
  mode: ModeToggleSearchBarMode;
  onModeChanged: (arg: ModeToggleSearchBarMode) => void;
}

export const SearchModeToggle: React.FC<SearchModeToggleProps> = ({
  mode,
  onModeChanged,
}) => {
  const handleChangeMode = (
    _event: React.MouseEvent<HTMLElement>,
    newMode: string,
  ) => {
    if (newMode !== null) {
      onModeChanged(newMode as ModeToggleSearchBarMode);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ToggleButtonGroup
        color={"primary"}
        value={mode}
        exclusive
        onChange={handleChangeMode}
        sx={{
          height: "32px",
          display: {
            xs: "none",
            sm: "inline-flex",
          },
        }}
      >
        <ToggleButton
          data-cy={"basicQueryButton"}
          value={"basic"}
          sx={{
            textTransform: "none",
          }}
        >
          Basic
        </ToggleButton>
        <ToggleButton
          data-cy={"basicRoboqlButton"}
          value={"roboql"}
          sx={{
            textTransform: "none",
          }}
        >
          RoboQL
        </ToggleButton>
      </ToggleButtonGroup>
      {mode === "roboql" && (
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "inline-flex",
            },
          }}
        >
          <RobotoButton
            variant="text"
            sx={{ mt: "5px" }}
            eventName={AnalyticsEvent.OpenRoboQLDocsClicked}
            disableFocusRipple
            startIcon={<MenuBookRoundedIcon />}
            href="https://docs.roboto.ai/roboql/overview.html"
          >
            <Typography variant="subtitle2">RoboQL Docs</Typography>
          </RobotoButton>
        </Box>
      )}
    </Box>
  );
};
