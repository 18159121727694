/**
 * A file that can be uploaded.
 * Necessary because of browser differences in the File API (whether File::relativePath is set)
 * compounded by different entrypoints to file upload in our application (drag and drop, file input).
 */
export class UploadableFile {
  public readonly file: File;
  public readonly relativePath: string;
  constructor(file: File, relativePath: string) {
    this.file = file;
    this.relativePath = relativePath;
  }

  public get name(): string {
    return this.file.name;
  }

  public get size(): number {
    return this.file.size;
  }
}

export function fileSystemEntryIsFile(
  entry: FileSystemEntry,
): entry is FileSystemFileEntry {
  return entry.isFile;
}

export function fileSystemEntryIsDirectory(
  entry: FileSystemEntry,
): entry is FileSystemDirectoryEntry {
  return entry.isDirectory;
}

/**
 * Iteratively yield FileSystemEntries from a directory.
 *
 * Slightly modified from a sample in the W3C File and Directory Entries API spec.
 */
export async function* getFileEntries(
  dirEntry: FileSystemDirectoryEntry,
): AsyncGenerator<FileSystemFileEntry> {
  const reader = dirEntry.createReader();
  const getNextBatch = () => {
    return new Promise<FileSystemEntry[]>((resolve, reject) => {
      reader.readEntries(resolve, reject);
    });
  };

  let entries;
  do {
    entries = await getNextBatch();
    for (const entry of entries) {
      if (fileSystemEntryIsFile(entry)) {
        yield entry;
      } else if (fileSystemEntryIsDirectory(entry)) {
        for await (const nestedEntry of getFileEntries(entry)) {
          yield nestedEntry;
        }
      } else {
        throw new Error("Impossible code path");
      }
    }
  } while (entries.length > 0);
}

export function fileFromFileSystemFileEntry(
  fileEntry: FileSystemFileEntry,
): Promise<UploadableFile> {
  return new Promise<UploadableFile>((resolve, reject) => {
    fileEntry.file((file: File) => {
      let relativePath = file.webkitRelativePath;
      if (relativePath === "") {
        if (fileEntry.fullPath.startsWith("/")) {
          relativePath = fileEntry.fullPath.slice(1);
        } else {
          relativePath = fileEntry.fullPath;
        }
      }
      resolve(new UploadableFile(file, relativePath));
    }, reject);
  });
}
