import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { MenuItem } from "@mui/material";
import * as React from "react";

import { FileRecord } from "@/domain/files";
import { useAnalytics } from "@/providers";
import { LoggerService } from "@/service";
import { AnalyticsEvent } from "@/types";

import { useGetFileContents } from "../hooks";

import styles from "./MenuItemError.module.css";

interface OpenFileInNewTabProps {
  fileRecord: FileRecord;
  onClick: () => void;
}

export function OpenFileInNewTab({
  fileRecord,
  onClick,
}: OpenFileInNewTabProps) {
  const getFileContentsAsUrl = useGetFileContents();
  const { trackEvent } = useAnalytics();

  const [error, setError] = React.useState<string | null>(null);

  const openFile = () => {
    getFileContentsAsUrl(fileRecord)
      .then((signedUrl) => {
        trackEvent(AnalyticsEvent.FileFocalOpenFileClicked);
        window.open(signedUrl, "_blank", "noopener,noreferrer");
        onClick();
      })
      .catch((error) => {
        LoggerService.error("Failed to open file in new tab", error);
        setError("Failed to open file. Try again.");
      });
  };

  return (
    <>
      <MenuItem onClick={openFile}>
        <OpenInNewIcon fontSize="small" />
        Open file in new tab
      </MenuItem>
      <div className={styles.errorText}>{error}</div>
    </>
  );
}
