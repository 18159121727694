import { useTheme } from "@mui/material";
import * as React from "react";

import { RobotoTypography, RobotoLogo, RobotoButton } from "@/components";
import { OrgInviteRecord } from "@/domain/orgs";
import { useNavigation } from "@/providers";
import { AnalyticsEvent } from "@/types";

import { AuthCard } from "./AuthCard";
import { AuthCardTitle } from "./AuthCardTitle";
import styles from "./AuthFlowButton.module.css";
import { LegalFooter } from "./LegalFooter";

interface PendingInvitesFormProps {
  isVisible: boolean;
  invites: OrgInviteRecord[] | null;
  skipInvites: () => void;
}

export const PendingInvitesForm: React.FC<PendingInvitesFormProps> = ({
  isVisible,
  invites,
  skipInvites,
}) => {
  const theme = useTheme();

  const { goto } = useNavigation();

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <AuthCardTitle title={"Pending Invites"} />

        {invites?.map((orgInviteRecord) => {
          return (
            <RobotoButton
              data-cy={"invite-button"}
              key={orgInviteRecord.invite_id}
              variant={"outlined"}
              eventName={AnalyticsEvent.SelectInviteClicked}
              className={styles.authFlowButton}
              sx={{
                padding: theme.spacing(1),
                display: "flex",
                flexDirection: "column",
                marginBottom: theme.spacing(2),
                cursor: "pointer",
              }}
              onClick={() => {
                goto.invite({ inviteId: orgInviteRecord.invite_id });
              }}
            >
              <RobotoTypography variant="body2" sx={{ textAlign: "center" }}>
                <span style={{ fontWeight: 500 }}>
                  {orgInviteRecord.org.name}
                </span>
              </RobotoTypography>
            </RobotoButton>
          );
        })}

        <RobotoButton
          data-cy={"skip-for-now"}
          eventName={AnalyticsEvent.SkipForNowClicked}
          variant={"outlined"}
          className={styles.authFlowButton}
          sx={{ marginBottom: theme.spacing(2) }}
          onClick={() => {
            skipInvites();
          }}
        >
          Skip for now
        </RobotoButton>
      </AuthCard>

      <LegalFooter />
    </>
  );
};
