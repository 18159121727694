import { TextField, Button } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";

import { EventRecord } from "@/domain/events";
import { useUpdateEvent } from "@/domain/events/hooks";

import { useEphemeralWorkspaceState } from "../WorkspaceCtx";

interface EventDescriptionFormProps {
  id: EventRecord["event_id"];
  description: EventRecord["description"];
  onSubmit: (description: EventRecord["description"]) => void;
}

export function EventDescriptionForm({
  id,
  description,
  onSubmit,
}: EventDescriptionFormProps) {
  const form = useForm({
    defaultValues: {
      description,
    },
  });

  const updateEvent = useUpdateEvent();
  const { updateEventInWorkspace } = useEphemeralWorkspaceState();

  const submissionErrors = form.formState.errors.root?.serverErrors;

  const onSubmitForm: SubmitHandler<{
    description: EventRecord["description"];
  }> = (data) => {
    // Return early if the description didn't change
    if (data.description === description) {
      onSubmit(data.description);
      return;
    }
    return updateEvent
      .mutateAsync({
        id,
        requestBody: {
          description: data.description,
        },
      })
      .then((event) => {
        updateEventInWorkspace(event);
        onSubmit(event.description);
      });
  };

  return (
    <form
      onSubmit={(event) => {
        form
          .handleSubmit(onSubmitForm)(event)
          .catch((err) => {
            const message =
              err instanceof Error
                ? err.message
                : "Failed to save description.";
            form.setError("root.serverErrors", {
              message,
            });
          });
      }}
    >
      <TextField
        {...form.register("description")}
        autoFocus
        defaultValue={description}
        error={form.formState.errors.description !== undefined}
        fullWidth
        helperText={form.formState.errors.description?.message}
        multiline
        size="small"
        minRows={2}
      />
      {submissionErrors && (
        <p style={{ color: "red", margin: "0.5rem 0" }}>
          {submissionErrors.message}
        </p>
      )}
      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          margin: "0.5rem 0",
          justifyContent: "flex-end",
        }}
      >
        <Button type="button" onClick={() => onSubmit(description)}>
          Cancel
        </Button>
        <Button
          disabled={form.formState.isSubmitting}
          variant="contained"
          type="submit"
          disableElevation
        >
          Save
        </Button>
      </div>
    </form>
  );
}
