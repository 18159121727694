import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import { RobotoButton } from "@/components";
import { Menu } from "@/components/Menu";
import { FileRecord } from "@/domain/files";
import { usePopupState } from "@/hooks";
import { AnalyticsEvent } from "@/types";

import { FileDetails } from "../FileDetails";

import { DownloadFile } from "./DownloadFile";
import { OpenFileInNewTab } from "./OpenFileInNewTab";
import { ViewTagsAndMetadata } from "./ViewTagsAndMetadata";

interface ViewFileControlsProps {
  fileRecord: FileRecord;
  handlePopover: (
    component: "annotations" | "details" | null,
    element: HTMLButtonElement | null,
  ) => void;
}

export function ViewFileControls({ fileRecord }: ViewFileControlsProps) {
  const dropdownMenu = usePopupState();
  const tagMetadataPopup = usePopupState();

  return (
    <>
      <RobotoButton
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        eventName={AnalyticsEvent.FileFocalActionsClicked}
        onClick={function openAddPanelMenu(event) {
          dropdownMenu.open(event.currentTarget);
        }}
        size="small"
        variant="outlined"
      >
        File Actions
      </RobotoButton>
      <Menu
        anchorEl={dropdownMenu.anchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={dropdownMenu.isOpen}
        onClose={dropdownMenu.close}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ViewTagsAndMetadata
          onClick={function openTagsAndMetadata() {
            tagMetadataPopup.open();
            dropdownMenu.close();
          }}
        />
        <DownloadFile fileRecord={fileRecord} onClick={dropdownMenu.close} />
        <OpenFileInNewTab
          fileRecord={fileRecord}
          onClick={dropdownMenu.close}
        />
      </Menu>
      <Dialog
        open={tagMetadataPopup.isOpen}
        fullWidth
        onClose={tagMetadataPopup.close}
      >
        <DialogTitle>File Metadata</DialogTitle>
        <DialogContent>
          <FileDetails fileRecord={fileRecord} fileRecordLoading={false} />
        </DialogContent>
      </Dialog>
    </>
  );
}
