import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, SxProps, Theme } from "@mui/material";
import { useState } from "react";

import { ErrorMonitoringService } from "@/service/ErrorMonitoringService";
import { AnalyticsEvent } from "@/types";

import { RobotoIconButton } from "./RobotoIconButton";
import { RobotoTooltip } from "./RobotoTooltip";

interface CopyToClipboardButtonProps {
  text: string;
  eventName?: AnalyticsEvent;
  sx?: SxProps<Theme>;
}

const CopyToClipboardButton = ({
  text,
  eventName = AnalyticsEvent.CopyLinkClicked,
  sx,
}: CopyToClipboardButtonProps) => {
  const [copyMsg, setCopyMsg] = useState("Copy");

  return (
    <RobotoTooltip title={copyMsg}>
      <Box component="span" sx={sx} data-not-draggable="true">
        <RobotoIconButton
          sx={{ padding: 0 }}
          eventName={eventName}
          onClick={function copyText() {
            navigator.clipboard
              .writeText(text)
              .then(() => {
                setCopyMsg("Copied!");
              })
              .catch((e) => {
                setCopyMsg("Failed to copy!");
                ErrorMonitoringService.captureError(e);
              });
          }}
          onBlur={() => setCopyMsg("Copy")}
        >
          <ContentCopyIcon sx={{ fontSize: "0.85rem" }} />
        </RobotoIconButton>
      </Box>
    </RobotoTooltip>
  );
};

export default CopyToClipboardButton;
