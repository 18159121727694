import { type Data, UniqueIdentifier, useDraggable } from "@dnd-kit/core";
import * as React from "react";

import { DragItem } from "./constants";
import styles from "./Draggable.module.css";

export interface DraggableItem<T> extends Data {
  type: DragItem;
  data: T;
}

interface DraggableProps<T> {
  as?: React.ElementType;
  item: DraggableItem<T>;
  id: UniqueIdentifier;
}

/**
 * A wrapper that makes its children draggable within a DnD context.
 */
export function Draggable<T>(
  props: React.PropsWithChildren<DraggableProps<T>>,
) {
  const { as, children, item, id } = props;
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
    data: item,
  });

  const Component = as ?? "div";

  return (
    <Component
      className={styles.draggable}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {children}
    </Component>
  );
}
