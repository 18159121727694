import { Link } from "@mui/material";
import * as React from "react";

import { AssociationRecord } from "@/domain/association";
import { useNavigation } from "@/providers";
import { actions, useVizDispatch } from "@/state/visualization";

import {
  constructPanelForMessagePath,
  determineDefaultPanelType,
} from "../visualization/DndContainer/dropHandlers";
import { type MessagePathNode } from "../visualization/TopicTree";

interface MessagePathLinkProps {
  messagePathNode: MessagePathNode;
  topicAssociation: AssociationRecord;
  linkPrefix?: string;
}

export const MessagePathLink: React.FC<MessagePathLinkProps> = ({
  messagePathNode,
  topicAssociation,
  linkPrefix,
}) => {
  const { goto } = useNavigation();
  const vizDispatch = useVizDispatch();

  const handleClick = () => {
    const panelType = determineDefaultPanelType(messagePathNode);

    goto.file({
      fileId: topicAssociation.association_id,
      beforeNavigation: () => {
        vizDispatch(actions.removeAllPanels());
        vizDispatch(
          actions.putFiles([{ fileId: topicAssociation.association_id }]),
        );
        constructPanelForMessagePath(vizDispatch, messagePathNode, panelType);
      },
    });
  };

  return (
    <Link onClick={handleClick} component="button" underline="hover">
      {linkPrefix}
      {messagePathNode.data.message_path}
    </Link>
  );
};
