import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import {
  AlertDialog,
  RobotoButton,
  RobotoLinkHoverUnderline,
} from "@/components";
import { DatasetRecord } from "@/domain/datasets";
import { OrgRecord } from "@/domain/orgs";
import { UpdateDatasetMetadata } from "@/features/datasets/components/UpdateDatasetMetadata";
import { UpdateDatasetTags } from "@/features/datasets/components/UpdateDatasetTags";
import { useNavigation } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";
import { AnalyticsEvent, Dataset } from "@/types";

interface DatasetDetailsProps {
  datasetId: string;
  currentOrg: OrgRecord | null;
  dataset: Dataset;
  updateCache: (arg: DatasetRecord) => void;
}

export const DatasetDetails: React.FC<DatasetDetailsProps> = ({
  datasetId,
  currentOrg,
  dataset,
  updateCache,
}) => {
  const theme = useTheme();
  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);
  const [alertDialogText, setAlertDialogText] = React.useState<string>("");
  const [alertDialogAction, setAlertDialogAction] =
    React.useState<() => Promise<void>>();
  const [error, setError] = React.useState<Error | null>(null);
  const { datasetService } = React.useContext(DomainServicesContext);
  const { goto } = useNavigation();

  function wrapActionWithDialog(text: string, action: () => Promise<void>) {
    setAlertDialogText(text);
    setAlertDialogAction(() => action);
    setAlertDialogOpen(true);
  }

  return (
    dataset && (
      <>
        <Box>
          {error !== null && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error.message}
            </Alert>
          )}
          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemText
                primary={"Created"}
                secondary={new Date(dataset.created || "").toLocaleString()}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Modified"}
                secondaryTypographyProps={{
                  component: "span",
                }}
                secondary={
                  <>
                    <Typography variant="body2">
                      {new Date(dataset.modified || "").toLocaleString() +
                        " by "}
                      <RobotoLinkHoverUnderline
                        to={`/users/${dataset.modified_by}`}
                      >
                        {dataset.modified_by}
                      </RobotoLinkHoverUnderline>
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Tags"}
                secondaryTypographyProps={{
                  component: "span",
                  mt: theme.spacing(1),
                }}
                secondary={
                  <UpdateDatasetTags
                    initialTags={dataset.tags || []}
                    currentOrg={currentOrg}
                    datasetId={datasetId}
                    onError={(error) => {
                      setError(error);
                    }}
                    onTagsUpdated={updateCache}
                  />
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Metadata"}
                secondaryTypographyProps={{
                  component: "span",
                }}
                secondary={
                  <UpdateDatasetMetadata
                    initialMetadata={dataset.metadata || {}}
                    currentOrg={currentOrg}
                    datasetId={datasetId}
                    onError={(error) => {
                      setError(error);
                    }}
                    onMetadataUpdated={updateCache}
                  />
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Operations"}
                secondaryTypographyProps={{
                  component: "span",
                }}
                secondary={
                  <Box sx={{ mt: theme.spacing(1) }}>
                    <RobotoButton
                      eventName={AnalyticsEvent.DatasetDeleted}
                      variant={"outlined"}
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() =>
                        wrapActionWithDialog(
                          `Are you sure you want to delete dataset ${datasetId}?`,
                          function deleteDataset() {
                            return datasetService
                              .deleteDataset(datasetId, {
                                resourceOwnerId: currentOrg?.org_id,
                              })
                              .then(() => {
                                setError(null);
                                goto.search();
                              })
                              .catch((error) => {
                                const err =
                                  error instanceof Error
                                    ? error
                                    : new Error(String(error));
                                setError(err);
                              });
                          },
                        )
                      }
                    >
                      Delete
                    </RobotoButton>
                  </Box>
                }
              />
            </ListItem>
          </List>
        </Box>

        <AlertDialog
          dialogOpen={alertDialogOpen}
          handleClose={() => {
            setAlertDialogOpen(false);
          }}
          dialogTitle={"Delete Dataset"}
          dialogText={alertDialogText}
          dialogAction={alertDialogAction}
        />
      </>
    )
  );
};
