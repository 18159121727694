import {
  type File,
  type ImagePanelConfig,
  type TopicData,
  type PanelState,
  type State,
} from "./schema";
import { Layout, LayoutItem } from "./schema/v1";

// Base
export enum ActionType {
  AddDataToLogPanel = "addDataToLogPanel",
  AddPathToMapPanel = "AddPathToMapPanel",
  AddSeriesToPlotPanel = "AddSeriesToPlotPanel",
  CreatePanel = "CreatePanel",
  MovePanel = "MovePanel",
  PutImagePanelData = "PutImagePanelData",
  PutFiles = "PutFiles",
  PutRawMessagePanelData = "PutRawMessagePanelData",
  RemoveAllPanels = "RemoveAllPanels",
  RemoveMessagePathFromLogPanel = "RemoveMessagePathFromLogPanel",
  RemovePanel = "RemovePanel",
  RemovePathFromMapPanel = "RemovePathFromMapPanel",
  RemoveSeriesFromPlotPanel = "RemoveSeriesFromPlotPanel",
  ReplaceState = "ReplaceState",
  ResizeLayouts = "ResizeLayouts",
  SetAllLayoutsResizing = "SetAllLayoutsResizing",
  SetImagePanelConfig = "SetImagePanelConfig",
  SetLayoutResizing = "SetLayoutResizing",
  SetMessagePath = "SetMessagePath",
  SetPathStyle = "SetPathStyle",
  SetPathVisibility = "SetPathVisibility",
  SetSeriesStyle = "SetSeriesStyle",
  SetSeriesVisibility = "SetSeriesVisibility",
}

export enum LayoutOrientation {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}

export interface Action<T> {
  payload: T;
  type: ActionType;
}

// AddDataToLogPanel
interface AddDataToLogPanelPayload {
  data: TopicData;
  panelId: string;
}
interface AddDataToLogPanelAction extends Action<AddDataToLogPanelPayload> {}
export function isAddDataToLogPanelAction(
  action: Action<unknown>,
): action is AddDataToLogPanelAction {
  return action.type === ActionType.AddDataToLogPanel;
}
export function addDataToLogPanel(
  panelId: string,
  data: TopicData,
): AddDataToLogPanelAction {
  return {
    payload: {
      data,
      panelId,
    },
    type: ActionType.AddDataToLogPanel,
  };
}

// AddPathToMapPanel
interface AddPathToMapPanelPayload {
  data: TopicData[];
  panelId: string;
}
interface AddPathToMapPanelAction extends Action<AddPathToMapPanelPayload> {}
export function isAddPathToMapPanelAction(
  action: Action<unknown>,
): action is AddPathToMapPanelAction {
  return action.type === ActionType.AddPathToMapPanel;
}
export function addPathToMapPanel(
  panelId: string,
  data: TopicData[],
): AddPathToMapPanelAction {
  return {
    payload: {
      data,
      panelId,
    },
    type: ActionType.AddPathToMapPanel,
  };
}

// AddSeriesToPlotPanel
interface AddSeriesToPlotPanelPayload {
  data: TopicData;
  panelId: string;
}
interface AddSeriesToPlotPanelAction
  extends Action<AddSeriesToPlotPanelPayload> {}
export function isAddSeriesToPlotPanelAction(
  action: Action<unknown>,
): action is AddSeriesToPlotPanelAction {
  return action.type === ActionType.AddSeriesToPlotPanel;
}
export function addSeriesToPlotPanel(
  panelId: string,
  data: TopicData,
): AddSeriesToPlotPanelAction {
  return {
    payload: {
      data,
      panelId,
    },
    type: ActionType.AddSeriesToPlotPanel,
  };
}

// CreatePanel
export interface Placement {
  siblingLayout: Layout | LayoutItem;
  orientation: LayoutOrientation;
}
export interface CreatePanelPayload {
  data: TopicData[] | null;
  type: PanelState["type"];
  placement?: Placement;
}
interface CreatePanelAction extends Action<CreatePanelPayload> {}
export function isCreatePanelAction(
  action: Action<unknown>,
): action is CreatePanelAction {
  return action.type === ActionType.CreatePanel;
}
export function createPanelAction(
  data: TopicData[] | null,
  type: PanelState["type"],
  placement?: Placement,
): CreatePanelAction {
  return {
    payload: {
      data,
      type,
      placement,
    },
    type: ActionType.CreatePanel,
  };
}

// MovePanel
export interface MovePanelPayload {
  panelLayout: LayoutItem;
  placement: Placement;
}
interface MovePanelAction extends Action<MovePanelPayload> {}
export function isMovePanelAction(
  action: Action<unknown>,
): action is MovePanelAction {
  return action.type === ActionType.MovePanel;
}
export function movePanelAction(
  panelLayout: LayoutItem,
  placement: Placement,
): MovePanelAction {
  return {
    payload: {
      panelLayout,
      placement,
    },
    type: ActionType.MovePanel,
  };
}

// PutImagePanelData
interface PutImagePanelDataPayload {
  panelId: string;
  data: TopicData;
}
interface PutImagePanelDataAction extends Action<PutImagePanelDataPayload> {}
export function isPutImagePanelDataAction(
  action: Action<unknown>,
): action is PutImagePanelDataAction {
  return action.type === ActionType.PutImagePanelData;
}
export function putImagePanelData(
  panelId: string,
  data: TopicData,
): PutImagePanelDataAction {
  return {
    payload: {
      panelId,
      data,
    },
    type: ActionType.PutImagePanelData,
  };
}

// PutFiles
interface PutFilesPayload {
  files: File[];
}
interface PutFilesAction extends Action<PutFilesPayload> {}
export function isPutFilesAction(
  action: Action<unknown>,
): action is PutFilesAction {
  return action.type === ActionType.PutFiles;
}
export function putFiles(files: File[]): PutFilesAction {
  return {
    payload: { files },
    type: ActionType.PutFiles,
  };
}

// PutRawMessagePanelData
interface PutRawMessagePanelDataPayload {
  panelId: string;
  data: TopicData;
}
interface PutRawMessagePanelDataAction
  extends Action<PutRawMessagePanelDataPayload> {}
export function isPutRawMessagePanelDataAction(
  action: Action<unknown>,
): action is PutRawMessagePanelDataAction {
  return action.type === ActionType.PutRawMessagePanelData;
}
export function putRawMessagePanelData(
  panelId: string,
  data: TopicData,
): PutRawMessagePanelDataAction {
  return {
    payload: {
      panelId,
      data,
    },
    type: ActionType.PutRawMessagePanelData,
  };
}

// RemoveAllPanels
interface RemoveAllPanelsAction extends Action<undefined> {}
export function isRemoveAllPanelsAction(
  action: Action<unknown>,
): action is RemoveAllPanelsAction {
  return action.type === ActionType.RemoveAllPanels;
}
export function removeAllPanels(): RemoveAllPanelsAction {
  return {
    payload: undefined,
    type: ActionType.RemoveAllPanels,
  };
}

// RemoveDataFromLogPanel
interface RemoveMessagePathFromLogPanelPayload {
  panelId: string;
  messagePathId: string;
}
interface RemoveMessagePathFromLogPanelAction
  extends Action<RemoveMessagePathFromLogPanelPayload> {}
export function isRemoveMessagePathFromLogPanelAction(
  action: Action<unknown>,
): action is RemoveMessagePathFromLogPanelAction {
  return action.type === ActionType.RemoveMessagePathFromLogPanel;
}
export function removeMessagePathFromLogPanel(
  panelId: string,
  messagePathId: string,
): RemoveMessagePathFromLogPanelAction {
  return {
    payload: {
      panelId,
      messagePathId,
    },
    type: ActionType.RemoveMessagePathFromLogPanel,
  };
}

// RemovePanel
export interface RemovePanelPayload {
  panelId: string;
}
interface RemovePanelAction extends Action<RemovePanelPayload> {}
export function isRemovePanelAction(
  action: Action<unknown>,
): action is RemovePanelAction {
  return action.type === ActionType.RemovePanel;
}
export function removePanel(panelId: string): RemovePanelAction {
  return {
    payload: {
      panelId,
    },
    type: ActionType.RemovePanel,
  };
}

// RemovePathFromMapPanel
interface RemovePathFromMapPanelPayload {
  panelId: string;
  pathId: string;
}
interface RemovePathFromMapPanelAction
  extends Action<RemovePathFromMapPanelPayload> {}
export function isRemovePathFromMapPanelAction(
  action: Action<unknown>,
): action is RemovePathFromMapPanelAction {
  return action.type === ActionType.RemovePathFromMapPanel;
}
export function removePathFromMapPanel(
  panelId: string,
  pathId: string,
): RemovePathFromMapPanelAction {
  return {
    payload: {
      panelId,
      pathId,
    },
    type: ActionType.RemovePathFromMapPanel,
  };
}

// RemoveSeriesFromPlotPanel
interface RemoveSeriesFromPlotPanelPayload {
  panelId: string;
  seriesId: string;
}
interface RemoveSeriesFromPlotPanelAction
  extends Action<RemoveSeriesFromPlotPanelPayload> {}
export function isRemoveSeriesFromPlotPanelAction(
  action: Action<unknown>,
): action is RemoveSeriesFromPlotPanelAction {
  return action.type === ActionType.RemoveSeriesFromPlotPanel;
}
export function removeSeriesFromPlotPanel(
  panelId: string,
  seriesId: string,
): RemoveSeriesFromPlotPanelAction {
  return {
    payload: {
      panelId,
      seriesId,
    },
    type: ActionType.RemoveSeriesFromPlotPanel,
  };
}

// ReplaceState
// Intended only for use by the StateEditor component -- use more specific actions in other contexts.
interface ReplaceStatePayload {
  state: State;
}
interface ReplaceStateAction extends Action<ReplaceStatePayload> {}
export function isReplaceStateAction(
  action: Action<unknown>,
): action is ReplaceStateAction {
  return action.type === ActionType.ReplaceState;
}
export function replaceState(state: State): ReplaceStateAction {
  return {
    payload: {
      state,
    },
    type: ActionType.ReplaceState,
  };
}

// SetPathStyle
interface SetPathStylePayload {
  panelId: string;
  pathId: string;
  style: Record<string, unknown>;
}
interface SetPathStyleAction extends Action<SetPathStylePayload> {}
export function isSetPathStyleAction(
  action: Action<unknown>,
): action is SetPathStyleAction {
  return action.type === ActionType.SetPathStyle;
}
export function setPathStyle(
  panelId: string,
  pathId: string,
  style: Record<string, unknown>,
): SetPathStyleAction {
  return {
    payload: {
      panelId,
      pathId,
      style,
    },
    type: ActionType.SetPathStyle,
  };
}

// SetPathVisibility
interface SetPathVisibilityPayload {
  panelId: string;
  pathId: string;
  visible: boolean;
}
interface SetPathVisibilityAction extends Action<SetPathVisibilityPayload> {}
export function isSetPathVisibilityAction(
  action: Action<unknown>,
): action is SetPathVisibilityAction {
  return action.type === ActionType.SetPathVisibility;
}
export function setPathVisibility(
  panelId: string,
  pathId: string,
  visible: boolean,
): SetPathVisibilityAction {
  return {
    payload: {
      panelId,
      pathId,
      visible,
    },
    type: ActionType.SetPathVisibility,
  };
}

// SetSeriesStyle
interface SetSeriesStylePayload {
  panelId: string;
  seriesId: string;
  style: Record<string, unknown>;
}
interface SetSeriesStyleAction extends Action<SetSeriesStylePayload> {}
export function isSetSeriesStyleAction(
  action: Action<unknown>,
): action is SetSeriesStyleAction {
  return action.type === ActionType.SetSeriesStyle;
}
export function setSeriesStyle(
  panelId: string,
  seriesId: string,
  style: Record<string, unknown>,
): SetSeriesStyleAction {
  return {
    payload: {
      panelId,
      seriesId,
      style,
    },
    type: ActionType.SetSeriesStyle,
  };
}

// SetSeriesVisibility
interface SetSeriesVisibilityPayload {
  panelId: string;
  seriesId: string;
  visible: boolean;
}
interface SetSeriesVisibilityAction
  extends Action<SetSeriesVisibilityPayload> {}
export function isSetSeriesVisibilityAction(
  action: Action<unknown>,
): action is SetSeriesVisibilityAction {
  return action.type === ActionType.SetSeriesVisibility;
}
export function setSeriesVisibility(
  panelId: string,
  seriesId: string,
  visible: boolean,
): SetSeriesVisibilityAction {
  return {
    payload: {
      panelId,
      seriesId,
      visible,
    },
    type: ActionType.SetSeriesVisibility,
  };
}

// Update dimensions
interface LayoutDimension {
  id: string;
  relativeSize: number;
}
interface ResizeLayoutPayload {
  layout1: LayoutDimension;
  layout2: LayoutDimension;
}

interface ResizeLayoutsAction extends Action<ResizeLayoutPayload> {}
export function isResizeLayoutsAction(
  action: Action<unknown>,
): action is ResizeLayoutsAction {
  return action.type === ActionType.ResizeLayouts;
}
export function resizeLayouts(
  layout1: LayoutDimension,
  layout2: LayoutDimension,
): ResizeLayoutsAction {
  return {
    payload: {
      layout1,
      layout2,
    },
    type: ActionType.ResizeLayouts,
  };
}

// Set all layouts resizing
interface SetAllLayoutsResizingPayload {
  isResizing: boolean;
}
interface SetAllLayoutsResizingAction
  extends Action<SetAllLayoutsResizingPayload> {}
export function isSetAllLayoutsResizingAction(
  action: Action<unknown>,
): action is SetAllLayoutsResizingAction {
  return action.type === ActionType.SetAllLayoutsResizing;
}
export function setAllLayoutsResizing(
  isResizing: boolean,
): SetAllLayoutsResizingAction {
  return {
    payload: {
      isResizing,
    },
    type: ActionType.SetAllLayoutsResizing,
  };
}

interface SetImagePanelConfigPayload {
  panelId: string;
  config: Partial<ImagePanelConfig>;
}
interface SetImagePanelConfigAction
  extends Action<SetImagePanelConfigPayload> {}
export function isSetImagePanelConfigAction(
  action: Action<unknown>,
): action is SetImagePanelConfigAction {
  return action.type === ActionType.SetImagePanelConfig;
}
export function setImagePanelConfig(
  panelId: string,
  config: Partial<ImagePanelConfig>,
): SetImagePanelConfigAction {
  return {
    payload: {
      panelId,
      config,
    },
    type: ActionType.SetImagePanelConfig,
  };
}

interface SetLayoutResizingPayload {
  layoutIds: string[];
  isResizing: boolean;
}
interface SetLayoutResizingAction extends Action<SetLayoutResizingPayload> {}
export function isSetLayoutResizingAction(
  action: Action<unknown>,
): action is SetLayoutResizingAction {
  return action.type === ActionType.SetLayoutResizing;
}
export function setLayoutResizing(
  layoutIds: string[],
  isResizing: boolean,
): SetLayoutResizingAction {
  return {
    payload: {
      layoutIds,
      isResizing,
    },
    type: ActionType.SetLayoutResizing,
  };
}

interface SetMessagePathPayload {
  messagePath: TopicData["messagePath"];
  panelId: string;
  seriesId?: string;
}
interface SetMessagePathAction extends Action<SetMessagePathPayload> {}
export function isSetMessagePathAction(
  action: Action<unknown>,
): action is SetMessagePathAction {
  return action.type === ActionType.SetMessagePath;
}
export function setMessagePath(
  payload: SetMessagePathPayload,
): SetMessagePathAction {
  return {
    payload,
    type: ActionType.SetMessagePath,
  };
}
