import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CircularProgress, Dialog, useTheme } from "@mui/material";
import classNames from "classnames";
import dayjs from "dayjs";
import * as React from "react";

import { RobotoIconButton, RobotoTypography } from "@/components";
import { TriggerEvaluationsForDataset } from "@/components/TriggerEvaluations";

import styles from "./TriggerEvaluationStatus.module.css";
import { useTriggerEvaluationsSummary } from "./useTriggerEvaluationsSummary";

interface TriggerEvaluationStatusProps {
  className?: classNames.Argument;
  datasetId: string;
  isUploading: boolean;
}

const ICON_SIZE = "0.875rem";

/**
 * Indicates whether trigger evaluations are in progress for the current dataset.
 * If so, will show a loading indicator.
 * If not, will show time at which trigger evaluations last occurred.
 */
export function TriggerEvaluationStatus({
  className,
  datasetId,
  isUploading,
}: TriggerEvaluationStatusProps) {
  const theme = useTheme();
  const summaryResult = useTriggerEvaluationsSummary(datasetId, isUploading);
  const [openEvaluationHistory, setOpenEvaluationHistory] =
    React.useState(false);

  const composedClassName = classNames(className, styles.container);

  if (summaryResult.isPending && !summaryResult.isError) {
    return (
      <RobotoTypography className={composedClassName}>
        <CircularProgress size={ICON_SIZE} />
        Fetching trigger evaluations summary
      </RobotoTypography>
    );
  }

  if (summaryResult.isError && !summaryResult.isRefetchError) {
    // Only show alert if failed to load data initially.
    // Otherwise, OK to show slightly stale status.
    return (
      <RobotoTypography className={composedClassName}>
        <RobotoIconButton
          onClick={() => void summaryResult.refetch()}
          size="small"
        >
          <RefreshIcon fontSize="small" color="primary" />
        </RobotoIconButton>
        Failed to load trigger evaluations summary
      </RobotoTypography>
    );
  }

  const summary = summaryResult.data;

  if (summary.count_pending > 0) {
    return (
      <RobotoTypography className={composedClassName}>
        <CircularProgress size={ICON_SIZE} />
        Evaluating triggers
      </RobotoTypography>
    );
  }

  if (summary.last_evaluation_start !== null) {
    const lastEvaluationStart = dayjs
      .duration(dayjs(summary.last_evaluation_start).diff(dayjs()))
      .humanize(true);
    return (
      <>
        <RobotoTypography className={composedClassName}>
          <span>
            Triggers evaluated&nbsp;
            <span
              title={new Date(summary.last_evaluation_start).toLocaleString()}
            >
              {lastEvaluationStart}
            </span>
            <RobotoIconButton
              onClick={() => setOpenEvaluationHistory(true)}
              size="small"
              title="View trigger evaluation history"
              style={{
                marginLeft: theme.spacing(0.5),
              }}
            >
              <OpenInNewIcon fontSize="small" color="primary" />
            </RobotoIconButton>
          </span>
        </RobotoTypography>
        <Dialog
          maxWidth={false}
          open={openEvaluationHistory}
          onClose={() => setOpenEvaluationHistory(false)}
        >
          <TriggerEvaluationsForDataset datasetId={datasetId} />
        </Dialog>
      </>
    );
  }

  return null;
}
