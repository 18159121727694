import { Box, Typography, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { RobotoTypography } from "../RobotoTypography";

import { UploadStage, UploadState } from "./useUploadState";

interface UploadProgressProps {
  uploadState: UploadState;
}

export function UploadProgress({ uploadState }: UploadProgressProps) {
  const theme = useTheme();

  if (!uploadState.isUploading) {
    return null;
  }

  const progressNumber = Math.floor(
    (uploadState.uploadedBytes / uploadState.totalBytes) * 100,
  );

  let progress = "";

  if (isNaN(progressNumber)) {
    progress = "0%";
  } else {
    progress = `${progressNumber}%`;
  }

  let detail = null;
  if (uploadState.stage === UploadStage.Uploading) {
    detail = (
      <>
        <RobotoTypography
          marginBottom={theme.spacing(1)}
          variant="caption"
          textOverflow="ellipsis"
          overflow="hidden"
          maxWidth="100%"
          whiteSpace="nowrap"
          flex="0 0 auto"
        >
          {uploadState.currentFile ?? null}
        </RobotoTypography>
        <Box position="relative" lineHeight={1}>
          <CircularProgress thickness={2.5} size={40} />
          <Typography
            variant="caption"
            component="p"
            color="text.secondary"
            top="50%"
            left="50%"
            sx={{ transform: "translate(-50%, -60%)" }}
            position="absolute"
          >
            {progress}
          </Typography>
        </Box>
      </>
    );
  } else if (uploadState.stage === UploadStage.Completing) {
    detail = <CircularProgress thickness={2.5} size={30} />;
  } else if (uploadState.stage === UploadStage.Preprocessing) {
    detail = (
      <>
        <RobotoTypography
          marginBottom={theme.spacing(1)}
          variant="caption"
          textOverflow="ellipsis"
          overflow="hidden"
          maxWidth="100%"
          whiteSpace="nowrap"
          flex="0 0 auto"
        >
          Preparing files for upload...
        </RobotoTypography>
        <CircularProgress thickness={2.5} size={30} />
      </>
    );
  }

  let title = `${uploadState.stage}...`;
  if (uploadState.stage === UploadStage.Uploading) {
    if (isNaN(uploadState.currentCount) || isNaN(uploadState.totalCount)) {
      title = "Uploading";
    } else {
      title = `Uploading ${uploadState.currentCount} of ${uploadState.totalCount}`;
    }
  }

  return (
    <Box
      sx={{
        position: "absolute",
        minHeight: "120px",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <RobotoTypography
        marginBottom={theme.spacing(1)}
        textOverflow="ellipsis"
        overflow="hidden"
        maxWidth="100%"
        whiteSpace="nowrap"
        variant="body2"
      >
        {title}
      </RobotoTypography>
      {detail}
    </Box>
  );
}
