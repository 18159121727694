import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SxProps } from "@mui/material";
import * as React from "react";

import { RobotoButton } from "@/components";
import { ActionReference, InvocationRecord } from "@/domain/actions";
import { OrgRecord } from "@/domain/orgs";
import { useNavigation } from "@/providers";
import { AnalyticsEvent } from "@/types";

interface CloneInvocationButtonProps {
  invocation?: InvocationRecord;
  org: OrgRecord | null;
  sx?: SxProps;
}

export default function CloneInvocationButton({
  invocation,
  org,
  sx,
}: CloneInvocationButtonProps) {
  const { goto } = useNavigation();
  const styles = React.useMemo(() => {
    const base = {
      mr: 1,
    };
    if (!sx) {
      return base;
    }

    return {
      ...base,
      ...sx,
    };
  }, [sx]);

  return (
    <>
      <RobotoButton
        eventName={AnalyticsEvent.InvocationCloned}
        onClick={() => {
          const actionRef = {
            name: invocation?.provenance.action.name,
            digest: invocation?.provenance.action.digest,
            owner:
              org?.org_id !== invocation?.provenance.action.org_id
                ? invocation?.provenance.action.org_id
                : undefined,
          } as ActionReference;
          goto.invokeAction({
            action: actionRef,
            datasetId: invocation?.data_source.data_source_id,
            baseInvocation: invocation?.invocation_id,
          });
        }}
        startIcon={<ContentCopyIcon />}
        sx={styles}
        variant="outlined"
        size="small"
        color="primary"
      >
        Clone
      </RobotoButton>
    </>
  );
}
