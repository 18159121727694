import { createContext } from "react";

import { AnalyticsEvent, MixpanelEventProps } from "@/types";

import { LoggerService } from "../../service";

export type AnalyticsService = {
  trackEvent: <T extends AnalyticsEvent>(
    eventName: T,
    props?: MixpanelEventProps[T],
  ) => void;
};

export const AnalyticsContext = createContext<AnalyticsService>({
  trackEvent: () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
  },
});
