import { AddPanel } from "@/components/visualization/controls/AddPanel";

import { ShareWorkspace } from "./ShareWorkspace";

interface WorkspaceControlsProps {
  hide?: boolean;
}

export function WorkspaceControls({ hide = false }: WorkspaceControlsProps) {
  if (hide) {
    return null;
  }

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <AddPanel />
      <ShareWorkspace />
    </div>
  );
}
