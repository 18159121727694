import ShareIcon from "@mui/icons-material/Share";
import * as React from "react";

import { SharedWorkspaceLinkModal, RobotoButton } from "@/components";
import { WorkspaceRecord } from "@/domain/workspaces";
import { usePopupState } from "@/hooks";
import { useAuth } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";
import { LoggerService } from "@/service";
import { useVizState } from "@/state/visualization";
import { AnalyticsEvent } from "@/types";

export function ShareWorkspace() {
  const { currentOrganization } = useAuth();
  const { workspaceService } = React.useContext(DomainServicesContext);
  const state = useVizState();

  const popup = usePopupState();
  const [workspaceRecord, setWorkspaceRecord] =
    React.useState<WorkspaceRecord | null>(null);

  const [error, setError] = React.useState<Error | null>(null);

  const createWorkspace = () => {
    const orgId = currentOrganization?.org_id || "";

    workspaceService
      .createWorkspace(state, orgId)
      .then((record) => {
        setWorkspaceRecord(record);
        setError(null);
      })
      .catch((e: Error) => {
        LoggerService.error(e);
        setError(e);
        setWorkspaceRecord(null);
      })
      .finally(popup.open);
  };

  return (
    <>
      <SharedWorkspaceLinkModal
        open={popup.isOpen}
        record={workspaceRecord}
        handleClose={() => {
          setWorkspaceRecord(null);
          popup.close();
        }}
        error={error}
      />
      <RobotoButton
        eventName={AnalyticsEvent.FileFocalShareClicked}
        variant="outlined"
        onClick={createWorkspace}
        startIcon={<ShareIcon />}
      >
        Share
      </RobotoButton>
    </>
  );
}
