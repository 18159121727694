import {
  RoboqlFieldDescription,
  roboqlFields,
} from "@/components/RoboqlEditor/roboqlFields.ts";
import { SearchField, SearchFieldType } from "@/components/SearchBar/types";
import { QueryTarget } from "@/domain/query";

const newFieldToOldStyle = (field: RoboqlFieldDescription): SearchField => {
  let fieldType: SearchFieldType = "other";

  if (field.label === "metadata") {
    fieldType = "metadata";
  } else if (field.label === "tags") {
    fieldType = "tags";
  } else if (field.label === "created" || field.label === "modified") {
    fieldType = "date";
  }

  return {
    name: field.label,
    type: fieldType,
  };
};

const DATASET_SEARCH_FIELDS: SearchField[] =
  roboqlFields.datasets.map(newFieldToOldStyle);

const FILE_SEARCH_FIELDS: SearchField[] =
  roboqlFields.files.map(newFieldToOldStyle);

const TOPIC_SEARCH_FIELDS: SearchField[] =
  roboqlFields.topics.map(newFieldToOldStyle);

const TOPIC_MESSAGE_PATH_FIELDS: SearchField[] =
  roboqlFields.topic_message_paths.map(newFieldToOldStyle);

export function sortFieldForTarget(target: QueryTarget): string {
  if (target === QueryTarget.Datasets) {
    return "created";
  } else if (target === QueryTarget.Files) {
    return "created";
  } else if (target === QueryTarget.Topics) {
    return "created";
  } else if (target === QueryTarget.TopicMessagePaths) {
    return "created";
  } else {
    return "created";
  }
}

export function searchFieldsForTarget(target: QueryTarget): SearchField[] {
  if (target === QueryTarget.Datasets) {
    return DATASET_SEARCH_FIELDS;
  } else if (target === QueryTarget.Files) {
    return FILE_SEARCH_FIELDS;
  } else if (target === QueryTarget.Topics) {
    return TOPIC_SEARCH_FIELDS;
  } else if (target === QueryTarget.TopicMessagePaths) {
    return TOPIC_MESSAGE_PATH_FIELDS;
  } else {
    return [];
  }
}
