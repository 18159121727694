import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, OutlinedInput, useTheme } from "@mui/material";
import classNames from "classnames";
import React, { ChangeEvent } from "react";

import styles from "./Filter.module.css";

interface FilterProps {
  handleFilterChange: (arg: ChangeEvent<HTMLInputElement>) => void;
  className?: classNames.Argument;
  initialValue?: string;
}

/**
 * Filter box for the TopicTree Sidebar.
 */
export function Filter(props: FilterProps) {
  const theme = useTheme();
  const { handleFilterChange, className, initialValue = "" } = props;

  // Use state to manage the input value
  const [filterTerm, setFilterTerm] = React.useState<string>(initialValue);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilterTerm(value); // Update the state with the new value
    handleFilterChange(event); // Call the provided callback
  };

  return (
    <div className={classNames(className)}>
      <OutlinedInput
        size="small"
        fullWidth
        placeholder="Filter topics"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className={styles.icon} />
          </InputAdornment>
        }
        sx={{
          backgroundColor: theme.palette.paper.main,
        }}
        value={filterTerm}
        onChange={handleChange}
        inputProps={{
          className: styles.inputBaseChild,
        }}
      />
    </div>
  );
}
