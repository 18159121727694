import ColorHash from "color-hash";

import type { PlotSeries } from "@/state/visualization";

const colorHash = new ColorHash({ lightness: 0.5, saturation: 0.5 });

export function colorForSeries(series: PlotSeries): string {
  const userSelectedColor = (series?.style ?? {}).lineColor as
    | string
    | undefined;
  return userSelectedColor ?? colorHash.hex(series.id);
}

export function colorForAnnotation(
  annotationName: string,
  opacity: number = 0.25,
): string {
  const rgb = colorHash.rgb(annotationName);
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity.toFixed(2)})`;
}
