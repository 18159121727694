import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Box,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";
import * as React from "react";

import {
  AlertDialog,
  RobotoButton,
  RobotoTableCell,
  RobotoTableRow,
} from "@/components";
import { TriggerRecord } from "@/domain/actions/TriggerRecord";
import { OrgRecord } from "@/domain/orgs";
import { useNavigation } from "@/providers";
import { usePaginatedAPICall } from "@/service/apiHooks";
import { AnalyticsEvent, RobotoAPICall, searchTriggersEndpoint } from "@/types";

import { TriggerRow } from "./trigger/TriggerRow";

type RowsPerPage = 10 | 25 | 50;
const initialRowsPerPage = 10;

interface TriggersProps {
  currentOrg: OrgRecord | null;
}

export const Triggers: React.FC<TriggersProps> = ({ currentOrg }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [reloadToggle, setReloadToggle] = React.useState(false);

  const [errorText, setErrorText] = React.useState<string>("");

  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);
  const [alertDialogText, setAlertDialogText] = React.useState<string>("");
  const [alertDialogAction, setAlertDialogAction] =
    React.useState<() => Promise<void>>();

  const theme = useTheme();
  const { goto } = useNavigation();

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] =
    React.useState<number>(initialRowsPerPage);

  const {
    getFirstPage,
    fetchNextPage,
    fetchPreviousPage,
    onRowsPerPageChange,
    pageData,
    isNextPageAvailable,
    cacheLength,
  } = usePaginatedAPICall<TriggerRecord>();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!currentOrg?.org_id) {
        return;
      }

      setIsLoading(true);

      const apiCall: RobotoAPICall = {
        endpoint: searchTriggersEndpoint,
        method: "POST",
        requestBody: JSON.stringify({
          sort_direction: "DESC",
          sort_by: "created",
        }),
        orgId: currentOrg?.org_id,
      };

      await getFirstPage(apiCall, initialRowsPerPage);

      setIsLoading(false);
    };

    void fetchData();
  }, [getFirstPage, currentOrg?.org_id, reloadToggle]);

  const onPageChangeHandler = async (newPage: number) => {
    //
    if (newPage > page) {
      setPage(page + 1);
      await fetchNextPage(newPage, rowsPerPage);
    } else {
      setPage(page - 1);
      fetchPreviousPage(newPage, rowsPerPage);
    }
  };

  const onRowsPerPageChangeHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const val = parseInt(e?.target?.value) ?? 10;
    setPage(0);
    setRowsPerPage(val);
    await onRowsPerPageChange(val as RowsPerPage);
  };

  return (
    <>
      <Box sx={{ mb: theme.spacing(3) }}>
        <Alert severity="info">
          Triggers automatically invoke actions on uploaded data that matches
          any specified conditions.
        </Alert>
      </Box>
      {errorText && (
        <Box sx={{ mb: theme.spacing(3) }}>
          <Alert severity="error">{errorText}</Alert>
        </Box>
      )}
      <Box sx={{ mb: theme.spacing(2) }}>
        <RobotoButton
          eventName={AnalyticsEvent.CreateTriggerClicked}
          variant={"outlined"}
          size="small"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => goto.createTrigger()}
          sx={{
            height: theme.actionButtonHeight,
            minWidth: 80,
          }}
        >
          Create
        </RobotoButton>
      </Box>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <RobotoTableCell>Name</RobotoTableCell>
              <RobotoTableCell>Action</RobotoTableCell>
              <RobotoTableCell>Evaluation History</RobotoTableCell>
              <RobotoTableCell>Modified</RobotoTableCell>
              <RobotoTableCell>Modified By</RobotoTableCell>
              <RobotoTableCell>Operations</RobotoTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <>
                {Array.from({ length: 3 }, (_, i) => (
                  <TableRow key={`row-${i}`}>
                    {Array.from({ length: 6 }, (_, j) => (
                      <RobotoTableCell key={`cell-${j}`}>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          height={30}
                          sx={{ mt: 1, mb: 1 }}
                        />
                      </RobotoTableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {pageData?.length > 0 ? (
                  <>
                    {pageData?.map((trigger, idx) => {
                      return (
                        <TriggerRow
                          key={`trigger-row-${idx}`}
                          trigger={trigger}
                          currentOrg={currentOrg}
                          reloadToggle={reloadToggle}
                          setReloadToggle={setReloadToggle}
                          setAlertDialogText={setAlertDialogText}
                          setAlertDialogAction={setAlertDialogAction}
                          setAlertDialogOpen={setAlertDialogOpen}
                          setErrorText={setErrorText}
                        />
                      );
                    })}
                  </>
                ) : (
                  <RobotoTableRow>
                    <RobotoTableCell
                      style={{
                        paddingBottom: theme.spacing(2),
                        paddingTop: theme.spacing(2),
                      }}
                      colSpan={7}
                    >
                      <Alert severity="warning">
                        No triggers have been created yet. You can create one by
                        clicking the Create button above, or with the{" "}
                        <Link
                          underline="hover"
                          target="_blank"
                          href="https://docs.roboto.ai/getting-started/programmatic-access.html#python-sdk-and-cli"
                        >
                          Roboto CLI
                        </Link>
                        :
                        <br />
                        <br />
                        <span
                          style={{
                            fontFamily: "monospace",
                            fontSize: "0.8rem",
                          }}
                        >
                          $ roboto triggers create --name {"<triggerName>"}
                          --action {"<actionReference>"} --input-data{" "}
                          {"<filePattern>"}
                        </span>
                      </Alert>
                    </RobotoTableCell>
                  </RobotoTableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={isNextPageAvailable ? -1 : cacheLength}
        nextIconButtonProps={{
          disabled: !isNextPageAvailable,
        }}
        rowsPerPageOptions={[10, 25, 50]}
        page={page}
        onPageChange={(_, newPage) => {
          void onPageChangeHandler(newPage);
        }}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => {
          void onRowsPerPageChangeHandler(e);
        }}
        labelRowsPerPage={"Triggers per page:"}
      />
      <AlertDialog
        dialogOpen={alertDialogOpen}
        handleClose={() => {
          setAlertDialogOpen(false);
        }}
        dialogTitle={"Delete Trigger"}
        dialogText={alertDialogText}
        dialogAction={alertDialogAction}
      />
    </>
  );
};
