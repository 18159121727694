import ConstructionIcon from "@mui/icons-material/Construction";
import { Alert, AlertTitle } from "@mui/material";

/**
 * Temporary UI to indicate a feature is under construction.
 */
export function UnderConstruction() {
  return (
    <Alert
      icon={<ConstructionIcon fontSize="inherit" />}
      severity="info"
      sx={{
        margin: "1em 1em",
      }}
    >
      <AlertTitle> Coming Soon</AlertTitle>
      We&apos;re working on something big here, check back soon!
    </Alert>
  );
}
