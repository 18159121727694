import AddIcon from "@mui/icons-material/Add";
import { Box, Divider, Popover, Typography, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoButton } from "@/components";
import { AnalyticsEvent } from "@/types";

import { CreateCollection } from "./CreateCollection";

export const CreateCollectionPopover: React.FC = () => {
  //
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <RobotoButton
        eventName={AnalyticsEvent.CreateCollectionClicked}
        variant="outlined"
        size="small"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handlePopoverOpen}
        sx={{
          height: theme.actionButtonHeight,
          minWidth: 80,
        }}
      >
        Create
      </RobotoButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "left",
        }}
        marginThreshold={5}
      >
        <Box sx={{ padding: "10px", minWidth: "375px" }} component="form">
          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            sx={{ textAlign: "center" }}
          >
            Create Collection
          </Typography>
          <Divider sx={{ mb: theme.spacing(1) }} />
          <CreateCollection close={handlePopoverClose} />
        </Box>
      </Popover>
    </Box>
  );
};
