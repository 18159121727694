import { Box, CircularProgress, Modal, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import { useAuth } from "@/providers";
import { useLazyAPICall } from "@/service/apiHooks";
import {
  AnalyticsEvent,
  Comment,
  RobotoAPICall,
  deleteCommentEndpoint,
} from "@/types";

import { RobotoButton } from "../RobotoButton";
import { RobotoTypography } from "../RobotoTypography";

import { CommentDisplayAndEdit } from "./CommentDisplayAndEdit";

interface CommentsListProps {
  comments: Comment[];
  onCommentDeleted: (comment: Comment) => void;
  avatarMap: { [key: string]: string };
  onCommentUpdated: (comment: Comment) => void;
  summaryView?: boolean;
}

export const CommentsList: React.FC<CommentsListProps> = ({
  comments,
  onCommentDeleted,
  avatarMap,
  onCommentUpdated,
  summaryView,
}) => {
  const { currentUser, currentOrganization } = useAuth();

  const [modalState, setModalState] = useState<{
    open: boolean;
    commentForDeletion: Comment | null;
  }>({ open: false, commentForDeletion: null });

  const theme = useTheme();

  const { loading, initiateRequest } = useLazyAPICall();

  const handleDeleteComment = async (comment: Comment) => {
    const apiCall: RobotoAPICall = {
      endpoint: deleteCommentEndpoint,
      method: "DELETE",
      pathParams: {
        commentId: comment.comment_id,
      },
      orgId: currentOrganization?.org_id,
    };

    await initiateRequest(apiCall);

    onCommentDeleted(comment);
    handleDeleteModalClose();
  };

  const handleUpdateComment = (comment: Comment) => {
    onCommentUpdated(comment);
  };

  const handleDeleteModalClose = () => {
    setModalState({ open: false, commentForDeletion: null });
  };

  return (
    <Box>
      {comments.map((comment) => {
        const avatarUrl = avatarMap[comment.created_by] ?? "";

        return (
          <span id={comment.comment_id} key={comment.comment_id}>
            <CommentDisplayAndEdit
              comment={comment}
              avatarUrl={avatarUrl}
              currentUserId={currentUser?.user_id ?? ""}
              onDeleteCommentClicked={() =>
                setModalState({ open: true, commentForDeletion: comment })
              }
              onCommentSaveClicked={handleUpdateComment}
              summaryView={summaryView}
            />
          </span>
        );
      })}
      <Modal
        open={modalState.open}
        onClose={handleDeleteModalClose}
        aria-labelledby="comment-modal-title"
        aria-describedby="comment-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: `1px solid ${theme.border.color}`,
            borderColor: theme.border.color,
            borderRadius: theme.border.radius,
            padding: theme.spacing(2),
          }}
        >
          <RobotoTypography variant="h6">Delete Comment</RobotoTypography>
          <RobotoTypography sx={{ mt: 2 }}>
            Are you sure you want to delete your comment?
          </RobotoTypography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: theme.spacing(2),
            }}
          >
            <RobotoButton
              sx={{ marginRight: theme.spacing(1) }}
              onClick={handleDeleteModalClose}
              eventName={AnalyticsEvent.DeleteCommentCancelButtonClicked}
            >
              No
            </RobotoButton>
            <RobotoButton
              eventName={AnalyticsEvent.DeleteCommentConfirmButtonClicked}
              onClick={() => {
                if (modalState.commentForDeletion) {
                  void handleDeleteComment(modalState.commentForDeletion);
                }
              }}
            >
              Yes
            </RobotoButton>
          </Box>

          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "background.paper",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};
