import ApiOutlinedIcon from "@mui/icons-material/ApiOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Alert, Box, Link, useTheme } from "@mui/material";

import { PageHeader, RobotoDivider } from "@/components";
import { useNavigation, useAuth } from "@/providers";

import {
  DashboardButton,
  DashboardSection,
  RecentDatasets,
  RecentInvocations,
} from "../components";
import { RecentComments } from "../components/RecentComments";

export function DashboardPage() {
  const theme = useTheme();
  const { currentUser } = useAuth();

  const { goto } = useNavigation();
  return (
    <>
      <PageHeader>
        Welcome,{" "}
        {currentUser?.name
          ? currentUser?.name
          : currentUser?.user_id?.split("@")[0]}
      </PageHeader>
      <RobotoDivider />
      <Alert severity="info">
        Check out our{" "}
        <Link href="https://docs.roboto.ai/" target="_blank" underline="hover">
          documentation
        </Link>{" "}
        if it&apos;s your first time here. Join our community{" "}
        <Link
          href="https://discord.gg/r8RXceqnqH"
          target="_blank"
          underline="hover"
        >
          Discord server
        </Link>{" "}
        and keep an eye on our{" "}
        <Link
          href="https://www.roboto.ai/blog"
          target="_blank"
          underline="hover"
        >
          blog
        </Link>{" "}
        for new features and announcements.
      </Alert>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(auto-fit, minmax(275px, 1fr))", // applies to xtra small (mobile) screens
            sm: "repeat(auto-fit, 275px)", // applies to small screens
          },
          gridTemplateRows: "repeat(auto-fit, 1fr)",
          columnGap: theme.spacing(2.5),
          rowGap: theme.spacing(2.5),
          mt: theme.spacing(1.5),
          overflowX: "auto",
          justifyContent: {
            xs: "center", // applies to xtra small (mobile) screens
            sm: "left", // applies to small screens
            md: "left", // applies to medium sized screens and up
          },
          pb: theme.spacing(0.5),
        }}
      >
        <DashboardButton
          title="Create Dataset"
          description="Upload files into a new dataset"
          bgColor="rgba(214, 241, 255, 0.9)"
          iconColor="rgb(0, 96, 143)"
          icon={<CreateNewFolderOutlinedIcon />}
          href={"/datasets/create"}
        />
        <DashboardButton
          title="Create Action"
          description="Process data with a custom action"
          bgColor="rgba(255, 224, 228, 0.9)"
          iconColor="rgb(218, 68, 86)"
          icon={<ApiOutlinedIcon />}
          href={"/actions/create"}
        />
        <DashboardButton
          title="Invoke Action"
          description="Run an action on files in a dataset"
          bgColor="rgba(211, 226, 250, 0.9)"
          iconColor="rgb(69, 64, 166)"
          icon={<BoltOutlinedIcon />}
          href={"/actions/invoke"}
        />
        <DashboardButton
          title="Invite User"
          description="Collaborate on data with your team"
          bgColor="rgba(214, 247, 233, 0.9)"
          iconColor="rgb(0, 130, 133)"
          icon={<PersonOutlineOutlinedIcon />}
          href={"/settings?tab=1"}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, 275px)",
          gridTemplateRows: "repeat(auto-fit, 1fr)",
          columnGap: theme.spacing(2.5),
          rowGap: theme.spacing(2.5),
          mt: theme.spacing(2),
          overflowX: "auto",
          pb: theme.spacing(0.5),
        }}
      >
        <DashboardSection
          title={"Recent Datasets"}
          colSpan={2}
          rowSpan={2}
          linkText={"See all datasets"}
          onClickMore={() => goto.search()}
        >
          <RecentDatasets count={3} />
        </DashboardSection>
        <DashboardSection
          title={"Recent Invocations"}
          colSpan={2}
          rowSpan={2}
          linkText={"See all invocations"}
          onClickMore={() => goto.actions({ tab: 2 })}
        >
          <RecentInvocations count={3} />
        </DashboardSection>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, 275px)",
          gridTemplateRows: "repeat(auto-fit, 1fr)",
          columnGap: theme.spacing(2.5),
          rowGap: theme.spacing(2.5),
          mt: theme.spacing(2),
          overflowX: "auto",
        }}
      >
        <DashboardSection title={"Recent Comments"} colSpan={4} rowSpan={2}>
          <RecentComments count={2} />
        </DashboardSection>
      </Box>
    </>
  );
}
