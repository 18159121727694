import { Box, useTheme } from "@mui/material";
import * as React from "react";

import { AnalyticsEvent, PageRoute } from "@/types";

import { RobotoIconButton } from "../RobotoIconButton";
import { RobotoTypography } from "../RobotoTypography";

import { Title } from "./types";

interface LinkButtonProps {
  title: Title;
  active: boolean;
  link?: PageRoute | string;
  Icon: React.ElementType;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  title,
  active,
  link,
  Icon,
}) => {
  const theme = useTheme();

  function getBackgroundColor() {
    if (active) {
      return theme.palette.primary.light;
    } else {
      return "none";
    }
  }

  return (
    <Box
      sx={{
        marginBottom: theme.spacing(1.5),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        paddingLeft: "1px",
        paddingRight: "10px",
        marginLeft: theme.spacing(0.75),
        marginRight: theme.spacing(0.75),
        borderRadius: theme.border.radius,
        whiteSpace: "nowrap",
        overflowX: "hidden",
        backgroundColor: getBackgroundColor(),
        transition: "background-color 0.2s ease-in-out",
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
      }}
    >
      <RobotoIconButton
        eventName={AnalyticsEvent.NavigationIconClicked}
        eventProperties={link && { navRoute: link }}
        sx={{
          marginRight: theme.spacing(2),
          "&:hover": {
            background: "none",
          },
        }}
      >
        <Icon />
      </RobotoIconButton>
      <RobotoTypography variant="body2">{title}</RobotoTypography>
    </Box>
  );
};
