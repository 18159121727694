import { useTheme } from "@mui/material";
import * as React from "react";

import { RobotoButton, RobotoTypography, RobotoLogo } from "@/components";
import { OrgRecord } from "@/domain/orgs";
import { useAuth, useNavigation } from "@/providers";
import { AnalyticsEvent } from "@/types";

import { AuthCard } from "./AuthCard";
import { AuthCardTitle } from "./AuthCardTitle";
import styles from "./AuthFlowButton.module.css";
import { LegalFooter } from "./LegalFooter";

interface SelectOrCreateOrganizationFormProps {
  isVisible: boolean;
  currentOrganizations: OrgRecord[] | null;
  createNewOrganizationClicked: (individualAccountIdentifier: string) => void;
}

export const SelectOrCreateOrganizationForm: React.FC<
  SelectOrCreateOrganizationFormProps
> = ({ isVisible, currentOrganizations, createNewOrganizationClicked }) => {
  const theme = useTheme();

  const { getUserIdToken, currentUser } = useAuth();

  if (!isVisible) {
    return null;
  }

  const onClick = async () => {
    const userIdToken = await getUserIdToken();
    let individualAccountIdentifier = userIdToken?.email;

    if (!individualAccountIdentifier) {
      individualAccountIdentifier = (new Date().getTime() / 1000).toString();
    }

    createNewOrganizationClicked(individualAccountIdentifier);
  };

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <AuthCardTitle title={"Sign In"} />

        <RobotoTypography
          sx={{
            marginBottom: theme.spacing(2),
            textAlign: "center",
          }}
          variant="body1"
        >
          Select your organization
        </RobotoTypography>

        <OrganizationsList organizations={currentOrganizations} />

        <RobotoButton
          data-cy={"create-new-organization"}
          disabled={
            (currentOrganizations &&
              currentOrganizations?.length >= 2 &&
              !currentUser?.is_system_user) ||
            false
          }
          eventName={AnalyticsEvent.CreateNewOrganizationClicked}
          variant={"contained"}
          className={styles.authFlowButton}
          sx={{ marginBottom: theme.spacing(1) }}
          onClick={() => {
            void onClick();
          }}
        >
          Create new organization
        </RobotoButton>
      </AuthCard>
      <LegalFooter />
    </>
  );
};

interface OrganizationsListProps {
  organizations: OrgRecord[] | null;
}

const OrganizationsList: React.FC<OrganizationsListProps> = ({
  organizations,
}) => {
  //

  const theme = useTheme();

  const { goto } = useNavigation();

  const { setCurrentOrganization } = useAuth();

  if (!organizations || organizations.length === 0) {
    return null;
  }

  return (
    <>
      {organizations.map((orgRole) => {
        return (
          <RobotoButton
            data-cy={"organization-cell"}
            key={orgRole.org_id}
            variant={"outlined"}
            eventName={AnalyticsEvent.SelectOrgClicked}
            className={styles.authFlowButton}
            sx={{
              padding: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              marginBottom: theme.spacing(2),
              cursor: "pointer",
            }}
            onClick={() => {
              setCurrentOrganization(orgRole);
              goto.home();
            }}
          >
            <RobotoTypography variant="body2" sx={{ textAlign: "center" }}>
              <span style={{ fontWeight: 500 }}>{orgRole.name}</span>
            </RobotoTypography>
          </RobotoButton>
        );
      })}
    </>
  );
};
