import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { z } from "zod";

import { EventNameInput } from "@/components/events";
import { EventRecord } from "@/domain/events";
import { useUpdateEvent } from "@/domain/events/hooks";

import { useEphemeralWorkspaceState } from "../WorkspaceCtx";

interface EventNameFormProps {
  id: EventRecord["event_id"];
  name: EventRecord["name"];
  onSubmit: (name: string) => void;
}

const schema = z.object({
  name: z.string().min(1, "Name is required."),
});

export function EventNameForm({ id, name, onSubmit }: EventNameFormProps) {
  const form = useForm<{ name: string }>({
    defaultValues: {
      name,
    },
    resolver: zodResolver(schema),
  });
  const updateEvent = useUpdateEvent();
  const { updateEventInWorkspace } = useEphemeralWorkspaceState();

  const submissionErrors = form.formState.errors.root?.serverErrors;

  const onSubmitData: SubmitHandler<{ name: string }> = (data) => {
    if (data.name === name) {
      // Return early if the name didn't change
      onSubmit(data.name);
      return;
    }
    return updateEvent
      .mutateAsync({
        id,
        requestBody: {
          name: data.name,
        },
      })
      .then((event) => {
        updateEventInWorkspace(event);
        onSubmit(data.name);
      });
  };

  return (
    <form
      onSubmit={(event) => {
        form
          .handleSubmit(onSubmitData)(event)
          .catch(() => {
            form.setError("root.serverErrors", {
              message: "Failed to save event name. Please try again.",
            });
          });
      }}
    >
      <Controller
        control={form.control}
        name="name"
        render={({ field }) => {
          return (
            <EventNameInput
              currentName={field.value}
              onNameChanged={field.onChange}
              size="small"
              autoFocus
              disabled={form.formState.isSubmitting}
              error={form.formState.errors.name !== undefined}
              helperText={form.formState.errors.name?.message}
            />
          );
        }}
      />
      {submissionErrors && (
        <Box component="p" sx={{ color: "red", margin: "0.5rem 0" }}>
          {submissionErrors.message}
        </Box>
      )}
      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          margin: "0.5rem 0",
          justifyContent: "flex-end",
        }}
      >
        <Button type="button" onClick={() => onSubmit(name)}>
          Cancel
        </Button>
        <Button
          disabled={form.formState.isSubmitting}
          variant="contained"
          type="submit"
          disableElevation
        >
          Save
        </Button>
      </div>
    </form>
  );
}
