import { Typography } from "@mui/material";
import classNames from "classnames";

import styles from "./MessagePathLabel.module.css";

export interface MessagePathLabelProps {
  className?: string;
  dataType: string;
  label: string;
}

/**
 * Renders a label for a message path.
 */
export function MessagePathLabel(props: MessagePathLabelProps) {
  const { className, dataType, label } = props;

  return (
    <span className={classNames(className, styles.labelContainer)}>
      <p className={styles.hideOverflow}>{label}</p>
      <Typography
        className={classNames(styles.dataTypeLabel, styles.hideOverflow)}
        color="text.secondary"
      >
        {dataType}
      </Typography>
    </span>
  );
}
